import React, { memo } from "react";
import { Link } from "react-router-dom";
import NavigationRegister from "../NavigationRegister";
import _ from "lodash";
import Utils from "../../../../../utils/Utils";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../store/slices/userSlice";

const HorizontalParent = memo((props) => {
  const { token } = useSelector(selectUser);
  const { item, isMenuActive, i, handleMenuClick, language, selected } = props;

  const { is_visible, url_tabs, path, menu_item_tag } = item;

  const link = url_tabs || path;

  if (!Boolean(is_visible)) return null;

  let active = false;

  if (menu_item_tag === "restricted_area" && !token) return null;

  if (!_.isEmpty(selected))
    active = selected.menu_item_id === item.menu_item_id;

  return (
    <li
      className={`hover:cursor-pointer navi-parent nav-item${
        item.subitems || item.megamenu ? ` dropdown` : ""
      }${isMenuActive === i ? " open" : ""}
      ${active ? " active" : ""}
      `}
      key={item.menu_item_id}
    >
      {!_.isEmpty(link) ? (
        <Link
          className="nav-link"
          to={link}
          target={item.url_tabs && "_blank"}
          rel={item.url_tabs && "noopener noreferrer"}
        >
          {item?.title_lang && item.title_lang[language]}
        </Link>
      ) : (
        <span className="nav-link">
          {item?.title_lang && item.title_lang[language]}
        </span>
      )}
      <i className="fa fa-angle-down" onClick={(e) => handleMenuClick(e, i)} />

      {item.subitems && (
        <ul className="simple-dropdown-menu">
          {item.subitems.map((_item) => (
            <NavigationRegister
              key={_item.menu_item_id}
              item={_item}
              type={_item.type}
              handleMenuClick={handleMenuClick}
              language={language}
              selected={selected}
              internalpath={path}
            />
          ))}
        </ul>
      )}
    </li>
  );
});

export default HorizontalParent;
