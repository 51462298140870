import React, { forwardRef, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentLanguage, t } from "../../../store/slices/languageSlice";
import Utils from "../../../../utils/Utils";
import { m } from "framer-motion";
import { Col, Container, Row } from "react-bootstrap";
import Buttons from "../../button/Buttons";
import CustomModal from "../../modal/CustomModal";
import { fadeIn } from "../../../../utils/GlobalAnimations";

const ModelImage = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { items, parent } = props;
  const lang = useSelector(selectCurrentLanguage);
  const cl = Utils.isValidJson(parent.className)
    ? JSON.parse(parent.className)
    : parent.className;

  const id = `model-site-${parent.post_content_id}`;

  const handleClose = () => {
    import("../../../../utils/Utilities").then((module) => {
      if (module.getCookie(id) !== "shown") {
        document.cookie = `${id}=shown`;
      }
    });
  };

  return (
    <CustomModal.Wrapper
      modelCookie={id}
      closeBtnIn={true}
      closeBtnOuter={false}
      defaultOpen
    >
      <div
        className="bg-white text-left relative overflow-hidden max-w-[900px]"
        id="subscribe-popup"
      >
        <div onClick={handleClose} className="absolute top-0 right-0 z-10">
          <CustomModal.Close className="close-btn">
            <button
              title="Close (Esc)"
              type="button"
              className="border-none text-[30px] w-[44px] h-[44px]"
            >
              ×
            </button>
          </CustomModal.Close>
        </div>
        <Row className="justify-center">
          <Col
            className="p-24 lg:p-16 xs:p-[3rem]"
            sm={{ span: 7, order: 1 }}
            xs={{ span: 12, order: 2 }}
          >
            <span className="text-xlg font-medium text-darkgray block mb-[10px]">
              {parent.title_lang && parent.title_lang[lang]}
            </span>
            <p className="">
              {parent.subtitle_widget && parent.subtitle_widget[lang]}
            </p>
          </Col>
          <Col
            sm={{ span: 5, order: 2 }}
            xs={{ span: 12, order: 1 }}
            className="bg-cover cover-background xs:h-[150px]"
            style={{
              backgroundImage: `url(${Utils.formatURL(
                `posts/${parent?.post_content_id}/${parent?.content?.image_main}`
              )}?s=md)`,
            }}
          ></Col>
        </Row>
      </div>
    </CustomModal.Wrapper>
  );
});

export default memo(ModelImage);
