import React, { forwardRef, memo } from "react";
import { Col, Container } from "react-bootstrap";
import { m } from "framer-motion";
import { fadeIn } from "../../../../utils/GlobalAnimations";
import PortfolioColorful from "../../portfolio/PortfolioColorful";
import { selectCurrentLanguage } from "../../../store/slices/languageSlice";
import { useSelector } from "react-redux";
import Utils from "../../../../utils/Utils";

const SlideImages = forwardRef((props, ref) => {
  const { items, parent } = props;
  const lang = useSelector(selectCurrentLanguage);
  const cl = Utils.isValidJson(parent.className)
    ? JSON.parse(parent.className)
    : parent.className;

  return (
    <section
      ref={ref}
      className={`py-[130px] lg:py-[90px] md:py-[75px] sm:py-[50px] relative border-t border-mediumgray overflow-hidden `}
      style={{
        ...cl,
      }}
    >
      <Container fluid className="px-0">
        <m.div
          className="row justify-center text-center mb-[98px] lg:mb-20 md:mb-16"
          {...fadeIn}
        >
          <Col
            xl={6}
            lg={7}
            md={8}
            sm={12}
            className="font-serif flex flex-col items-center text-center"
          >
            <span className="font-medium text-basecolor text-base uppercase block mb-[10px]">
              {parent?.title_lang && parent?.title_lang[lang]}
            </span>
            <h2 className="heading-5 font-bold -tracking-[1px] text-darkgray block uppercase mb-0">
              {parent?.subtitle_widget && parent?.subtitle_widget[lang]}
            </h2>
          </Col>
        </m.div>

        <m.div className="row" {...{ ...fadeIn, transition: { delay: 0.5 } }}>
          <Col className="pl-40 lg:pl-24 md:pl-0">
            <PortfolioColorful
              grid="grid grid-4col xl-grid-4col lg-grid-3col md-grid-2col sm-grid-2col xs-grid-1col gutter-extra-large"
              overlay="#c3964ee6"
              data={items}
              carousel={true}
              carouselOption={{
                spaceBetween: 30,
                loop: true,
                slidesPerView: "auto",
                autoplay: { delay: 4000, disableOnInteraction: false },
              }}
              className="swiper-auto-slide"
            />
          </Col>
        </m.div>
      </Container>
    </section>
  );
});

export default memo(SlideImages);
