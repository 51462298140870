import React, { forwardRef, memo } from "react";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../../../store/slices/languageSlice";
import { Col, Container, Row } from "react-bootstrap";
import { fadeIn } from "../../../../utils/GlobalAnimations";
import { m } from "framer-motion";
import Utils from "../../../../utils/Utils";

const CardImages = forwardRef((props, ref) => {
  const { items, parent } = props;
  const lang = useSelector(selectCurrentLanguage);
  const cl = Utils.isValidJson(parent.className)
    ? JSON.parse(parent.className)
    : parent.className;

  return (
    <section
      ref={ref}
      className={`py-[30px] lg:py-[30px] md:py-[30px] xs:py-[30px] `}
      style={{
        ...cl,
      }}
    >
      <Container>
        <Row className="items-center justify-center">
          <Col className="mt-[1rem] sm:mt-20">
            <Row
              className={`row-cols-1 row-cols-lg-4 row-cols-md-4 row-cols-sm-2 gap-y-10`}
            >
              {items.map((item, _item) => (
                <Col key={_item}>
                  <m.div
                    className="client-box relative w-full h-full inline-block"
                    {...{
                      ...fadeIn,
                      transition: { delay: _item / 4, duration: 0.8 },
                    }}
                  >
                    <a
                      aria-label={item?.title_lang && item?.title_lang[lang]}
                      href={item?.content?.link && item?.content?.link[lang]}
                    >
                      <img
                        className="relative w-[auto]"
                        width="148"
                        height="43"
                        src={Utils.formatURL(
                          `posts/${item?.post_content_id}/${item?.content?.image_main}?s=md`
                        )}
                        alt={item?.title_lang && item?.title_lang[lang]}
                      />
                    </a>
                  </m.div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
});

export default memo(CardImages);
