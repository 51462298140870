import React, { forwardRef, memo } from "react";
import { m } from "framer-motion";
import { fadeIn } from "../../../../utils/GlobalAnimations";
import { Container } from "react-bootstrap";
import FancyTextBox from "../../fancyTextBox/FancyTextBox";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../../../store/slices/languageSlice";
import Utils from "../../../../utils/Utils";
import _ from "lodash";

const CardFancyBox = forwardRef((props, ref) => {
  const { items, parent } = props;
  const lang = useSelector(selectCurrentLanguage);
  const cl = Utils.isValidJson(parent.className)
    ? JSON.parse(parent.className)
    : parent.className;

  const firstImage =
    !_.isEmpty(items) &&
    items[0] &&
    items[0].content &&
    items[0]?.content?.image_main
      ? Utils.formatURL(
          `posts/${items[0]?.post_content_id}/${items[0]?.content?.image_main}?s=md`
        )
      : null;

  return (
    <m.section
      ref={ref}
      className={`bg-darkgray cover-background overflow-hidden `}
      style={{
        backgroundImage: firstImage ? `url(${firstImage})` : null,
        ...cl,
      }}
      {...fadeIn}
    >
      <Container fluid className="px-0">
        <FancyTextBox
          grid="row row-cols-1 row-cols-xl-4 row-cols-sm-2 gx-0 px-0"
          themeColor=""
          className="justify-center"
          theme="fancy-text-box-05"
          data={items}
          animation={fadeIn}
        />
      </Container>
    </m.section>
  );
});

export default memo(CardFancyBox);
