import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import fetch from "../../../context/AxiosContext";
import _ from "lodash";
import NavigationModel from "../../model/NavigationModel";
import Utils from "../../../utils/Utils";
import { selectCurrentLanguage } from "./languageSlice";

const navigationAdapter = createEntityAdapter({
  selectId: (data) => data.menu_item_id,
  sortComparer: (a, b) => Number(a?.item_order) - Number(b?.item_order),
});

const initialState = navigationAdapter.getInitialState({
  selected: null,
  right: [],
  productsPath: "",
});

export const getNavigation = createAsyncThunk(
  "navigation/getNavigation",
  async (__, { dispatch, getState }) => {
    try {
      const res = await fetch.get(
        "https://app.floricolor.pt/api/live/json/site/public"
      );

      if (_.isEmpty(res.result)) return Promise.reject(res);

      if (_.isEmpty(res.result.items)) return [];

      const items = res.result.items.map((m) => NavigationModel(m));

      return [];
    } catch (error) {
      console.error(error);
    }
  }
);

export const constructNavigation = createAsyncThunk(
  "navigation/constructNavigation",
  async (result, { dispatch, getState }) => {
    try {
      const language = selectCurrentLanguage(getState());
      const { site_menu_header, site_menu_footer, site_menu_header_right } =
        result;

      const navi = site_menu_header?.items || [];
      const right = site_menu_header_right?.items
        ? site_menu_header_right?.items.map((i) => NavigationModel(i))
        : [];

      dispatch(
        setMenuRight(NavigationCreation(right, true, language, dispatch))
      );

      const items = navi.map((m) => NavigationModel(m));
      return NavigationCreation(items, true, language, dispatch);
    } catch (error) {
      console.error(error);
    }
  }
);

export const NavigationCreation = (data, parent, lang, dispatch, arr = []) => {
  for (let index = 0; index < data.length; index++) {
    const element = data[index];

    const {
      title_lang,
      url_lang,
      url_tabs,
      website_template_id,
      menu_item_tag,
      menu_item_id,
      subitems,
      category_subitems,
    } = element;

    let _type = parent ? "parent" : !_.isEmpty(subitems) ? "group" : "item";
    let type = `horizontal-${_type}`;

    let path = null;

    if (url_lang && url_lang[lang]) {
      if (url_lang[lang].substring(0, 1) === "/") path = url_lang[lang];
      else path = `/${lang}/${url_lang[lang]}`;
    } else if (menu_item_tag === "home") {
      path = `/${lang}`;
    } else {
      path = `/${lang}/${menu_item_tag}${menu_item_id}`;
    }

    if (url_tabs) path = url_tabs;

    if (website_template_id === "layout_products")
      dispatch(setProductsPath(path));

    arr.push({
      ...element,
      path,
      type,
    });

    if (subitems && !_.isEmpty(subitems))
      arr[index].subitems = [
        ...NavigationCreation(subitems, false, lang, dispatch),
      ];

    if (category_subitems && !_.isEmpty(category_subitems)) {
      const removedparent = [];
      category_subitems.map(
        (cs) =>
          cs.subitems &&
          !_.isEmpty(cs.subitems) &&
          removedparent.push(...cs.subitems)
      );

      const cs = arr[index] && arr[index].subitems ? arr[index].subitems : [];

      arr[index].subitems = [
        ...NavigationCategoryCreation(
          removedparent,
          false,
          path,
          lang,
          element?.website_menu_page_id,
          element?.website_menu_type_id
        ),
        ...cs,
      ];
    }
  }

  return arr;
};

export const NavigationCategoryCreation = (
  data,
  parent,
  pathurl,
  lang,
  content_id,
  menu_type,
  arr = []
) => {
  for (let index = 0; index < data.length; index++) {
    const element = data[index];

    const { name_lang, category_id, subitems, ...rest } = element;

    let _type = parent ? "parent" : !_.isEmpty(subitems) ? "group" : "item";
    let type = `horizontal-item`;

    const path =
      name_lang && name_lang[lang]
        ? `c/${Utils.removeDiacritics(name_lang[lang])}-v${category_id}`
        : "";

    arr.push({
      ...rest,
      menu_item_id: category_id,
      title_lang: name_lang,
      is_visible: true,
      path,
      pathurl: `${pathurl}/${path}`,
      type,
      website_menu_page_id: content_id,
      website_menu_type_id: menu_type,
    });
  }

  return arr;
};

export const { selectAll, selectById, selectEntities, selectIds, selectTotal } =
  navigationAdapter.getSelectors((state) => state.navigation);

const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    setSelectedMenu: (state, action) => {
      state.selected = {
        ...state.selected,
        ...action.payload,
      };
    },
    setMenuRight: (state, action) => {
      state.right = action.payload;
    },
    setProductsPath: (state, action) => {
      state.productsPath = action.payload;
    },
  },
  extraReducers: (b) => {
    b.addCase(getNavigation.fulfilled, (state, action) => {
      navigationAdapter.setAll(state, action.payload);
    }).addCase(constructNavigation.fulfilled, (state, action) => {
      navigationAdapter.setAll(state, action.payload);
    });
  },
});

export const { setSelectedMenu, setMenuRight, setProductsPath } =
  navigationSlice.actions;

export const selectSelectedMenu = ({ navigation }) => navigation.selected;
export const selectHeaderRight = ({ navigation }) => navigation.right;
export const selectProductPath = ({ navigation }) => navigation.productsPath;

export default navigationSlice.reducer;
