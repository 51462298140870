import React, { memo, useEffect, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { m } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentLanguage, t } from "../../../store/slices/languageSlice";
import Utils from "../../../../utils/Utils";

const DownloadItem = (props) => {
  const dispatch = useDispatch();
  const { items } = props;
  const portfolioWrapper = useRef();
  const { pathname } = useLocation();
  const params = useParams();
  const lang = useSelector(selectCurrentLanguage);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let allImages = portfolioWrapper.current.querySelectorAll("img");

    Promise.all(
      Array.prototype.slice
        .call(allImages)
        .filter((img) => !img.complete)
        .map(
          (img) =>
            new Promise((resolve) => {
              img.onload = img.onerror = resolve;
            })
        )
    ).then(() => {
      import("../../../../utils/Utilities").then((module) => {
        const grid = module.initializeIsotop(portfolioWrapper.current);
        grid.on("arrangeComplete", () => setLoading(false));
      });
    });
  });

  return (
    <div
      className={`${props.className ? ` ${props.className}` : ""} grid-wrapper`}
    >
      <ul
        ref={portfolioWrapper}
        className={`grid-container text-center${
          props.grid ? ` ${props.grid}` : ""
        }${loading ? " loading" : ""}`}
      >
        <li className="grid-sizer"></li>
        {items.map((item, i) => {
          const { thumbnail, name_lang, file_to_download, updated } = item;

          return (
            <li
              key={i}
              className={`grid-item${
                item.double_col ? " grid-item-double" : ""
              } `}
            >
              <Link
                aria-label="link"
                target={"_blank"}
                to={file_to_download}
                className="no-underline"
              >
                <m.div
                  className="portfolio-switchimg overflow-hidden"
                  initial={{ opacity: 0 }}
                  whileInView={!loading && { opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.6, ease: "easeOut" }}
                >
                  <div className="portfolio-image">
                    {thumbnail && (
                      <img
                        src={thumbnail}
                        width={600}
                        height={500}
                        alt="portfolio-switch"
                        className="w-full"
                      />
                    )}
                  </div>
                  <div className="py-[30px] portfolio-content lg:py-[20px] xs:py-[15px]">
                    {name_lang && name_lang[lang] && (
                      <span className="inline-block font-medium text-darkgray font-serif">
                        {name_lang[lang]}
                      </span>
                    )}
                    {updated && (
                      <span className="block text-spanishgray text-md">
                        {dispatch(t("common.updated"))}: {updated}
                      </span>
                    )}
                  </div>
                </m.div>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default memo(DownloadItem);
