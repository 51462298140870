import _ from "lodash";
import Utils from "../../../../utils/Utils";

export const CategoryModel = (data) =>
  _.mergeWith(
    {
      id: data?.category_id,
      category_id: "",
      category_in: "",
      external_code: null,
      category_tag: "",
      is_active: 1,
      picture: null,
      name_lang: {},
      description_lang: {},
      title_lang: {},
      subtitle_widget: {},
      title_SEO_lang: null,
      description_SEO_lang: null,
      name: "",
      description: null,
      title_SEO: null,
      description_SEO: null,
      picture_src: null,
      subitems: [],
    },
    data,
    (obj, src, k) => {
      if (
        k === "name_lang" ||
        k === "description_lang" ||
        k === "title_lang" ||
        k === "subtitle_widget"
      )
        return Utils.isValidJson(src) ? JSON.parse(src) : obj;

      if (k === "is_active") return Number(src);

      if (src === null || src === undefined) return obj;
    }
  );
