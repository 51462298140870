import React, { lazy, useEffect } from "react";
import {
  Navigate,
  Route,
  Routes,
  createBrowserRouter,
  useLocation,
  useParams,
  useRoutes,
} from "react-router-dom";
import { Page404 } from "../pages/404/Page404";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../store/slices/languageSlice";
import { selectAll, selectHeaderRight } from "../store/slices/navigationSlice";
import Utils from "../../utils/Utils";
import { selectFooterNavi } from "../store/slices/footerSlice";
import { registerWidget } from "../components/widgets/WidgetsRegister";
import TitleSimple from "../components/widgets/titles/TitleSimple";
import TitleSimpleProps from "../components/widgets/titles/TitleSimpleProps";
import ImageSimple from "../components/widgets/images/ImageSimple";
import ContentSimple from "../components/widgets/content/ContentSimple";
import CardFancyBox from "../components/widgets/cards/CardFancyBox";
import CardGrid from "../components/widgets/cards/CardGrid";
import CardSimpleLink from "../components/widgets/cards/CardSimpleLink";
import CardSimple from "../components/widgets/cards/CardSimple";
import CardTwoImages from "../components/widgets/cards/CardTwoImages";
import CardWithVideo from "../components/widgets/cards/CardWithVideo";
import CardImages from "../components/widgets/cards/CardImages";
import CounterSimple from "../components/widgets/counter/CounterSimple";
import SlideCards from "../components/widgets/slideshows/SlideCards";
import SlideCardsII from "../components/widgets/slideshows/SlideCardsII";
import SlideImages from "../components/widgets/slideshows/SlideImages";
import SlideSimple from "../components/widgets/slideshows/SlideSimple";
import FormContact from "../components/widgets/forms/FormContact";
import FormLogin from "../components/widgets/forms/FormLogin";
import FormRegister from "../components/widgets/forms/FormRegister";
import FormSuggestions from "../components/widgets/forms/FormSuggestions";
import AccordionSimple from "../components/widgets/accordions/AccordionSimple";
import ModelSimple from "../components/widgets/model/ModelSimple";
import ModelImage from "../components/widgets/model/ModelImage";
import Maintenance from "../pages/maintenance/Maintenance";
import { AnimatePresence, motion } from "framer-motion";
import { selectPageStatus } from "../store/slices/pageSlice";
import PageLoading from "../components/loader/PageLoading";
import CardGridWithPage from "../components/widgets/cards/CardGridWithPage";

registerWidget("title_simple", TitleSimple);
registerWidget("title_simple_with_props", TitleSimpleProps);

registerWidget("form_contact", FormContact);
registerWidget("form_login", FormLogin);
registerWidget("form_register", FormRegister);
registerWidget("form_suggestions", FormSuggestions);

registerWidget("accordion_simple", AccordionSimple);

registerWidget("image_simple", ImageSimple);

registerWidget("model_simple", ModelSimple);
registerWidget("model_image", ModelImage);

registerWidget("content_simple", ContentSimple);

registerWidget("card_fancy_box", CardFancyBox);
registerWidget("card_grid", CardGrid);
registerWidget("card_simple_link", CardSimpleLink);
registerWidget("card_simple", CardSimple);
registerWidget("card_two_images", CardTwoImages);
registerWidget("card_with_video", CardWithVideo);
registerWidget("card_images", CardImages);
registerWidget("card_with_page", CardGridWithPage);

registerWidget("counter_simple", CounterSimple);

registerWidget("slide_cards", SlideCards);
registerWidget("slide_cards_ii", SlideCardsII);
registerWidget("slide_images", SlideImages);
registerWidget("slide_simple", SlideSimple);

const RoutesConfigs = () => {
  const lang = useSelector(selectCurrentLanguage);
  const navigation = useSelector(selectAll);
  const navifooter = useSelector(selectFooterNavi);
  const naviHeaderRight = useSelector(selectHeaderRight);
  const location = useLocation();
  const status = useSelector(selectPageStatus);

  const routes = useRoutes([
    {
      path: "*",
      element: <Navigate to={lang} />,
    },
    {
      path: "/404",
      element: <Page404 />,
    },
    {
      path: "/pt/maintenance",
      element: <Maintenance />,
    },
    {
      path: "/",
      element: <Navigate to={lang} />,
    },
    {
      path: "",
      element: <Navigate to={lang} />,
    },
    ...Utils.generateRoutesFromConfigs(navigation, lang).map((r) => r),
    ...Utils.generateRoutesFromConfigs(naviHeaderRight, lang).map((r) => r),
    ...Utils.generateRoutesFromConfigs(navifooter, lang).map((r) => r),
  ]);

  if (!routes) return null;

  return (
    <AnimatePresence mode="wait">
      {React.cloneElement(routes, { key: location.pathname })}
    </AnimatePresence>
  );
};

export default RoutesConfigs;
