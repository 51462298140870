import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import Typography from "@mui/material/Typography";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  hideMessage,
  selectMessageOptions,
  selectMessageState,
} from "../../store/slices/messageSlice";

const StyledSnackbar = styled(Snackbar)(({ theme, variant }) => ({
  "& .SnackbarMessage-content": {
    ...(variant === "success" && {
      backgroundColor: "#43a047",
      color: "#FFFFFF",
      borderRadius: "25px",
    }),

    ...(variant === "error" && {
      backgroundColor: "#d32f2f",
      color: "#FFFFFF",
      borderRadius: "25px",
    }),

    ...(variant === "info" && {
      backgroundColor: "#2196f3",
      color: "#FFFFFF",
      borderRadius: "25px",
    }),

    ...(variant === "warning" && {
      backgroundColor: "#ff9800",
      color: "#FFFFFF",
      borderRadius: "25px",
    }),

    ...((variant === "" || !variant) && {
      backgroundColor: "#000",
      color: "#FFFFFF",
      borderRadius: "25px",
    }),
  },
}));

const variantIcon = {
  success: "feather-check-circle",
  warning: "feather-alert-triangle",
  error: "feather-alert-circle",
  info: "feather-info",
};

const SnackbarMessage = (props) => {
  const dispatch = useDispatch();
  const state = useSelector(selectMessageState);
  const options = useSelector(selectMessageOptions);

  return (
    <StyledSnackbar
      {...options}
      open={state}
      onClose={() => dispatch(hideMessage())}
      className="z-9999999"
      ContentProps={{
        variant: "body2",
        headlineMapping: {
          body1: "div",
          body2: "div",
        },
      }}
    >
      <SnackbarContent
        className="SnackbarMessage-content"
        message={
          <div className="flex items-center">
            {variantIcon[options.variant] && (
              <i className={`${variantIcon[options.variant]} text-lg`} />
            )}
            <Typography className="!mx-2">{options.message}</Typography>
          </div>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => dispatch(hideMessage())}
            size="medium"
          >
            <i className={"feather-x"} />
          </IconButton>,
        ]}
      />
    </StyledSnackbar>
  );
};

export default memo(SnackbarMessage);
