const components = {};

export function registerPages(name, Component) {
  components[name] = Component;
}

export default function PagesRegister(props) {
  const C = components[props.type];
  return C ? <C {...props} /> : null;
}
