import React, { forwardRef, lazy, memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAll, selectPageStatus } from "../../store/slices/pageSlice";
import { useParams } from "react-router-dom";
import { setLanguage } from "../../store/slices/languageSlice";
import { fadeIn, motionContainer } from "../../../utils/GlobalAnimations";
import { m, motion } from "framer-motion";
import PageLoading from "../../components/loader/PageLoading";

//import WidgetRegister from "../../components/widgets/WidgetsRegister";
const WidgetRegister = lazy(() =>
  import("../../components/widgets/WidgetsRegister")
);

const Home = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const data = useSelector(selectAll);
  const params = useParams();
  const status = useSelector(selectPageStatus);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (params.lang) {
      dispatch(setLanguage(params.lang));
    }
  }, [params.lang]);

  setTimeout(() => {
    setLoading(false);
  }, 3000);

  if (status === "pending")
    return (
      <motion.div
        initial="hidden"
        animate="visible"
        exit={{ opacity: 0, transition: { duration: 1 } }}
        variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
      >
        <PageLoading />
      </motion.div>
    );

  return (
    <motion.div
      className={`interior-design w-full`}
      style={props.style}
      variants={motionContainer}
      initial="hidden"
      animate="show"
    >
      {data.map((dt, _index) => {
        const {
          content,
          widget_content,
          post_content_id,

          ...parent
        } = dt;
        const { type_widget } = content;

        return (
          <WidgetRegister
            key={post_content_id}
            type={type_widget}
            items={widget_content}
            parent={{ ...dt, ...parent, ...content }}
          />
        );
      })}
    </motion.div>
  );
});

export default memo(Home);
