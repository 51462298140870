import axios from "axios";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { t } from "../app/store/slices/languageSlice";
import { showMessage } from "../app/store/slices/messageSlice";
import _ from "lodash";
import ModUtils from "../utils/ModUtils";

const current =
  window.location.hostname === "localhost" ||
  ["192.168.102.79", "192.168.102.79:3000"].includes(window.location.hostname)
    ? `https://floricolor.com/api/live/json`
    : `${window.location.origin}/api/live/json`;

const fetch = axios.create({
  baseURL: current,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

const AxiosInterceptor = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const generateMessage = fetch.interceptors.response.use(
      (res) => {
        try {
          if (res.dialog) {
            const snackbar = Object.keys(res.dialog).map((item) => {
              const element = res.dialog[item];

              if (typeof element === "string") {
                dispatch(
                  showMessage({
                    message: dispatch(t(element)),
                    variant: item,
                    autoHideDuration: 5000,
                  })
                );

                return {
                  variant: item,
                  title: element,
                  description: element,
                };
              } else {
                dispatch(
                  showMessage({
                    message: dispatch(
                      t(element.title, {
                        ...element.data,
                      })
                    ),
                    variant: item,
                    autoHideDuration: 5000,
                  })
                );

                return {
                  variant: item,
                  title: element.title,
                  description: element.content,
                  icon: element.icon,
                  time: element.time,
                  data: element.data,
                };
              }
            });

            return { ...res, snackbar };
          }

          return res;
        } catch (error) {
          dispatch(
            showMessage({
              message: "Server / Network Error",
              variant: "error",
              autoHideDuration: 5000,
            })
          );
          throw new Error();
        }
      },
      (error) => {
        throw new Error();
      }
    );

    const validateResponse = fetch.interceptors.response.use(
      (res) => {
        try {
          if (res.status === "error") return Promise.reject(res);

          return res;
        } catch (error) {
          return Promise.reject(error);
        }
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    return () => {
      fetch.interceptors.response.eject(generateMessage);
      fetch.interceptors.response.eject(validateResponse);
    };
  }, [fetch]);

  return children;
};

fetch.interceptors.response.use(
  (res) => {
    if (typeof res.data !== "object" || !res.data) {
      throw new Error(res);
    }

    const { data } = res;

    if (_.isEmpty(data)) return Promise.reject(res);
    // if (data.status === "error") return Promise.reject(res);

    return res.data;
  },
  (err) => {
    return Promise.reject(err);
  }
);

fetch.interceptors.request.use((config) => {
  const headers = window.localStorage.getItem("site-session-token");
  const cry = ModUtils.getData();

  config.headers["api-request-key"] = cry;

  if (headers) {
    // config.headers["pok"] = headers;
    config.headers["site-session-token"] = headers;
  }

  const url = config.url.split("?");

  config.url =
    url.length === 1 ? url.concat("?&debug=1").join("") : url.join("?debug=1&");

  return config;
});

export default fetch;
export { AxiosInterceptor };
