import React, { forwardRef, memo } from "react";
import { Container, Row } from "react-bootstrap";
import { m } from "framer-motion";
import { fadeIn, fadeInLeft } from "../../../../utils/GlobalAnimations";
import TestimonialsCarousel from "../../testimonialCarousel/TestimonialsCarousel";
import { TestimonialsCarouselData5 } from "../../testimonialCarousel/TestimonialsCarouselData";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../../../store/slices/languageSlice";
import Utils from "../../../../utils/Utils";

const SlideCardsII = forwardRef((props, ref) => {
  const { items, parent } = props;
  const lang = useSelector(selectCurrentLanguage);
  const cl = Utils.isValidJson(parent.className)
    ? JSON.parse(parent.className)
    : parent.className;

  return (
    <section
      className={`py-[130px] lg:py-[90px] md:py-[75px]  xs:py-[50px] `}
      style={{
        ...cl,
      }}
    >
      <Container>
        <Row className="items-center justify-center overflow-hidden">
          <m.div
            className="col-lg-4 col-sm-8 lg:text-start md:mb-20 md:text-center px-[15px]"
            {...fadeIn}
          >
            <span className="font-serif font-medium text-basecolor uppercase block mb-[15px]">
              {parent?.title_lang && parent?.title_lang[lang]}
            </span>
            <h2 className="heading-5 font-serif font-bold uppercase text-darkgray -tracking-[1px] m-0 whitespace-pre-line">
              {parent?.subtitle_widget && parent?.subtitle_widget[lang]}
            </h2>
          </m.div>
          <m.div className="col-xl-7 col-lg-8 offset-xl-1" {...fadeInLeft}>
            <TestimonialsCarousel
              data={items}
              className="overflow-hidden"
              carouselOption={{
                slidesPerView: 1,
                loop: true,
                navigation: false,
                autoplay: { delay: 2500, disableOnInteraction: false },
              }}
            />
          </m.div>
        </Row>
      </Container>
    </section>
  );
});

export default memo(SlideCardsII);
