import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSessionConfig } from "./configSlice";

export const getLanguages = createAsyncThunk(
  "language/getLanguages",
  async (__, { dispatch, getState }) => {
    try {
    } catch (error) {
      console.error(error);
    }
  }
);

export const t = (tag, options) => (dispatch, getState) => {
  const { labels } = getState().language;

  var returned = "";
  const search = tag && labels ? tag : "";

  if (!search) return `[${tag}]`;

  if (!labels[search]) return `[${tag}]`;

  returned = labels[search];

  for (const key in options) {
    returned = returned.replace(`{{${key}}}`, options[key]);
  }

  return returned;
};

const languageSlice = createSlice({
  name: "language",
  initialState: {
    labels: {},
    languages: [],
    language:
      window.location.pathname && window.location.pathname === "/"
        ? navigator.language.substring(0, 2)
        : window.location.pathname.substring(1, 3),
  },
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
  },
  extraReducers: (b) => {
    b.addCase(getSessionConfig.fulfilled, (state, action) => {
      const { lang_list, labels } = action.payload;

      state.labels = labels;
      state.languages = lang_list;
    });
  },
});

export const { setLanguage } = languageSlice.actions;

export const selectCurrentLanguage = ({ language }) => language.language;
export const selectLangList = ({ language }) => language.languages;

export default languageSlice.reducer;
