import React, { Suspense, lazy, memo, useEffect } from "react";
import { NaviHeader } from "../components/headers/Header";
import { useGlobalContext } from "../../context/GlobalContext";
import { useSelector } from "react-redux";
import { selectAll } from "../store/slices/navigationSlice";
import _ from "lodash";
import { NaviFooter } from "../components/footer/Footer";
import { selectFooterNavi } from "../store/slices/footerSlice";
import FooterWidgets from "../pages/footer/FooterWidgets";
import { useParams } from "react-router-dom";
import Maintenance from "../pages/maintenance/Maintenance";
import PageLoading from "../components/loader/PageLoading";
import { selectStatus } from "../store/slices/configSlice";
import { Typography } from "@mui/material";

const RoutesConfigs = lazy(() => import("../configs/RoutesConfigs"));

const PageLayout = (props) => {
  const { headerHeight, footerHeight } = useGlobalContext();
  const navigation = useSelector(selectAll);
  const navifooter = useSelector(selectFooterNavi);
  const sc = useSelector(selectStatus);

  return (
    <div
      className="App"
      style={{
        "--header-height": `${headerHeight}px`,
      }}
    >
      <Suspense fallback={<PageLoading />}>
        {sc === "rejected" ? (
          <Maintenance />
        ) : _.isEmpty(navigation) ? (
          <PageLoading />
        ) : (
          <main
            style={{
              width: "100%",
              height: "100%",
              marginTop: headerHeight,
              marginBottom: footerHeight,
            }}
            className="flex flex-col h-screen"
          >
            {!window.location.pathname.includes("maintenance") && (
              <NaviHeader />
            )}

            <div className="w-full h-full flex-1 !min-h-screen">
              <RoutesConfigs />
            </div>

            {!window.location.pathname.includes("maintenance") && (
              <div className="w-full">
                <FooterWidgets />
                <NaviFooter
                  className={"text-[#828282] bg-darkgray mt-auto"}
                  theme={"dark"}
                />
              </div>
            )}
          </main>
        )}
      </Suspense>
    </div>
  );
};

export default memo(PageLayout);
