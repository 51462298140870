import React, { forwardRef, memo } from "react";
import { Container, Row } from "react-bootstrap";
import { m } from "framer-motion";
import {
  fadeIn,
  fadeInDown,
  fadeInLeft,
} from "../../../../utils/GlobalAnimations";
import Buttons from "../../button/Buttons";
import CustomModal from "../../CustomModal";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../../../store/slices/languageSlice";
import Utils from "../../../../utils/Utils";

const CardWithVideo = forwardRef((props, ref) => {
  const { item, parent } = props;
  const lang = useSelector(selectCurrentLanguage);
  const cl = Utils.isValidJson(parent.className)
    ? JSON.parse(parent.className)
    : parent.className;

  return (
    <section
      ref={ref}
      className={`relative py-[160px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]  `}
      style={{ ...cl }}
    >
      <Container>
        <Row className="items-start">
          <m.div
            className="col-lg-4 col-md-6 col-sm-7 flex flex-col sm:mb-20"
            {...{ ...fadeIn, transition: { delay: 0.2 } }}
          >
            <h1 className="heading-6 font-serif uppercase w-[75%] text-darkgray font-bold mb-[15px] lg:w-[90%] md:w-[75%] md:mb-[10px]">
              We are interior design team based on new york
            </h1>
            <div className="mt-20 w-[70%] mx:lg-0 md:mt-0 xs:w-1/2 xxs:w-[70%]">
              <a
                aria-label="gmail"
                href="mailto:hello@domain.com"
                className="font-serif text-basecolor hover:text-darkgray underline underline-offset-4 font-medium"
              >
                hello@domain.com
              </a>
              <Buttons
                ariaLabel="About Company"
                to="/page/about-us/"
                title="About Company"
              />
            </div>
          </m.div>

          <m.div
            className="col-lg-4 col-md-6 md:mb-24 sm:mb-[50px]"
            {...{ ...fadeIn, transition: { delay: 0.4 } }}
          >
            <div className="font-serif uppercase text-xmd font-semibold text-darkgray mb-[25px] sm:mb-[15px]">
              New design trends 2020
            </div>
            <p className="w-[85%] xl:w-full mb-[25px]">
              Sed ut perspiciatis unde omnis natus error sit voluptam accu
              santium natus error omnis iste natus error sit volupta accu
              santium sed perspiciatis unde omnis iste natus.
            </p>
            <p className="w-[85%] xl:w-full">
              Perspiciatis unde omnis natus error natus voluptatem accu santium
              natus omnis iste natus error sit voluptatem accu santium.
            </p>
          </m.div>

          <m.div
            className="col-lg-4"
            {...{ ...fadeIn, transition: { delay: 0.6 } }}
          >
            <div className="outside-box-bottom relative mb-[-14vw] lg:mb-0">
              <img
                width={360}
                height={542}
                className="relative z-[1] w-full"
                src="https://via.placeholder.com/720x1084"
                alt=""
              />
              {/* Modal Component Start */}
              <CustomModal.Wrapper
                className="absolute bottom-0 right-0 z-[1] landscape:md:!-bottom-[25px]"
                modalBtn={
                  <div className="relative flex items-center p-[35px] right-0 bg-[#f1edea] bottom-[-25px] z-[2] lg:p-[25px] lg:bottom-0 w-[76%] ml-auto cursor-pointer">
                    <Buttons
                      ariaLabel="modal btn"
                      type="submit"
                      title={<i className="icon-control-play" />}
                    />
                    <span className="flex-1 relative ml-3 video-icon-text font-serif text-darkgray text-md uppercase font-semibold">
                      company achievements
                    </span>
                  </div>
                }
              >
                <div className="w-[1020px] max-w-full relative rounded mx-auto">
                  <div className="fit-video">
                    <iframe
                      width="100%"
                      height="100%"
                      className="shadow-[0_0_8px_rgba(0,0,0,0.06)]"
                      controls
                      src="https://www.youtube.com/embed/g0f_BRYJLJE?autoplay=1"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              </CustomModal.Wrapper>
              {/* Modal Component End */}
            </div>
          </m.div>
        </Row>
      </Container>
    </section>
  );
});

export default memo(CardWithVideo);
