import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import fetch from "../../../context/AxiosContext";
import _ from "lodash";
import NavigationModel from "../../model/NavigationModel";
import Utils from "../../../utils/Utils";
import { selectCurrentLanguage } from "./languageSlice";

const footerAdapter = createEntityAdapter({
  selectId: (data) => data.menu_item_id,
  sortComparer: (a, b) => Number(a?.item_order) - Number(b?.item_order),
});

const initialState = footerAdapter.getInitialState({
  selected: null,
  right: [],
});

export const getNavigation = createAsyncThunk(
  "footer/getNavigation",
  async (__, { dispatch, getState }) => {
    try {
      const res = await fetch.get(
        "https://app.floricolor.pt/api/live/json/site/public"
      );

      if (_.isEmpty(res.result)) return Promise.reject(res);

      if (_.isEmpty(res.result.items)) return [];

      const items = res.result.items.map((m) => NavigationModel(m));

      return NavigationCreation(items, true);
    } catch (error) {
      console.error(error);
    }
  }
);

export const constructNavigationFooter = createAsyncThunk(
  "footer/constructNavigationFooter",
  async (result, { dispatch, getState }) => {
    try {
      const language = selectCurrentLanguage(getState());
      const { site_menu_header, site_menu_footer, site_menu_header_right } =
        result;

      const navi = site_menu_footer?.items || [];

      const items = navi.map((m) => NavigationModel(m));

      return NavigationCreation(items, true, language, false);
    } catch (error) {
      console.error(error);
    }
  }
);

export const NavigationCreation = (data, parent, lang, icon, arr = []) => {
  for (let index = 0; index < data.length; index++) {
    const element = data[index];

    const {
      title_lang,
      url_lang,
      url_tabs,
      website_template_id,
      menu_item_tag,
      menu_item_id,
      subitems,
      category_subitems,
    } = element;

    let _type = icon
      ? "icon"
      : parent
      ? "parent"
      : !_.isEmpty(subitems)
      ? "group"
      : "item";
    let type = `footer-${_type}`;

    let path = "";

    if (url_lang && url_lang[lang]) {
      if (url_lang[lang].substring(0, 1) === "/") path = url_lang[lang];
      else path = `/${lang}/${url_lang[lang]}`;
    } else if (menu_item_tag === "home") {
      path = `/${lang}`;
    } else {
      path = `/${lang}/${menu_item_tag}${menu_item_id}`;
    }

    if (url_tabs) path = url_tabs;

    arr.push({
      ...element,
      path,
      type,
    });

    if (subitems && !_.isEmpty(subitems))
      arr[index].subitems = [
        ...NavigationCreation(subitems, false, lang, element.icon_subitems),
      ];
  }

  return arr;
};

export const {
  selectAll: selectFooterNavi,
  selectById,
  selectEntities,
  selectIds,
  selectTotal,
} = footerAdapter.getSelectors((state) => state.footer);

const footerSlice = createSlice({
  name: "footer",
  initialState,
  reducers: {
    setSelectedMenu: (state, action) => {
      state.selected = action.payload;
    },
    setMenuRight: (state, action) => {
      state.right = action.payload;
    },
  },
  extraReducers: (b) => {
    b.addCase(getNavigation.fulfilled, (state, action) => {
      footerAdapter.setAll(state, action.payload);
    }).addCase(constructNavigationFooter.fulfilled, (state, action) => {
      footerAdapter.setAll(state, action.payload);
    });
  },
});

export const { setSelectedMenu, setMenuRight } = footerSlice.actions;

export const selectSelectedMenu = ({ footer }) => footer.selected;
export const selectHeaderRight = ({ footer }) => footer.right;

export default footerSlice.reducer;
