import React, { memo } from "react";

// Libraries
import { useField } from "formik";
import { Form } from "react-bootstrap";

export const Input = memo(
  ({ label, labelClass, className, showErrorMsg, ...props }) => {
    const [field, meta] = useField(props);

    return (
      <label
        className={`block relative ${
          meta.touched && meta.error ? " errors-danger" : ""
        }${labelClass ? ` ${labelClass}` : ""}`}
      >
        {label}
        <input
          className={`${className}${
            meta.touched && meta.error ? " errors-danger" : ""
          }`}
          {...field}
          {...props}
        />
        {meta.touched && meta.error && showErrorMsg ? (
          <span className="text-sm text-error block mt-[5px]">
            {meta.error}
          </span>
        ) : null}
      </label>
    );
  }
);

export const Select = memo(
  ({ label, labelClass, className, showErrorMsg, data, ...props }) => {
    const [field, meta] = useField(props);

    return (
      <label
        className={`block relative ${
          meta.touched && meta.error ? " errors-danger" : ""
        }${labelClass ? ` ${labelClass}` : ""}`}
      >
        {label}
        <Form.Select
          aria-label="select-form"
          className={`${className}${
            meta.touched && meta.error ? " errors-danger" : ""
          }`}
          {...field}
          {...props}
        >
          <option disabled selected value={""}></option>
          {data.map((d, _index) => (
            <option key={_index} value={d.value}>
              {d.label}
            </option>
          ))}
        </Form.Select>
        {meta.touched && meta.error ? (
          <span className="text-sm text-error block mt-[5px]">
            {meta.error}
          </span>
        ) : null}
      </label>
    );
  }
);

export const TextArea = memo(
  ({ label, labelClass, className, showErrorMsg, ...props }) => {
    const [field, meta] = useField(props);

    return (
      <label
        className={`block relative${
          meta.touched && meta.error ? " errors-danger" : ""
        }${labelClass ? ` ${labelClass}` : ""}`}
      >
        {label}
        <textarea
          className={`${className}${
            meta.touched && meta.error ? " errors-danger" : ""
          }`}
          {...field}
          {...props}
        />
        {meta.touched && meta.error && showErrorMsg ? (
          <span className="text-sm text-error block mt-[5px]">
            {meta.error}
          </span>
        ) : null}
      </label>
    );
  }
);

export const Checkbox = memo(
  ({ label, labelClass, className, children, ...props }) => {
    const [field, meta] = useField(props);

    return (
      <label
        className={`block relative${
          meta.touched && meta.error ? " errors-danger" : ""
        }${labelClass ? ` ${labelClass}` : ""}`}
      >
        <input
          type="checkbox"
          className={`${className}${
            meta.touched && meta.error ? " errors-danger" : ""
          }`}
          {...field}
          {...props}
        />
        {children}
      </label>
    );
  }
);

Input.defaultProps = {
  showErrorMsg: true,
};
