import _ from "lodash";
import Utils from "../../utils/Utils";

export const ContentModel = (data) =>
  _.mergeWith(
    {
      post_section_id: null,
      name_tag: "home",
      name_lang: {},
      more_options: {},
    },
    data,
    (obj, src, k) => {
      if (k === "name_lang") return src ? JSON.parse(src) : obj;

      if (k === "more_options")
        return src && Utils.isValidJson(src) ? JSON.parse(src) : obj;

      if (src === null || src === undefined) return obj;
    }
  );

export const SectionsModel = (data) =>
  _.mergeWith(
    {
      post_content_id: null,
      is_active: 1,
      is_group: 0,
      title_lang: {},
      subtitle_widget: {},
      content: {},
      more_options: {},
    },
    data,
    (obj, src, k) => {
      if (k === "title_lang") return src ? JSON.parse(src) : obj;

      if (k === "content") {
        return src && Utils.isValidJson(src)
          ? SectionContentModel(JSON.parse(src))
          : obj;
      }
      if (k === "more_options")
        return src && Utils.isValidJson(src) ? JSON.parse(src) : obj;

      if (k === "is_active" || k === "is_group") return Number(src);

      if (k === "widget_content")
        return !_.isEmpty(src)
          ? src.map((i) => SectionsModel(i)).filter((f) => Boolean(f.is_active))
          : [];

      if (src === null || src === undefined) return obj;
    }
  );

export const SubSectionsModel = (data) =>
  _.mergeWith(
    {
      post_content_id: null,
      is_active: 1,
      is_group: 0,
      title_lang: {},
      subtitle_widget: {},
      content: {},
      more_options: {},
    },
    data,
    (obj, s, k) => {
      if (k === "title_lang") return s ? JSON.parse(s) : obj;

      if (k === "content") {
        return s && Utils.isValidJson(s)
          ? SubSectionContentModel(JSON.parse(s), data.post_content_id)
          : obj;
      }
      if (k === "more_options")
        return s && Utils.isValidJson(s) ? JSON.parse(s) : obj;

      if (k === "is_active" || k === "is_group") return Number(s);

      if (k === "widget_content")
        return !_.isEmpty(s)
          ? s.map((i) => SectionsModel(i)).filter((f) => Boolean(f.is_active))
          : [];

      if (k === "subitems")
        return !_.isEmpty(s) && Array.isArray(s)
          ? s
              .filter((s) => Boolean(Number(s.is_active)))
              .map((s) => SubSectionsModel(s))
          : [];

      if (s === null || s === undefined) return obj;
    }
  );

const SectionContentModel = (data) =>
  _.mergeWith({}, data, (obj, src, k) => {
    if (src && Utils.isValidJson(src)) return JSON.parse(src);

    if (src === null || src === undefined) return obj;
  });

const SubSectionContentModel = (data, id) =>
  _.mergeWith({}, data, (o, s, k, a) => {
    if (k === "image_main") return Utils.formatURL(`posts/${id}/${s}?s=md`);

    if (s && Utils.isValidJson(s)) return JSON.parse(s);

    if (s === null || s === undefined) return o;
  });
