const CounterData01 = [
  {
    number: {
      text: "2530",
    },
    title: "Custom shortcodes",
    content: "Lorem ipsum is simply dummy text the printing typesetting",
  },
  {
    number: {
      text: "2000",
    },
    title: "Completed projects",
    content: "Lorem ipsum is simply dummy text the printing typesetting",
  },
  {
    number: {
      text: "1958",
    },
    title: "Working hours",
    content: "Lorem ipsum is simply dummy text the printing typesetting",
  },
];

const CounterData02 = [
  {
    number: {
      text: "2500",
    },
    title: "WORKING",
    content: "HOURS",
  },
  {
    number: {
      text: "3250",
    },
    title: "PHOTO",
    content: "CAPTURE",
  },
  {
    number: {
      text: "2800",
    },
    title: "WORK",
    content: "COMPLETED",
  },
  {
    number: {
      text: "2750",
    },
    title: "TELEPHONIC",
    content: "TALK",
  },
];

const CounterData03 = [
  {
    number: {
      text: "24",
    },
    title: "COUNTRIES",
  },
  {
    number: {
      text: "28",
    },
    title: "LANGUAGES",
  },
  {
    number: {
      text: "72",
    },
    title: "CHANNELS",
  },
  {
    number: {
      text: "92",
    },
    title: "BRANDS",
  },
];

const CounterData04 = [
  {
    number: {
      text: "220",
    },
    title: "PEOPLE",
  },
  {
    number: {
      text: "380",
    },
    title: "PROJECTS",
  },
  {
    number: {
      text: "700",
    },
    title: "CHANNELS",
  },
  {
    number: {
      text: "240",
    },
    title: "BRANDS",
  },
];

const CounterData05 = [
  {
    number: {
      text: "1227",
      class:
        "text-gradient bg-gradient-to-r from-[#556fff] to-[#e66176] inline-block",
    },
    title: "Winning awards",
    content: "For creative design",
  },
  {
    number: {
      text: "1947",
      class:
        "text-gradient bg-gradient-to-r from-[#556fff] to-[#e66176] inline-block",
    },
    title: "Working hours",
    content: "Desperate for work",
  },
  {
    number: {
      text: "1587",
      class:
        "text-gradient bg-gradient-to-r from-[#556fff] to-[#e66176] inline-block",
    },
    title: "Happy clients",
    content: "We love clients",
  },
];

export {
  CounterData01,
  CounterData02,
  CounterData03,
  CounterData04,
  CounterData05,
};
