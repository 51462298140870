import React, { forwardRef, memo, useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination, Keyboard } from "swiper/modules";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../../../store/slices/languageSlice";
import Utils from "../../../../utils/Utils";

const SlideSimple = forwardRef((props, ref) => {
  const { items, parent } = props;
  const lang = useSelector(selectCurrentLanguage);
  const swiperRef = useRef(null);
  const cl = Utils.isValidJson(parent.className)
    ? JSON.parse(parent.className)
    : parent.className;

  return (
    <section
      className={`relative `}
      ref={ref}
      style={{
        ...cl,
      }}
    >
      {items.length > 1 && (
        <>
          <button
            aria-label="swiper next button"
            onClick={() => swiperRef.current.swiper.slidePrev()}
            className="swiper-button-prev absolute top-[48%] z-[2] left-[40px] text-[40px] text-white w-[50px] leading-[50px] flex justify-center items-center right-inherit cursor-pointer after:hidden hover:opacity-50 transition-default"
          >
            <i className="line-icon-Arrow-OutLeft text-[40px]"></i>
          </button>

          <button
            aria-label="swiper prev button"
            onClick={() => swiperRef.current.swiper.slideNext()}
            className="swiper-button-next absolute top-[48%] z-[2] right-[40px] text-[40px] text-white w-[50px] leading-[50px] flex justify-center items-center  right-inherit cursor-pointer after:hidden hover:opacity-50 transition-default"
          >
            <i className="line-icon-Arrow-OutRight text-[40px]"></i>
          </button>
        </>
      )}

      <Swiper
        pagination={{ clickable: true }}
        keyboard={true}
        ref={swiperRef}
        loop={true}
        modules={[Navigation, Pagination, Keyboard, Autoplay]}
        autoplay={{ delay: 6000, disableOnInteraction: false }}
        className={`full-screen landscape:md:h-[600px] swiper-pagination-02 interior-design-swiper 
        swiper-pagination-light swiper-pagination-medium white-move swiper-default-nav-hidden`}
        //
      >
        {items?.map((item, _item) => (
          <SwiperSlide
            key={_item}
            className="relative overflow-hidden bg-center bg-cover bg-no-repeat slide-widget-img"
            style={{
              backgroundImage: `url(${Utils.formatURL(
                `posts/${item?.post_content_id}/${item?.content?.image_main}`
              )}?s=xl)`,
            }}
          >
            <div className="flex flex-col justify-end ml-auto w-[600px] h-full xl:w-[550px] sm:w-full">
              <div className="bg-white py-28 px-32 xl:py-20 xl:px-24 xs:p-10">
                <Col className="p-0 mb-[25px] md:inline-block items-center justify-center">
                  <span className="font-serif text-basecolor inline-block font-medium align-middle">
                    {String(Number(_item + 1)).padStart(2, "0")}
                  </span>
                  <span className="w-[35px] h-[1px] inline-block align-middle bg-mediumgray ml-[22px] mr-[15px] "></span>
                  <span className="inline-block font-serif text-basecolor uppercase font-medium align-middle">
                    {item?.title_lang && item?.title_lang[lang]}
                  </span>
                </Col>
                <Col className="col-12 p-0 font-serif justify-center mb-[10px]">
                  <div className="flex">
                    <h2 className="heading-4 m-0 self-center text-darkgray uppercase font-bold -tracking-[2px] w-[80%]">
                      {item?.content?.description_lang &&
                        item?.content?.description_lang[lang]}
                    </h2>
                    <span className="self-center text-center ml-[30px]">
                      <Link
                        aria-label="demo"
                        to={item?.content?.link && item?.content?.link[lang]}
                        className="inline-block leading-[65px] rounded-full bg-darkgray w-[60px] h-[60px]"
                      >
                        <i className="feather-arrow-right text-xlg text-white"></i>
                      </Link>
                    </span>
                  </div>
                </Col>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
});

export default memo(SlideSimple);
