import React, { memo, useEffect, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { m, motion } from "framer-motion";
import { fadeIn } from "../../../../../utils/GlobalAnimations";
import ImageGallery from "./components/ImageGallery";
import TabLayout from "../../../../components/tab/TabLayout";
import IconText from "../../../../components/icontext/IconText";
import CTACard from "../../../../components/cta/CTACard";
import {
  getProduct,
  selectProductItem,
  selectProductStatus,
} from "../../store/listSlice";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Keyboard, Navigation, Pagination } from "swiper/modules";
import {
  selectCurrentLanguage,
  t,
} from "../../../../store/slices/languageSlice";
import { setSelectedMenu } from "../../../../store/slices/navigationSlice";
import TabScrollable from "../../../../components/tab/TabScrollable";
import _ from "lodash";
import PageLoading from "../../../../components/loader/PageLoading";
import { setMetas, setPageTitle } from "../../../../store/slices/configSlice";
import { Helmet } from "react-helmet";

const ProductPortifolio = (props) => {
  const dispatch = useDispatch();
  const lang = useSelector(selectCurrentLanguage);
  const params = useParams();
  const item = useSelector(selectProductItem);
  const status = useSelector(selectProductStatus);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      setSelectedMenu({
        website_menu_type_id: "reverse-scroll_dark__",
      })
    );

    if (params.product_id) {
      const id = params.product_id.split("-v")[1];
      dispatch(getProduct(id)).then((r) => {
        if (!r.payload) navigate("/");
      });
    }
  }, [dispatch, params.product_id]);

  useEffect(() => {
    const name = (item?.name_lang && item?.name_lang[lang]) || null;
    name && dispatch(setPageTitle(name));
  }, [item]);

  const swiperRef1 = useRef();

  if (_.isEmpty(item) || status === "pending")
    return (
      <motion.div
        initial="hidden"
        animate="visible"
        exit={{ opacity: 0, transition: { duration: 1 } }}
        variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
      >
        <PageLoading />
      </motion.div>
    );

  return (
    <div className="interior-design" style={props.style}>
      <Helmet>
        <meta
          name="description"
          content={
            (item?.description_lang && item?.description_lang[lang]) || ""
          }
        />
        <meta
          property="og:title"
          content={(item?.name_lang && item?.name_lang[lang]) || ""}
        />
        <meta
          property="og:description"
          content={
            (item?.description_lang && item?.description_lang[lang]) || ""
          }
        />
        <meta
          property="og:image"
          content={
            (!_.isEmpty(item?.slide) &&
              item?.slide[0] &&
              item?.slide[0].picture_src_SEO) ||
            ""
          }
        />
        <meta
          property="og:image:alt"
          content={(item?.name_lang && item?.name_lang[lang]) || ""}
        />
        <meta property="og:url" content={window.location.href} />
      </Helmet>

      {!_.isEmpty(item?.slide) && (
        <section className="relative">
          {item?.slide.length > 1 && (
            <>
              <button
                aria-label="swiper next button"
                onClick={() => swiperRef1.current.swiper.slidePrev()}
                className="swiper-button-prev absolute top-[48%] z-[2] left-[40px] text-[40px] text-white w-[50px] leading-[50px] flex justify-center items-center right-inherit cursor-pointer after:hidden hover:opacity-50 transition-default"
              >
                <i className="line-icon-Arrow-OutLeft text-[40px]"></i>
              </button>
              <button
                aria-label="swiper prev button"
                onClick={() => swiperRef1.current.swiper.slideNext()}
                className="swiper-button-next absolute top-[48%] z-[2] right-[40px] text-[40px] text-white w-[50px] leading-[50px] flex justify-center items-center  right-inherit cursor-pointer after:hidden hover:opacity-50 transition-default"
              >
                <i className="line-icon-Arrow-OutRight text-[40px]"></i>
              </button>
            </>
          )}
          <Swiper
            pagination={{ clickable: true }}
            keyboard={true}
            ref={swiperRef1}
            loop={true}
            modules={[Navigation, Pagination, Keyboard, Autoplay]}
            autoplay={{ delay: 4000, disableOnInteraction: false }}
            className="full-screen landscape:md:h-[600px] swiper-pagination-02 interior-design-swiper swiper-pagination-light swiper-pagination-medium white-move swiper-default-nav-hidden"
          >
            {item?.slide.map((pic, _pic) => (
              <SwiperSlide
                key={_pic}
                className="relative overflow-hidden bg-center bg-cover bg-no-repeat"
                style={{
                  backgroundImage: `url(${pic.picture_src_SEO}?s=xl)`,
                }}
              >
                <div className="flex flex-col justify-end ml-auto w-[600px] h-full xl:w-[550px] sm:w-full">
                  <div className="bg-[#ffffffb3] py-28 px-32 xl:py-20 xl:px-24 xs:p-10">
                    <Col className="p-0 mb-[25px] md:inline-block items-center justify-center">
                      <span className="font-serif text-basecolor inline-block font-medium align-middle">
                        {String(_pic + 1).padStart(2, "0")}
                      </span>
                      <span className="w-[35px] h-[1px] inline-block align-middle bg-mediumgray ml-[22px] mr-[15px] "></span>
                    </Col>
                    <Col className="col-12 p-0 font-serif justify-center mb-[10px]">
                      <div className="flex">
                        <h1 className="heading-4 m-0 self-center text-darkgray uppercase font-bold -tracking-[2px] w-full">
                          {item?.name_lang && item?.name_lang[lang]}
                        </h1>
                      </div>
                    </Col>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </section>
      )}
      {/**
       * PRODUCT IMAGE BACKGROUND
       * END
       */}

      {/**
       * DESCRIPTION
       */}
      <m.section
        className="py-[60px] md:py-[80px] sm:py-[80px] xs:py-[70px]"
        {...fadeIn}
      >
        <Container>
          <Row>
            <Col className="mb-[4%]">
              <h6 className="font-serif text-center text-gray-900 font-medium mb-[25px] lg:[15px]">
                {item?.name_lang && item?.name_lang[lang]}
              </h6>
            </Col>
          </Row>
          <Row className="justify-center">
            <Col xl={8} lg={8} md={10}>
              <m.div className={`dropcaps-style05 contents`}>
                <p className="contents whitespace-pre-line">
                  {item?.description_lang && item?.description_lang[lang] && (
                    <span className="text-basecolor">
                      {item?.description_lang &&
                        item?.description_lang[lang].charAt(0)}
                    </span>
                  )}
                  {item?.description_lang &&
                    item?.description_lang[lang] &&
                    item?.description_lang[lang].slice(1)}
                </p>
              </m.div>
            </Col>
          </Row>
        </Container>
      </m.section>
      {/**
       * DESCRIPTION
       * END
       */}

      {/**
       * IMAGE GALLERY
       */}
      <section className="bg-lightgray border-bottom border-color-extra-light-gray px-[10%] lg:px-0 py-[60px] md:py-[80px] sm:py-[80px] xs:py-[70px]">
        <Container fluid>
          <Row>
            <ImageGallery data={item?.images} />
          </Row>
        </Container>
      </section>
      {/**
       * IMAGE GALLERY
       * END
       */}

      {/**
       * TAB FEATURES
       */}
      <m.section
        className="py-[60px] md:py-[80px] sm:py-[80px] xs:py-[70px] md:px-0 sm:px-0 xs:px-0"
        {...fadeIn}
      >
        <Container>
          {/* <TabLayout item={item} /> */}
          <TabScrollable item={item} />
        </Container>
      </m.section>
      {/**
       * TAB FEATURES
       * END
       */}

      {/**
       * FEATURES
       */}
      <section className="bg-lightgray py-[60px] md:py-[80px] sm:py-[80px] xs:py-[70px]">
        <Container>
          <Row>
            <Col className="mb-[2%]">
              <h6 className="font-serif text-dark text-center font-medium">
                {dispatch(t("common.characteristics"))}
              </h6>
            </Col>
          </Row>
          <IconText
            grid="row-cols-1 row-cols-lg-3 row-cols-sm-2"
            theme="icon-with-text-10"
            animation={fadeIn}
            animationDelay={0.02}
            item={item}
          />
        </Container>
      </section>
      {/**
       * FEATURES
       * END
       */}

      <CTACard url={(item && item.fos3_url) || ""} />
    </div>
  );
};

export default memo(ProductPortifolio);
