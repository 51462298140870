import _ from "lodash";

const NavigationModel = (data) =>
  _.mergeWith(
    {
      menu_item_id: null,
      menu_item_in: null,
      property_key: "generic",
      menu_id: null,
      is_component: "0",
      item_order: "4",
      url_tabs: null,
      menu_item_tag: null,
      title_lang: {},
      url_lang: {},
      subtitle_lang: null,
      description: null,
      icon: null,
      title: null,
      subtitle: null,
      website_menu_type_id: "",
      icon_subitems: false,
      name_lang: {},
    },
    data,
    (obj, src, k) => {
      if (k === "title_lang") return src ? JSON.parse(src) : obj;
      if (k === "url_lang") return src ? JSON.parse(src) : obj;
      if (k === "name_lang") return src ? JSON.parse(src) : obj;

      if (k === "is_active" || k === "is_visible") return Number(src);
      if (k === "icon_subitems") return Boolean(Number(src));

      if (src === null || src === undefined) return obj;
    }
  );

export default NavigationModel;
