import React from "react";
import ReactDOM from "react-dom/client";
import { LazyMotion, domMax } from "framer-motion";
import { ParallaxProvider } from "react-scroll-parallax";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

import "rsuite/dist/rsuite.min.css";
import "./assets/css/icons.css";
import "./assets/css/global.css";
import "./assets/css/pages.css";
import "./index.scss";
import { hydrate } from "react-dom";
import { render } from "react-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <LazyMotion features={domMax}>
    <ParallaxProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ParallaxProvider>
  </LazyMotion>
);
