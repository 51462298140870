import _ from "lodash";
import Utils from "../../../../utils/Utils";

export const FilesModel = (data) =>
  _.mergeWith(
    {
      site_file_download_id: "1",
      item_order: null,
      name_lang: {},
      link: null,
      file_to_download: null,
      thumbnail: null,
      created: null,
      created_by: null,
      updated: null,
      updated_by: null,
    },
    data,
    (obj, src, k) => {
      if (k === "name_lang")
        return src && Utils.isValidJson(src) ? JSON.parse(src) : obj;

      if (k === "file_to_download" || k === "thumbnail")
        return src
          ? Utils.formatURL(
              `data_table/site_file_download/${k}/${data.site_file_download_id}/${src}?s=md`
            )
          : "";

      if (k === "created" || k === "updated")
        return src ? Utils.formatDate(src) : obj;

      if (src === null || src === undefined) return obj;
    }
  );
