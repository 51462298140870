import React, { forwardRef, memo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../../../store/slices/languageSlice";
import Utils from "../../../../utils/Utils";
import { Parallax } from "react-scroll-parallax";

const ContentSimple = forwardRef((props, ref) => {
  const { items, parent } = props;
  const lang = useSelector(selectCurrentLanguage);
  const cl = Utils.isValidJson(parent.className)
    ? JSON.parse(parent.className)
    : parent.className;

  return (
    <section
      ref={ref}
      className={`relative py-[40px] lg:py-[30px] md:py-[30px] sm:py-[30px] xs:py-[20px] `}
      style={{
        backgroundColor: "white",
        ...cl,
      }}
    >
      <div className="h-full w-full"></div>
      <Container>
        <h1 className="inline-block  mb-[0px] text-black text-xmd leading-[20px] -tracking-[.5px] font-serif  whitespace-pre-line">
          {parent?.subtitle_widget && parent?.subtitle_widget[lang]}
        </h1>
        <h1
          className="inline-block  mb-[0px] text-black text-xmd  -tracking-[.5px] font-serif  whitespace-pre-line"
          dangerouslySetInnerHTML={{
            __html: parent?.html_lang && parent?.html_lang[lang],
          }}
        />
      </Container>
    </section>
  );
});

export default memo(ContentSimple);
