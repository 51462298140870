const ModUtils = (() => {
  const wa = JSON.parse(process.env.REACT_APP_ALPHA_ARR);

  const getData = () => {
    const _w = w();
    const _i = i(_w);
    const _t = t();
    const _s = s(_i, _t);
    const _l = l(_s);

    return [_w, _i, _t, _s, _l].join("-");
  };

  const a = () =>
    Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i));

  const ri = () => Math.floor(Math.random() * wa.length);

  const w = () => wa[ri()].toUpperCase();

  const i = (w) =>
    w
      .split("")
      .map((letter) => a().indexOf(letter))
      .join("");

  const t = () => Math.floor(Date.now() / 1000);

  const s = (i, t) => parseInt(i) + t;

  const l = (s) =>
    s
      .toString()
      .split("")
      .map((digit) => a()[parseInt(digit)])
      .join("");

  return {
    getData,
  };
})();

export default ModUtils;
