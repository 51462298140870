import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import fetch from "../../../../context/AxiosContext";
import _ from "lodash";
import { FilesModel } from "../model/FilesModel";

const filesAdapter = createEntityAdapter({
  selectId: (data) => data.site_file_download_id,
});

const initialState = filesAdapter.getInitialState({});

export const getFiles = createAsyncThunk(
  "downloads/files/getFiles",
  async (navigate, { dispatch, getState }) => {
    try {
      const res = await fetch.get("/site/public/files_download");

      if (res.status === "error") throw new Error(res);

      if (_.isEmpty(res.result)) return [];

      const items = await res.result.map((i) => FilesModel(i));

      return items;
    } catch (error) {
      navigate("/");
      return Promise.reject(error);
    }
  }
);

export const { selectAll, selectById, selectEntities, selectIds, selectTotal } =
  filesAdapter.getSelectors((state) => state.downloads.files);

const filesSlice = createSlice({
  name: "downloads/files",
  initialState,
  reducers: {},
  extraReducers: (b) => {
    b.addCase(getFiles.fulfilled, (state, action) => {
      filesAdapter.setAll(state, action.payload);
    }).addCase(getFiles.rejected, (state, action) => {});
  },
});

export default filesSlice.reducer;
