import _ from "lodash";
import React, { memo } from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { m } from "framer-motion";
import NavigationRegister from "./NavigationRegister";
import "../../../../assets/scss/components/_socialicons.scss";

const FooterParent = memo((props) => {
  const { item, isMenuActive, i, handleMenuClick, language } = props;

  const { is_visible } = item;

  const path = item.url_tabs
    ? item.url_tabs
    : item?.url_lang
    ? item.url_lang[language]
      ? `/${language}/${item.url_lang[language]}`
      : ""
    : "";

  if (!Boolean(is_visible)) return null;

  return (
    <Col
      key={item.menu_item_id}
      {...props}
      className={`footer-menu ${props.className}`}
    >
      {!_.isEmpty(item.url_lang) ? (
        <Link
          className={`mb-[20px] block font-medium font-serif xs:!mb-[10px]`}
          to={path}
          target={item.url_tabs && "_blank"}
          rel={item.url_tabs && "noopener noreferrer"}
        >
          {item?.title_lang && item.title_lang[language]}
        </Link>
      ) : (
        <span
          className={`mb-[20px] block font-medium font-serif xs:!mb-[10px]`}
        >
          {item?.title_lang && item.title_lang[language]}
        </span>
      )}
      {item?.icon_subitems && item.subitems ? (
        <ul
          className={`social-icon flex-wrap gap-y-5 gap-x-5 p-0 portrait:justify-center landscape:justify-start flex portrait:md:justify-start portrait:xs:justify-center`}
        >
          {item.subitems.map((_item, _index) => (
            <NavigationRegister
              key={_item.menu_item_id}
              item={_item}
              type={_item.type}
              handleMenuClick={handleMenuClick}
              isMenuActive={isMenuActive}
              language={language}
            />
          ))}
        </ul>
      ) : (
        item.subitems && (
          <ul>
            {item.subitems.map((_item, _index) => (
              <NavigationRegister
                key={_item.menu_item_id}
                item={_item}
                type={_item.type}
                handleMenuClick={handleMenuClick}
                isMenuActive={isMenuActive}
                language={language}
              />
            ))}
          </ul>
        )
      )}
    </Col>
  );
});

export default FooterParent;
