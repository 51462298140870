import React, { forwardRef, memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPageContent,
  selectAll as selectPageData,
} from "../../../store/slices/pageSlice";
import {
  getCategories,
  selectAll,
  selectCategory,
} from "../store/categoriesSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { m, motion } from "framer-motion";
import { fadeIn } from "../../../../utils/GlobalAnimations";
import SliderHorizontal from "../../../components/slider/SliderHorizontal";
import ProductItem from "./ProductItem";
import {
  filteredList,
  searchProducts,
  selectProducts,
  setSearch,
} from "../store/listSlice";
import WidgetRegister from "../../../components/widgets/WidgetsRegister";
import { setSelectedMenu } from "../../../store/slices/navigationSlice";
import { selectCurrentLanguage, t } from "../../../store/slices/languageSlice";
import { IconButton, TextField, Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import _ from "lodash";
import Filters from "./Filters";
import { setPageTitle } from "../../../store/slices/configSlice";

const motionContainer = {
  show: {
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const SearchPage = forwardRef((props, ref) => {
  const { item } = props;
  const dispatch = useDispatch();
  const data = useSelector(selectPageData);
  const lang = useSelector(selectCurrentLanguage);
  const list = useSelector(selectProducts);
  const params = useParams();

  useEffect(() => {
    dispatch(getPageContent({ item }));
    dispatch(setSelectedMenu(item));
    // if (params.search) dispatch(setSearch(params.search));
    if (params.search) {
      dispatch(searchProducts({ search: params.search }));
      dispatch(setPageTitle(params.search));
    }
  }, [dispatch, params.search]);

  return (
    <div style={props.style} ref={ref}>
      {data.map((dt, _index) => {
        const { content, widget_content, post_content_id, ...parent } = dt;
        const { type_widget } = content;

        return (
          <WidgetRegister
            key={post_content_id}
            type={type_widget}
            items={widget_content}
            parent={{ ...dt, ...parent, ...content }}
            options={{
              title_lang: {
                [lang]: dispatch(t("common.search")),
              },
              subtitle_widget: {
                [lang]: params.search
                  ? _.isEmpty(list)
                    ? dispatch(
                        t("WEBSITE.search_description_noresults", {
                          search: params.search,
                        })
                      )
                    : `${dispatch(
                        t("WEBSITE.search_description", {
                          search: params.search,
                        })
                      )}`
                  : "",
              },
            }}
          />
        );
      })}

      <section className="relative pt-0 px-[6%] lg:px-[2%] xs:px-5 pb-10 xs:-mt-10 -mt-20">
        <Container fluid>
          <div
            className={
              "w-full flex !flex-row xs:!flex-col items-center justify-between xs:justify-center"
            }
          >
            <Filters />

            <Typography color={"text.secondary"} className="xs:!mt-2 mt-0">
              {`${dispatch(
                t("WEBSITE.total_result", {
                  length: list.length,
                })
              )}`}
            </Typography>
          </div>
        </Container>
      </section>

      <section className="relative pt-0 py-[130px] px-[4%] lg:py-[90px] lg:px-[2%] md:py-[75px] sm:py-[50px] xs:px-0">
        <Container fluid>
          <Row>
            {/* <Col xs={12} className="xs:px-0 grid grid-rows-12"> */}
            <Col xs={12}>
              <motion.div
                className="grid grid-cols-12 grid-flow-row gap-4"
                variants={motionContainer}
                initial="hidden"
                animate="show"
              >
                {list.map((l, _index) => (
                  <ProductItem key={_index} item={l} />
                ))}
              </motion.div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* <section className="relative pt-0 py-[130px] px-[4%] lg:py-[90px] lg:px-[2%] md:py-[75px] sm:py-[50px] xs:px-0">
        <Container fluid>
          <Row>
            <Col xs={12} className="xs:px-0">
              <ProductItem data={list} />
            </Col>
          </Row>
        </Container>
      </section> */}
    </div>
  );
});

export default memo(SearchPage);
