import React from "react";

// Libraries
import { Col, Container, Row } from "react-bootstrap";
import { AnimatePresence, m } from "framer-motion";

import { fadeIn, fadeInRight, fadeInUp } from "../../../utils/GlobalAnimations";

const Maintenance = () => {
  return (
    <section className="overflow-hidden z-[9999] absolute h-[100vh] flex-1 px-[130px] lg:px-[0px]">
      <Container fluid className="relative">
        <Row className="items-center h-[100vh] landscape:md:h-auto sm:items-start xs:h-auto mxl:justify-center">
          <Col
            md={12}
            xs={12}
            className="text-[#000] font-serif flex flex-row md:!flex-col justify-between items-start sm:items-center sm:text-center sm:px-[100px] sm:pt-[50px] sm:pb-0 sm:h-auto xs:px-[20px] h-full py-[90px]"
          >
            <div className="w-1/2 md:w-full">
              <m.h6
                {...{
                  ...fadeInUp,
                  transition: { delay: 0.4, duration: 0.6, ease: "easeOut" },
                }}
                className="text-[40px] mb-[30px] font-semibold -tracking-[1px] leading-[46px] lg:tracking-normal md:text-[28px] md:leading-[30px] sm:text-[35px] sm:leading-[41px] sm:mb-[20px]"
              >
                The website is in maintenance mode.
              </m.h6>
              <m.span
                {...{
                  ...fadeInUp,
                  transition: { delay: 0.6, duration: 0.6, ease: "easeOut" },
                }}
                className="text-xmd mb-[60px] inline-block opacity-70 sm:mb-[25px]"
              >
                We have almost done all the technical improvements and will be
                back very soon. Thank you for your patience!
              </m.span>
            </div>

            <div className="w-1/2 md:w-full">
              <m.img
                loading="lazy"
                height="100%"
                // className="absolute bottom-0 left-1/2 -translate-x-1/2"
                src="https://cdn.dribbble.com/users/5783521/screenshots/16019652/media/1d6e7b581a59746ab56db702afe9f590.gif?resize=400x0"
                alt="maintenance"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1.4, duration: 0.6, ease: "easeOut" }}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Maintenance;
