import React, { forwardRef, memo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import BlogSimple from "../../blog/BlogSimple";
import { blogData } from "../../blog/BlogData";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../../../store/slices/languageSlice";
import Utils from "../../../../utils/Utils";
import { motionContainer } from "../../../../utils/GlobalAnimations";
import { motion } from "framer-motion";
import ItemSimple from "../../blog/ItemSimple";
import { CardRoundedItem } from "./CardGridWithPage";
import ItemSimpleRounded from "../../blog/ItemSimpleRounded";

const blogSimpleData = blogData.filter((item) => item.blogType === "simple");

const CardGrid = forwardRef((props, ref) => {
  const { items, parent } = props;
  const lang = useSelector(selectCurrentLanguage);
  const cl = Utils.isValidJson(parent.className)
    ? JSON.parse(parent.className)
    : parent.className;

  const options = parent?.content?.more_options || {};

  return (
    <section
      ref={ref}
      className={`py-[10px] lg:py-[10px] md:py-[10px]  xs:py-[50px] relative bg-[#f1edea] `}
      style={{
        ...cl,
      }}
    >
      <Container fluid>
        <Row className="justify-center text-center font-serif">
          <Col
            xl={6}
            lg={7}
            md={8}
            sm={12}
            className="flex flex-col items-center text-center "
          >
            <h2 className="heading-5 text-[32px] font-bold -tracking-[1px] text-darkgray block w-3/5 mb-0">
              {parent?.subtitle_widget && parent?.subtitle_widget[lang]}
            </h2>
          </Col>
        </Row>
      </Container>
      <Container fluid className="px-[6%]">
        <Row className="justify-center">
          <Col xl={12}>
            <div className="grid grid-cols-12 grid-flow-row gap-4 mt-28 md:mt-[4.5rem] sm:mt-8">
              {items.map((l, _index) =>
                Boolean(Number(options?.round)) ? (
                  <ItemSimpleRounded key={_index} item={l} options={options} />
                ) : (
                  <ItemSimple key={_index} item={l} options={options} />
                )
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
});

export default memo(CardGrid);
