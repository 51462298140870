import React, { forwardRef, memo, useState } from "react";
import { fadeIn } from "../../../../utils/GlobalAnimations";
import { m } from "framer-motion";
import { Col, Container, Row } from "react-bootstrap";
import Buttons from "../../button/Buttons";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentLanguage, t } from "../../../store/slices/languageSlice";
import Utils from "../../../../utils/Utils";
import { validateLogin } from "../../../store/slices/userSlice";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";
import _ from "lodash";

const FormLogin = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { items, parent } = props;
  const [loading, setLoading] = useState(false);
  const lang = useSelector(selectCurrentLanguage);
  const cl = Utils.isValidJson(parent.className)
    ? JSON.parse(parent.className)
    : parent.className;

  const schema = yup.object().shape({
    email: yup
      .string()
      .required(dispatch(t("common.input_required")))
      .email(dispatch(t("common.input_invalid_email"))),
    password: yup.string().required(dispatch(t("common.input_required"))),
  });

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, dirtyFields, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(schema),
  });

  const form = watch();

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      await dispatch(validateLogin({ ...data })).then((res) => {
        setLoading(false);
        if (res.payload) navigate(`/${lang}/downloads`);
      });
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <m.section
      className="py-[30px] overflow-hidden lg:py-[20px] md:py-[20px] sm:py-[20px]"
      {...fadeIn}
      style={{
        ...cl,
      }}
    >
      <Container>
        <Row className="justify-center">
          <Col xl={5} md={6} className="lg:px-[30px] md:px-[15px] sm:mb-[40px]">
            <div className="bg-lightgray p-16 lg:mt-[35px] md:p-10 rounded-lg space-y-8">
              <Controller
                control={control}
                name={"email"}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={dispatch(t("common.email"))}
                    placeholder={dispatch(t("common.email"))}
                    fullWidth
                    helperText={errors?.email?.message}
                    error={!!errors?.email}
                    required
                  />
                )}
              />

              <Controller
                control={control}
                name={"password"}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={dispatch(t("common.password"))}
                    placeholder={dispatch(t("common.password"))}
                    fullWidth
                    type="password"
                    helperText={errors?.password?.message}
                    error={!!errors?.password}
                    required
                  />
                )}
              />

              <Buttons
                ariaLabel="login"
                type="submit"
                color={"#fff"}
                onClick={handleSubmit(onSubmit)}
                disabled={_.isEmpty(dirtyFields) || !isValid || loading}
                title={dispatch(t("WEBSITE.login"))}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </m.section>
  );
});

export default memo(FormLogin);
