import _ from "lodash";
import Utils from "../../../../utils/Utils";

export const ProductModel = (data) =>
  _.mergeWith(
    {
      product_id: null,
      name_lang: {},
      description_lang: {},
      main_category_id: null,
      name: null,
      description: null,
      picture: null,
      picture_src: null,
      picture_src_SEO: null,
    },
    data,
    (obj, src, k) => {
      if (k === "pictures") return src.map((s) => ProductPictures(s));

      if (k === "name_lang" || k === "description_lang" || k === "title_lang")
        return src && Utils.isValidJson(src) ? JSON.parse(src) : obj;

      if (k === "external_url")
        return src && Utils.isValidJson(src) ? JSON.parse(src) : obj;

      if (k === "is_active") return Number(src);

      if (src === null || src === undefined) return obj;
    }
  );

const ProductPictures = (data) =>
  _.mergeWith(
    {
      is_main: 0,
      name_lang: {},
      segment: [],
      item_order: "0",
      picture_src: null,
    },
    data,
    (obj, src, k) => {
      if (k === "name_lang" || k === "description_lang")
        return src && Utils.isValidJson(src) ? JSON.parse(src) : obj;

      if (k === "segment")
        return src && Utils.isValidJson(src) ? JSON.parse(src) : obj;

      if (src === null || src === undefined) return obj;
    }
  );

export const PropsModel = (data) =>
  _.mergeWith(
    {
      name_lang: {},
    },
    data,
    (o, s, k) => {
      if (k === "name_lang")
        return s && Utils.isValidJson(s) ? JSON.parse(s) : o;

      if (s === null || s === undefined) return o;
    }
  );
