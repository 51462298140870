import React, { memo, useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";

const PageOutlet = (props) => {
  return (
    <div style={props.style}>
      <Outlet />
    </div>
  );
};

export default memo(PageOutlet);
