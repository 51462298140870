import React, { memo } from "react";
import { Link, useLocation } from "react-router-dom";
import Utils from "../../../../../utils/Utils";

const HorizontalItem = memo((props) => {
  const location = useLocation();
  const { item, isMenuActive, i, handleMenuClick, language, selected } = props;

  const { is_visible, url_tabs, path, pathurl } = item;

  const link = url_tabs || pathurl || path;

  if (!Boolean(is_visible)) return null;

  let active = location.pathname == link;

  return (
    <li
      className={`simple-dropdown 
      ${active ? " active " : ""}
    `}
      key={item.menu_item_id}
    >
      <Link
        className="nav-link"
        to={link}
        target={url_tabs && "_blank"}
        rel={url_tabs && "noopener noreferrer"}
      >
        {item?.title_lang && item.title_lang[language]}
      </Link>
    </li>
  );
});

export default HorizontalItem;
