import React, { forwardRef, memo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../../../store/slices/languageSlice";
import Utils from "../../../../utils/Utils";

const CardSimpleLink = forwardRef((props, ref) => {
  const { items, parent } = props;
  const lang = useSelector(selectCurrentLanguage);
  const cl = Utils.isValidJson(parent.className)
    ? JSON.parse(parent.className)
    : parent.className;

  return items.map((item, _item) => (
    <section
      ref={ref}
      key={_item}
      className={`bg-cover relative bg-center bg-no-repeat  overflow-hidden py-[160px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]`}
      style={{
        backgroundImage:
          item?.content?.image_main &&
          `url(${Utils.formatURL(
            `posts/${item?.post_content_id}/${item?.content?.image_main}?s=md`
          )})`,
        ...cl,
      }}
    >
      <div className="opacity-50  absolute top-0 left-0 w-full h-full"></div>
      <Container>
        <Row className="items-center justify-center">
          <Col className="text-center relative">
            <h2 className="heading-6 font-serif text-xlg md:text-lg xs:text-lg font-semibold  uppercase mb-0">
              {item?.content?.description_lang &&
                item?.content?.description_lang[lang]}{" "}
              &nbsp;
              <a
                aria-label="button"
                href={item?.content?.link && item?.content?.link[lang]}
                className="font-semibold text-xlg pt-0 font-serif uppercase text-decoration-line-bottom md:text-lg md:leading-[26px]"
              >
                {item?.title_lang && item?.title_lang[lang]}
              </a>
            </h2>
          </Col>
        </Row>
      </Container>
    </section>
  ));
});

export default memo(CardSimpleLink);
