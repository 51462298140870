import { Box, IconButton, Paper, Typography, styled } from "@mui/material";
import React, { forwardRef, memo } from "react";
import { useDispatch } from "react-redux";
import { t } from "../../store/slices/languageSlice";
import _ from "lodash";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const FilePicker = forwardRef(
  ({ value, onChange, name, title, errors }, ref) => {
    const dispatch = useDispatch();

    const handleDrop = (files) => {
      try {
        if (!_.isEmpty(files)) {
          const f = files["0"];
          onChange(f);
        }
      } catch (error) {}
    };

    const handleRemove = () => {
      onChange(null);
    };

    const pic =
      value && value instanceof File ? URL.createObjectURL(value) : null;

    const type = value && value instanceof File ? value.type : null;
    const fname = value && value instanceof File ? value.name : null;

    return (
      <Paper
        className={"h-full w-full flex py-1 px-2 items-center"}
        onDragOver={(event) => {
          event.preventDefault();
        }}
        onDrop={(event) => {
          event.preventDefault();
          const {
            dataTransfer: { files },
          } = event;
          handleDrop(files);
        }}
        // component="label"
      >
        <Paper
          elevation={0}
          className="w-full h-full flex-1 cursor-pointer"
          component="label"
        >
          <Typography>{title}</Typography>

          <div className="h-full w-full flex py-2 flex-1 items-center">
            {pic && type.includes("image") && (
              <Box
                className={
                  "w-[4.5rem] h-full aspect-video flex items-center justify-center overflow-hidden mr-5"
                }
              >
                <img
                  className="max-h-[100%] object-contain rounded-md"
                  src={pic}
                  alt={name}
                />
              </Box>
            )}

            {pic ? (
              <Typography
                style={{
                  fontSize: 12,
                }}
              >
                {fname}
              </Typography>
            ) : (
              <Typography
                style={{
                  fontSize: 12,
                }}
              >
                {dispatch(t("common.file_dragndrop"))}
              </Typography>
            )}
          </div>

          <VisuallyHiddenInput
            type="file"
            onChange={({ target: { files } }) => handleDrop(files)}
            style={{
              zIndex: 9999999,
            }}
          />
        </Paper>

        <div>
          <IconButton disabled={!pic} onClick={handleRemove}>
            <i className="feather-x text-lg"></i>
          </IconButton>
        </div>
      </Paper>
    );
  }
);

export default memo(FilePicker);
