import { Box, Typography } from "@mui/material";
import React, { forwardRef, memo, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { CheckTreePicker } from "rsuite";
import { t } from "../../store/slices/languageSlice";
import _ from "lodash";
import Utils from "../../../utils/Utils";

const CheckTree = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const {
    control: { value, onChange, ...field },
    data,
    placeholder,
    ...rest
  } = props;

  const [info, setInfo] = useState([]);

  useMemo(() => {
    if (data)
      setInfo([
        ...data.map((d) => {
          const { children } = d;

          if (!_.isEmpty(children)) {
            let c = [];
            c = [...children.map((c) => ({ ...c }))];
            return {
              ...d,
              children: [...c],
            };
          }

          return {
            ...d,
          };
        }),
      ]);
  }, [data]);

  return (
    <CheckTreePicker
      {...field}
      ref={ref}
      value={value}
      // onChange={(v, e) => {
      //   // console.log(v);
      //   onChange(v);
      // }}
      onClean={() => {
        onChange([]);
      }}
      onSelectItem={(i, p) => {
        if (!_.isEmpty(i.children)) {
          const items = i.children.map((c) => c.value);
          if (i.check) {
            onChange([...value, ...items]);
            return;
          }
          const old = [...value.filter((v) => !items.includes(v))];
          onChange([...old]);
          return;
        }

        if (i.check) {
          onChange([...value, i.value]);
          return;
        }

        const old = [...value.filter((v) => v !== i.value)];
        onChange([...old]);
        return;
      }}
      size="lg"
      data={info}
      renderTreeNode={(nodeData) => {
        // console.log(nodeData);

        return (
          <Box className={"w-full flex flex-row items-center space-x-2"}>
            {nodeData.children ? null : (
              <img
                src={nodeData.picture || Utils.placeholderImage()}
                width={35}
                height={35}
              />
            )}

            <Typography>{nodeData.label}</Typography>
          </Box>
        );
      }}
      style={{
        fontSize: 10,
      }}
      placement={"autoVerticalEnd"}
      open={props.open}
      locale={{
        noResultsText: dispatch(t("common.no_results")),
        placeholder: dispatch(t(placeholder)),
        searchPlaceholder: dispatch(t("common.search")),
        loading: dispatch(t("common.loading")),
        emptyMessage: dispatch(t("common.empty")),
        checkAll: dispatch(t("common.all")),
        newItem: dispatch(t("common.new")),
        createOption: dispatch(t("common.create")),
      }}
    />
  );
});

export default memo(CheckTree);
