import React, { forwardRef, memo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../../../store/slices/languageSlice";
import Utils from "../../../../utils/Utils";
import { Parallax } from "react-scroll-parallax";

const ImageSimple = forwardRef((props, ref) => {
  const { items, parent } = props;
  const lang = useSelector(selectCurrentLanguage);
  const cl = Utils.isValidJson(parent.className)
    ? JSON.parse(parent.className)
    : parent.className;

  return (
    <div
      ref={ref}
      className={`py-[40px] lg:py-[40px] md:py-[30px] sm:py-[30px] xs:py-[30px] relative  w-full  `}
      style={{
        ...cl,
      }}
    >
      <Container>
        <img
          src={Utils.formatURL(
            `posts/${parent?.post_content_id}/${parent?.content?.image_main}`
          )}
        />
      </Container>
    </div>
  );
});

export default memo(ImageSimple);
