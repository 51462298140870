import React, { forwardRef, memo } from "react";
import { Link } from "react-router-dom";

const LinkComponent = forwardRef((props, ref) => {
  const { to, children, ...rest } = props;

  const link = Boolean(to);

  return link ? (
    <Link to={to} {...rest}>
      {children}
    </Link>
  ) : (
    <span {...rest}>{children}</span>
  );
});

export default memo(LinkComponent);
