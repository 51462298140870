import React, { memo } from "react";
import { Link } from "react-router-dom";
import NavigationRegister from "../NavigationRegister";
import _ from "lodash";
import Utils from "../../../../../utils/Utils";

const HorizontalGroup = memo((props) => {
  const { item, isMenuActive, i, handleMenuClick, language, selected } = props;

  const { is_visible, url_tabs, path } = item;

  const link = url_tabs || path;

  if (!Boolean(is_visible)) return null;

  return (
    <li key={item.menu_item_id} className="simple-dropdown">
      {!_.isEmpty(link) ? (
        <Link
          className="nav-link"
          to={link}
          target={url_tabs && "_blank"}
          rel={url_tabs && "noopener noreferrer"}
        >
          {item?.title_lang && item.title_lang[language]}
          {item.subitems && <i className="fas fa-angle-right"></i>}
        </Link>
      ) : (
        <span className="nav-link">
          {item?.title_lang && item.title_lang[language]}
          {item.subitems && <i className="fas fa-angle-right"></i>}
        </span>
      )}

      {item.subitems && (
        <ul className="simple-dropdown-menu">
          {item.subitems.map((_item) => (
            <NavigationRegister
              key={_item.menu_item_id}
              item={_item}
              type={_item.type}
              handleMenuClick={handleMenuClick}
              language={language}
              selected={selected}
              internalpath={path}
            />
          ))}
        </ul>
      )}
    </li>
  );
});

export default HorizontalGroup;
