import React, { forwardRef, memo } from "react";
import { Container, Row } from "react-bootstrap";
import { m } from "framer-motion";
import {
  fadeIn,
  fadeInDown,
  fadeInLeft,
} from "../../../../utils/GlobalAnimations";
import Buttons from "../../button/Buttons";
import CustomModal from "../../CustomModal";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../../../store/slices/languageSlice";
import Utils from "../../../../utils/Utils";

const CardSimple = forwardRef((props, ref) => {
  const { items, parent } = props;
  const lang = useSelector(selectCurrentLanguage);
  const cl = Utils.isValidJson(parent.className)
    ? JSON.parse(parent.className)
    : parent.className;

  return (
    <section
      ref={ref}
      className={`relative py-[160px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px] `}
      style={{
        ...cl,
      }}
    >
      <Container>
        {items.map((item, _item) => (
          <Row key={item.post_content_id} className="items-start">
            <m.div
              className="col-lg-4 col-md-6 col-sm-7 flex flex-col sm:mb-20"
              {...{ ...fadeIn, transition: { delay: 0.2 } }}
            >
              <h1 className="heading-6 font-serif uppercase w-[75%] text-darkgray font-bold mb-[15px] lg:w-[90%] md:w-[75%] md:mb-[10px]">
                {item?.title_lang && item?.title_lang[lang]}
              </h1>
              <div className="mt-20 w-[70%] mx:lg-0 md:mt-0 xs:w-1/2 xxs:w-[70%]">
                <a
                  aria-label="gmail"
                  href="mailto:hello@domain.com"
                  className="font-serif text-basecolor hover:text-darkgray underline underline-offset-4 font-medium"
                >
                  hello@domain.com
                </a>
                <Buttons
                  ariaLabel="About Company"
                  to={item?.content?.link && item?.content?.link[lang]}
                  title="About Company"
                />
              </div>
            </m.div>

            <m.div
              className="col-lg-4 col-md-6 md:mb-24 sm:mb-[50px]"
              {...{ ...fadeIn, transition: { delay: 0.4 } }}
            >
              <div className="font-serif uppercase text-xmd font-semibold text-darkgray mb-[25px] sm:mb-[15px]">
                New design trends 2020
              </div>
              <p className="w-[85%] xl:w-full mb-[25px] whitespace-pre-line">
                {item?.content?.description_lang &&
                  item?.content?.description_lang[lang]}
              </p>
              <div
                className="w-[85%] xl:w-full mb-[25px] whitespace-pre-line"
                dangerouslySetInnerHTML={{
                  __html:
                    item?.content?.html_lang && item?.content?.html_lang[lang],
                }}
              />
            </m.div>

            <m.div
              className="col-lg-4"
              {...{ ...fadeIn, transition: { delay: 0.6 } }}
            >
              <div className="outside-box-bottom relative mb-[-14vw] lg:mb-0">
                <img
                  width={360}
                  height={542}
                  className="relative z-[1] w-full"
                  src={Utils.formatURL(
                    `posts/${item?.post_content_id}/${item?.content?.image_main}?s=md`
                  )}
                  alt=""
                />
              </div>
            </m.div>
          </Row>
        ))}
      </Container>
    </section>
  );
});

export default memo(CardSimple);
