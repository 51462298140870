import React, { useEffect, useState, useRef, memo } from "react";

// Libraries
import { Col } from "react-bootstrap";
import Isotope from "isotope-layout";
import Lightbox from "react-18-image-lightbox";
import { m } from "framer-motion";
import { PropTypes } from "prop-types";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../../../../../store/slices/languageSlice";
import imgdf from "../../../../../../assets/img/floricolor_default.jpeg";

const imageGalleryData02 = [
  {
    double_col: true,
    picture_src_SEO: imgdf,
    name_lang: { pt: "Lightbox gallery image title" },
  },
  {
    picture_src_SEO: imgdf,
    name_lang: { pt: "Lightbox gallery image title" },
  },
  {
    picture_src_SEO: imgdf,
    name_lang: { pt: "Lightbox gallery image title" },
  },
  {
    double_col: true,
    picture_src_SEO: imgdf,
    name_lang: { pt: "Lightbox gallery image title" },
  },
  {
    picture_src_SEO: imgdf,
    name_lang: { pt: "Lightbox gallery image title" },
  },
  {
    picture_src_SEO: imgdf,
    name_lang: { pt: "Lightbox gallery image title" },
  },
  {
    picture_src_SEO: imgdf,
    name_lang: { pt: "Lightbox gallery image title" },
  },
  {
    picture_src_SEO: imgdf,
    name_lang: { pt: "Lightbox gallery image title" },
  },
];

const ImageGallery01 = memo((props) => {
  const lang = useSelector(selectCurrentLanguage);

  return (
    <>
      <img
        width="297"
        height="367"
        className="w-full max-w-full h-auto"
        src={`${props.data.picture_src_SEO}?s=md`}
        alt={
          (props?.data?.name_lang[lang] && props?.data?.name_lang[lang]) ||
          props.data.picture_src_SEO
        }
      />
      <div className="image-box-wrapper absolute w-full h-full left-0 top-0 flex items-center justify-center">
        <i className="feather-zoom-in text-[28px] font-light text-white"></i>
      </div>
    </>
  );
});

const ImageGallery02 = memo((props) => {
  const lang = useSelector(selectCurrentLanguage);

  return (
    <>
      <img
        width="300"
        height="300"
        src={`${props.data.picture_src_SEO}?s=md`}
        alt={
          (props?.data?.name_lang[lang] && props?.data?.name_lang[lang]) ||
          props.data.picture_src_SEO
        }
      />
      <div className="image-box-wrapper absolute w-full h-full left-0 top-0 flex items-center justify-center py-[50px]">
        <i className="feather-zoom-in text-[28px] font-light text-white"></i>
      </div>
    </>
  );
});

const ImageGallery03 = memo((props) => {
  const lang = useSelector(selectCurrentLanguage);

  return (
    <>
      <img
        width="297"
        height="297"
        className="w-full max-w-full h-auto"
        src={`${props.data.picture_src_SEO}?s=md`}
        alt={
          (props?.data?.name_lang[lang] && props?.data?.name_lang[lang]) ||
          props.data.picture_src_SEO
        }
      />
      <div className="image-box-wrapper absolute w-full h-full left-0 top-0  flex items-center justify-center py-[50px]">
        <i className="feather-zoom-in  text-[28px] font-light	text-white"></i>
      </div>
    </>
  );
});

const ImageGallerySwitch = (params, item, i) => {
  switch (params.theme) {
    case "image-gallery-01":
      return <ImageGallery01 data={item} />;
    case "image-gallery-02":
      return <ImageGallery02 data={item} />;
    case "image-gallery-03":
      return <ImageGallery03 data={item} />;
    default: {
      return <ImageGallery01 data={item} />;
    }
  }
};

const ImageGallery = (props) => {
  const lang = useSelector(selectCurrentLanguage);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const BgColor =
    props.overlay && typeof props.overlay === "object"
      ? `linear-gradient(to top right, ${props.overlay.map((item, i) => item)})`
      : props.overlay;
  const imageGallery = useRef();
  const imageGalleryWrapper = useRef();

  const handleClick = (i) => {
    setIsOpen(true);
    setPhotoIndex(i);
    // handleOpenEvent()
  };

  useEffect(() => {
    imageGallery.current = new Isotope(imageGalleryWrapper.current, {
      itemSelector: ".grid-item",
      layoutMode: "masonry",
      percentPosition: true,
      stagger: 0,
      masonry: {
        columnWidth: ".grid-sizer",
      },
    });

    setTimeout(() => {
      imageGallery.current.arrange({ filter: `*` });
    }, 1000);
  }, []);

  return (
    <>
      {isOpen && (
        <Lightbox
          // imageCaption={`${
          //   props.data[photoIndex].name_lang &&
          //   props.data[photoIndex].name_lang[lang]
          //     ? props.data[photoIndex].name_lang[lang]
          //     : ""
          // }... ${photoIndex + 1} / ${props.data.length}`}
          // imageCaption={`${photoIndex + 1} / ${props.data.length}`}
          // imageCaption={`${photoIndex + 1} / ${props.data.length}`}
          imageCaption={
            props.data[photoIndex].description_lang &&
            props.data[photoIndex].description_lang[lang]
              ? props.data[photoIndex].description_lang[lang]
              : ""
          }
          mainSrc={props.data[photoIndex].picture_src_SEO}
          onCloseRequest={() => setIsOpen(false)}
          nextSrc={
            props.data[(photoIndex + 1) % props.data.length].picture_src_SEO
          }
          prevSrc={
            props.data[(photoIndex + props.data.length - 1) % props.data.length]
              .picture_src_SEO
          }
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + props.data.length - 1) % props.data.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % props.data.length)
          }
        />
      )}
      <Col>
        <ul
          ref={imageGalleryWrapper}
          className={`${props.theme} ${
            props.className ? `${props.className}` : ""
          }grid-container ${props.grid ? `${props.grid}` : ""}`}
        >
          <li className="grid-sizer"></li>
          {props.data.map((item, i) => {
            if (i > 5) return null;
            return (
              <m.li
                key={i}
                {...{
                  ...props.animation,
                  transition: { delay: i * props.animationDelay },
                }}
                className={`grid-item${
                  i === 0 || i === 3 ? " grid-item-double" : ""
                }`}
              >
                <div
                  className="image-box"
                  style={{
                    background:
                      props.theme === "image-gallery-03"
                        ? props.overlay[i] && props.overlay[i]
                        : BgColor,
                  }}
                  onClick={() => handleClick(i)}
                >
                  {ImageGallerySwitch(props, item, i)}
                </div>
              </m.li>
            );
          })}
        </ul>
      </Col>
    </>
  );
};

ImageGallery.defaultProps = {
  data: imageGalleryData02,
  animationDelay: 0.2,
  theme: "image-gallery-02",
  grid: "grid grid-4col xl-grid-4col lg-grid-3col md-grid-2col sm-grid-2col xs-grid-1col gutter-large",
  overlay: ["#233130"],
  animation: {
    initial: { opacity: 0 },
    whileInView: { opacity: 1 },
    viewport: { once: true },
    transition: { delay: 0.4 },
  },
};

ImageGallery.propTypes = {
  className: PropTypes.string,
  // data: PropTypes
  //   .arrayOf
  //   // PropTypes.exact({
  //   //   title: PropTypes.string,
  //   //   src: PropTypes.string,
  //   //   double_col: PropTypes.bool,
  //   // })
  //   (),
  animation: PropTypes.object,
  animationDelay: PropTypes.number,
  theme: PropTypes.string,
  grid: PropTypes.string,
  overlay: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default memo(ImageGallery);
