import React, { memo } from "react";
import { Col, Nav, Row, Tab, Tabs } from "react-bootstrap";
import { PropTypes } from "prop-types";
import { m } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentLanguage, t } from "../../store/slices/languageSlice";
import Utils from "../../../utils/Utils";
import _ from "lodash";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";

const TabLayout = (props) => {
  const { item } = props;
  const properties = item?.properties || {};
  const dispatch = useDispatch();
  const lang = useSelector(selectCurrentLanguage);

  return (
    <Tab.Container id="tabs-product" defaultActiveKey={0}>
      <Row className="tab-style-03 justify-center">
        <Col lg={12} className="text-center mb-10">
          <Nav className="">
            <Swiper
              slidesPerView={5}
              className="swiper-pagination-dark  black-move"
              // ref={swiperRef}
              modules={[Navigation, Pagination, Autoplay]}
              {...props.carouselOption}
            >
              {Object.keys(properties).map((k, _index) => {
                if (_.isEmpty(properties[k])) return null;
                if (k === "general_feature") return null;

                return (
                  <SwiperSlide
                    key={_index}
                    className="flex-none rounded-lg overflow-hidden w-auto px-4"
                  >
                    <Nav.Item key={k} className="">
                      <Nav.Link className="text-center" eventKey={_index}>
                        {dispatch(t(`WEBSITE.${k}`))}
                      </Nav.Link>
                    </Nav.Item>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Nav>
        </Col>

        <Col>
          <Tab.Content>
            {Object.keys(properties).map((k, _index) => {
              if (_.isEmpty(properties[k])) return null;
              if (k === "general_feature") return null;
              const arr = properties[k];

              return (
                <Tab.Pane eventKey={_index} key={k}>
                  <Row xs={2}>
                    {arr.map((item, _item) => {
                      const id = Object.keys(item).find((key) =>
                        key.includes(`${k}_id`)
                      );
                      const table = id ? id.replace("_id", "") : "";

                      if (!id || !table) return null;

                      return (
                        <Col
                          key={_item}
                          xl={2}
                          lg={3}
                          md={4}
                          xs={6}
                          className="tab-item"
                        >
                          <div className="flex flex-col py-[20px] md:items-center">
                            {item["picture"] && (
                              <img
                                height={200}
                                width={200}
                                className="rounded-lg"
                                src={`${Utils.formatURL(
                                  `data_table/${table}/picture/${item[id]}/${item["picture"]}`
                                )}?s=xs`}
                                alt=""
                                data-no-retina=""
                              />
                            )}

                            <div className="w-full">
                              <span className="font-medium text-sm text-darkgray ">
                                {item.name_lang && item.name_lang[lang]}
                              </span>
                              {/* <p>{item.content}</p> */}
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </Tab.Pane>
              );
            })}
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
};

TabLayout.defaultProps = {
  data: [],
};

export default memo(TabLayout);
