import React, { memo, useEffect, useRef, useState } from "react";
import { selectCurrentLanguage } from "../../../store/slices/languageSlice";
import { Link, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { m, motion } from "framer-motion";
import Utils from "../../../../utils/Utils";

const itemMotion = {
  hidden: { opacity: 0, y: 20 },
  show: { opacity: 1, y: 0 },
};

const ProductItem = (props) => {
  const { pathname } = useLocation();
  const { item } = props;
  const params = useParams();
  const lang = useSelector(selectCurrentLanguage);
  const [loading, setLoading] = useState(true);

  const paths = pathname.split("/");
  paths.splice(3);
  const path = paths.join("/");

  return (
    <div className="col-span-3 xl:col-span-3 lg:col-span-4 md:col-span-6 sm:col-span-12 text-center">
      <Link
        aria-label="link"
        to={`${path}/${
          item?.name_lang
            ? `p/${Utils.removeDiacritics(item?.name_lang[lang])}-v${
                item?.product_id
              }`
            : ""
        }`}
        className="no-underline"
      >
        <motion.div className="portfolio-switchimg " variants={itemMotion}>
          <div
            className={"portfolio-image portfolio-switchimg overflow-hidden"}
          >
            <img
              src={
                item?.picture_src
                  ? `${item?.picture_src}?s=sm`
                  : Utils.placeholderImage()
              }
              width={300}
              height={200}
              alt={item?.name_lang[lang]}
              className="w-full"
            />
          </div>
          <div className="portfolio-content py-[10px]">
            {item?.name_lang && item?.name_lang[lang] && (
              <span className="inline-block font-medium text-darkgray font-serif">
                {item.name_lang[lang]}
              </span>
            )}
          </div>
        </motion.div>
      </Link>
    </div>
  );
};

export default memo(ProductItem);
