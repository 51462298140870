import React, { forwardRef, memo } from "react";
import { Accordion, Col, Container } from "react-bootstrap";
import { m } from "framer-motion";

import "../../../../assets/scss/components/_accordion.scss";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../../../store/slices/languageSlice";
import Utils from "../../../../utils/Utils";

const AccordionSimple = forwardRef((props, ref) => {
  const { items, parent } = props;
  const lang = useSelector(selectCurrentLanguage);
  const cl = Utils.isValidJson(parent.className)
    ? JSON.parse(parent.className)
    : parent.className;

  return (
    <section
      className="pt-20 switch-tabs"
      style={{
        ...cl,
      }}
    >
      <Container>
        <Col>
          <h6 className="font-serif text-darkgray  font-medium mb-[1%]">
            {parent?.subtitle_widget && parent?.subtitle_widget[lang]}
          </h6>
        </Col>
        <div
          className={`accordion-style-02 light ${
            props.className ? ` ${props.className}` : ""
          }`}
        >
          <Accordion defaultActiveKey={0}>
            {items.map((item, key) => {
              return (
                <m.div
                  className={`accordion-item-wrapper`}
                  key={key}
                  {...{
                    ...props.animation,
                    transition: { delay: key * props.animationDelay },
                  }}
                >
                  <Accordion.Item key={key} eventKey={key}>
                    {item?.title_lang && item?.title_lang[lang] && (
                      <Accordion.Header>
                        {item?.title_lang && item?.title_lang[lang] && (
                          <span className="panel-title">
                            {item?.title_lang[lang]}
                          </span>
                        )}
                      </Accordion.Header>
                    )}
                    {item?.content?.description_lang &&
                      item?.content?.description_lang[lang] && (
                        <Accordion.Body className=" whitespace-pre-line">
                          {item?.content?.description_lang[lang]}
                        </Accordion.Body>
                      )}
                  </Accordion.Item>
                </m.div>
              );
            })}
          </Accordion>
        </div>
      </Container>
    </section>
  );
});

export default memo(AccordionSimple);
