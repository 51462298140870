import React, { memo, useContext, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import "../../../assets/scss/layouts/_footer.scss";
import { useGlobalContext } from "../../../context/GlobalContext";
import FooterParent from "./navigation/FooterParent";
import FooterGroup from "./navigation/FooterGroup";
import FooterItem from "./navigation/FooterItem";
import { registerComponent } from "./navigation/NavigationRegister";
import FooterNavigation from "./navigation/FooterNavigation";
import { useDispatch, useSelector } from "react-redux";
import { selectFooterNavi } from "../../store/slices/footerSlice";
import { selectCurrentLanguage, t } from "../../store/slices/languageSlice";
import FooterIconItem from "./navigation/FooterIconItem";
import { selectLogos } from "../../store/slices/configSlice";

registerComponent("footer-parent", FooterParent);
registerComponent("footer-group", FooterGroup);
registerComponent("footer-item", FooterItem);
registerComponent("footer-icon", FooterIconItem);

export const Footer = (props) => {
  // Add Global Data
  const { setFooterHeight } = useGlobalContext();

  useEffect(
    () => {
      // Calculate Footer Height
      let footerEl = document.querySelector("footer");

      function setTopSpace() {
        let windowWidth = window.innerWidth;
        let footerHeight = 0;

        if (props.parallax) {
          if (props.parallax.desktop === true) {
            footerHeight = footerEl.offsetHeight;
            footerEl.classList.add("pos-fixed");
          }

          if (windowWidth <= 1199) {
            if (props.parallax.lg === false) {
              footerHeight = 0;
              footerEl.classList.remove("pos-fixed");
            }

            if (props.parallax.lg === true) {
              footerHeight = footerEl.offsetHeight;
              footerEl.classList.add("pos-fixed");
            }
          }

          if (windowWidth <= 991) {
            if (props.parallax.md === false) {
              footerHeight = 0;
              footerEl.classList.remove("pos-fixed");
            }

            if (props.parallax.md === true) {
              footerHeight = footerEl.offsetHeight;
              footerEl.classList.add("pos-fixed");
            }
          }

          if (windowWidth <= 767) {
            if (props.parallax.sm === false) {
              footerHeight = 0;
              footerEl.classList.remove("pos-fixed");
            }

            if (props.parallax.sm === true) {
              footerHeight = footerEl.offsetHeight;
              footerEl.classList.add("pos-fixed");
            }
          }

          if (windowWidth <= 575) {
            if (props.parallax.xs === false) {
              footerHeight = 0;
              footerEl.classList.remove("pos-fixed");
            }

            if (props.parallax.xs === true) {
              footerHeight = footerEl.offsetHeight;
              footerEl.classList.add("pos-fixed");
            }
          }
        }

        setFooterHeight(footerHeight - 1);
      }

      setTimeout(setTopSpace, 1000);
      window.addEventListener("resize", function () {
        setTimeout(setTopSpace, 1000);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <footer
      className={`${props.theme}${
        props.className ? ` ${props.className}` : ""
      }`}
      style={props.style}
    >
      {props.children}
    </footer>
  );
};

const FooterMenu = ({ data, titleClass, className, ...props }) => {
  const navigation = useSelector(selectFooterNavi);
  const lang = useSelector(selectCurrentLanguage);

  return (
    <FooterNavigation {...props} navigation={navigation} language={lang} />
  );
};

export const NaviFooter = memo((props) => {
  const dispatch = useDispatch();
  const { dark_theme, light_theme } = useSelector(selectLogos);

  return (
    <Footer
      theme={props.theme}
      className={`${props.className ? ` ${props.className}` : ""}`}
    >
      <div className="pt-[4%] lg:pt-[6%] footer-menu xs:py-[50px]">
        <Container>
          <Row className="justify-between md:text-start xs:text-center gap-y-[60px]">
            <Col
              lg={{ span: 4, order: 0 }}
              md={5}
              sm={{ span: 6, order: 1 }}
              className="md:mb-[50px] xs:mb-[25px]"
            >
              <Link aria-label="link" to="/" className="mb-[20px] block">
                <img
                  alt="logo"
                  src={dark_theme?.horizontal_logo}
                  width="111"
                  height="36"
                  className="xs:m-auto "
                />
              </Link>
              <p className="w-[100%] mb-[20px] xs:w-full">
                {dispatch(t("common.company_description"))}
              </p>
            </Col>
            <FooterMenu
              
              lg={{ span: "auto", order: 0 }}
              md={6}
              sm={{ span: 4, order: 3 }}
              className="justify-between"
              titleClass="capitalize"
            />
          </Row>
        </Container>
      </div>
      <div className="py-[40px] mt-10 border-t border-[#ffffff1a]">
        <Container>
          <Row>
            <Col md={3} className="sm:mb-[20px]"></Col>
            <Col
              md={6}
              className="flex justify-center items-center text-center sm:mb-[20px]"
            >
              <p className="mb-0">
                <a
                  aria-label="codemaker"
                  rel="noreferrer"
                  href="https://codemaker.pt/"
                  className="hover:text-white"
                  target="_blank"
                >
                  &copy; {dispatch(t("common.powered_by"))}
                </a>
              </p>
            </Col>
            <Col md={3} className="text-right sm:text-center"></Col>
          </Row>
        </Container>
      </div>
    </Footer>
  );
});

FooterMenu.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.exact({
      title: PropTypes.string,
      submenu: PropTypes.arrayOf(
        PropTypes.exact({
          title: PropTypes.string,
          link: PropTypes.string,
        })
      ),
    })
  ),
};

Footer.defaultProps = {
  theme: "dark",
};

NaviFooter.defaultProps = {
  data: [],
  className: "bg-darkgray text-[#828282]",
  logo: "/assets/img/webp/logo-white.webp",
  theme: "light",
};

export default memo(FooterMenu);
