import React from "react";
import { GlobalProvider } from "./context/GlobalContext";
import { AxiosInterceptor } from "./context/AxiosContext";
import { Provider } from "react-redux";
import store from "./app/store";
import SnackbarMessage from "./app/components/snackbar/SnackbarMessage";

const withAppProviders = (Component) => (props) => {
  return (
    <Provider store={store}>
      <GlobalProvider>
        <AxiosInterceptor>
          <SnackbarMessage />
          <Component {...props} />
        </AxiosInterceptor>
      </GlobalProvider>
    </Provider>
  );
};

export default withAppProviders;
