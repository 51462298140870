import React, { memo } from "react";
import { m } from "framer-motion";
import { fadeIn } from "../../../utils/GlobalAnimations";
import { Col, Container, Row } from "react-bootstrap";
import Buttons from "../button/Buttons";
import { useDispatch } from "react-redux";
import { t } from "../../store/slices/languageSlice";

const CTACard = ({ url }) => {
  const dispatch = useDispatch();

  return (
    <m.section
      className="py-[60px] md:py-[80px] sm:py-[80px] xs:py-[70px] overflow-hidden"
      {...fadeIn}
    >
      <Container>
        <Row className="justify-center text-center font-serif font-medium text-darkgray">
          <Col xl={7} lg={8} md={10}>
            <span className="tracking-[2px] uppercase opacity-50 block mb-[30px] md:mb-[20px]">
              {dispatch(t("WEBSITE.product_footer_title"))}
            </span>
            <h2 className="-tracking-[2px] mb-[50px] md:mb-[40px] xs:w-[98%] xs:mx-auto">
              {dispatch(t("WEBSITE.product_footer_description"))}
            </h2>
            <Buttons
              ariaLabel="button link"
              to={url}
              href={url}
              target={"_blank"}
              title={dispatch(t("WEBSITE.buy_now"))}
            />
          </Col>
        </Row>
      </Container>
    </m.section>
  );
};

export default memo(CTACard);
