import React, { memo, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Parallax } from "react-scroll-parallax";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage, t } from "../../store/slices/languageSlice";
import { useLocation, useParams } from "react-router-dom";
import _ from "lodash";
import CategoryItem from "./components/CategoryItem";
import { selectAll as selectPageData } from "../../store/slices/pageSlice";
import WidgetRegister from "../../components/widgets/WidgetsRegister";
import { getCategories, selectAll } from "./store/categoriesSlice";
import { m } from "framer-motion";
import SliderHorizontal from "../../components/slider/SliderHorizontal";
import { fadeIn } from "../../../utils/GlobalAnimations";
import { motion } from "framer-motion";
import PageLoading from "../../components/loader/PageLoading";

const motionContainer = {
  show: {
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const Products = (props) => {
  const { item } = props;
  const dispatch = useDispatch();
  const data = useSelector(selectPageData);
  const items = useSelector(selectAll);
  const params = useParams();

  useEffect(() => {
    if (params.lang) {
      dispatch(setLanguage(params.lang));
    }
  }, [params.lang]);

  useEffect(() => {
    if (!_.isEmpty(item) && item.category_id) {
      dispatch(getCategories(item.category_id));
    }
  }, [dispatch, item]);

  if (_.isEmpty(data) || _.isEmpty(items))
    return (
      <motion.div
        initial="hidden"
        animate="visible"
        exit={{ opacity: 0, transition: { duration: 1 } }}
        variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
      >
        <PageLoading />
      </motion.div>
    );

  return (
    <div style={props.style}>
      {data.map((dt, _index) => {
        const {
          content,
          widget_content,
          post_content_id,

          ...parent
        } = dt;
        const { type_widget } = content;

        return (
          <WidgetRegister
            key={post_content_id}
            type={type_widget}
            items={widget_content}
            parent={{ ...dt, ...parent, ...content }}
          />
        );
      })}

      <section className="relative pt-0 py-[130px] px-[4%] lg:py-[90px] lg:px-[2%] md:py-[75px] sm:py-[50px] xs:px-0">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <motion.div
                className="grid grid-cols-12 grid-flow-row gap-4"
                variants={motionContainer}
                initial="hidden"
                animate="show"
              >
                {items.map((l, _index) => (
                  <CategoryItem key={_index} item={l} />
                ))}
              </motion.div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* <section className="relative pt-0 py-[130px] px-[4%] lg:py-[90px] lg:px-[2%] md:py-[75px] sm:py-[50px] xs:px-0">
        <Container fluid>
          <Row>
            <Col xs={12} className="xs:px-0">
              <CategoryItem
                grid="grid grid-4col xl-grid-4col lg-grid-3col md-grid-2col sm-grid-2col xs-grid-1col gutter-extra-large text-center"
                className={"pt-0"}
                filterData={[]}
                data={items}
              />
            </Col>
          </Row>
        </Container>
      </section> */}
    </div>
  );
};

export default memo(Products);
