const portfolioClassicData = [
  {
    title: "Magazine Cover",
    img: "https://via.placeholder.com/800x650",
    category: [""],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Daimler Financial",
    img: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Identity",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Potato Oslands",
    img: "https://via.placeholder.com/800x650",
    category: ["branding"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Daimler Financial",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "photography"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Skoda Corporate",
    img: "https://via.placeholder.com/800x650",
    category: ["photography"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Jeremy Dupont",
    img: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Bill Gardner",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "The Aparthotel",
    img: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Development",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Armchair Mojo",
    img: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "E-commerce",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Truenorth Web",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "graphics", "photography"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Mass Productions",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "graphics"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Cortifiel Look",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics", "photography"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-02",
  },
];

const portfolioClassicMetroData = [
  {
    title: "Magazine Cover",
    img: "https://via.placeholder.com/800x650",
    category: [""],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Biolator Series",
    img: "https://via.placeholder.com/800x650",
    category: ["branding"],
    subtitle: "Identity",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Potato Oslands",
    img: "https://via.placeholder.com/1000x815",
    category: ["logos"],
    subtitle: "Branding",
    double_col: true,
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Daimler Financial",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Skoda Corporate",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics", "branding"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Jeremy Dupont",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Bill Gardner",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics", "branding", "logos"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "The Aparthotel",
    img: "https://via.placeholder.com/1000x815",
    category: ["photography"],
    subtitle: "Development",
    double_col: true,
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Armchair Mojo",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics", "photography", "branding"],
    subtitle: "E-commerce",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Truenorth Web",
    img: "https://via.placeholder.com/800x650",
    category: ["photography", "branding", "graphics", "logos"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Mass Productions",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "graphics"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Cortifiel Look",
    img: "https://via.placeholder.com/800x650",
    category: ["photography", "branding", "logos"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Crop Identity",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "logos"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Pixflow Studio",
    img: "https://via.placeholder.com/800x650",
    category: ["photography"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-04",
  },
];

const portfolioClassicMasonryData = [
  {
    title: "Biolator Series",
    img: "https://via.placeholder.com/800x1200",
    category: [""],
    subtitle: "Identity",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Daimler Financial",
    img: "https://via.placeholder.com/800x650",
    category: [""],
    subtitle: "Identity",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Potato Oslands",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "logos"],
    subtitle: "branding",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Daimler Financial",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "photography"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Skoda Corporate",
    img: "https://via.placeholder.com/800x650",
    category: ["branding"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Jeremy Dupont",
    img: "https://via.placeholder.com/800x650",
    category: ["photography"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Bill Gardner",
    img: "https://via.placeholder.com/800x1200",
    category: ["graphics", "logos"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "The Aparthotel",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics", "logos"],
    subtitle: "Development",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Armchair Mojo",
    img: "https://via.placeholder.com/800x1200",
    category: ["graphics"],
    subtitle: "E-commerce",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Truenorth Web",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "photography"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Mass Productions",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Cortifiel Look",
    img: "https://via.placeholder.com/800x650",
    category: ["photography"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Pixflow Studio",
    img: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-03",
  },
];

const portfolioBoxedData = [
  {
    title: "Love Fashion",
    img: "https://via.placeholder.com/800x650",
    category: [""],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Creative Tropical",
    img: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Foodlly Cafe",
    img: "https://via.placeholder.com/800x650",
    category: ["branding"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Visionen Gestalten",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics", "logos"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Hongo Creative",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "photography"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Pix Flow",
    img: "https://via.placeholder.com/800x650",
    category: ["branding"],
    subtitle: "Identity",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Emperor Dry",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics", "photography"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Herbal Beauty",
    img: "https://via.placeholder.com/800x650",
    category: ["branding"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Tailoring Interior",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics", "photography"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Design Blasting",
    img: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Crop Identity",
    img: "https://via.placeholder.com/800x650",
    category: ["photography"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Harddot Stone",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics", "logos"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-02",
  },
];

const portfolioBoxedMasonryData = [
  {
    title: "Love Fashion",
    img: "https://via.placeholder.com/800x1200",
    category: [""],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Creative Tropical",
    img: "https://via.placeholder.com/800x800",
    category: ["logos"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Foodlly Cafe",
    img: "https://via.placeholder.com/800x1200",
    category: ["branding", "photography"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Visionen Gestalten",
    img: "https://via.placeholder.com/800x800",
    category: ["logos"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Hongo Creative",
    img: "https://via.placeholder.com/800x1200",
    category: ["photography", "logos"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Pix Flow",
    img: "https://via.placeholder.com/800x1200",
    category: ["branding"],
    subtitle: "Identity",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Emperor Dry",
    img: "https://via.placeholder.com/800x800",
    category: ["graphics", "photography"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Herbal Beauty",
    img: "https://via.placeholder.com/800x800",
    category: ["branding"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Tailoring Interior",
    img: "https://via.placeholder.com/800x800",
    category: ["graphics", "photography"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Design Blasting",
    img: "https://via.placeholder.com/800x800",
    category: ["branding", "logos"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Crop Identity",
    img: "https://via.placeholder.com/800x800",
    category: ["graphics"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Harddot Stone",
    img: "https://via.placeholder.com/800x800",
    category: ["graphics"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-02",
  },
];

const portfolioBoxedMetroData = [
  {
    title: "Mongolish",
    img: "https://via.placeholder.com/1000x815",
    category: [""],
    subtitle: "Branding",
    double_col: true,
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Masscoating",
    img: "https://via.placeholder.com/800x650",
    category: ["photography"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Cortifiel",
    img: "https://via.placeholder.com/800x650",
    category: ["logos", "photography"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Everyday",
    img: "https://via.placeholder.com/1000x815",
    category: ["branding", "logos", "photography"],
    subtitle: "Brochure",
    double_col: true,
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Scandinavian",
    img: "https://via.placeholder.com/800x650",
    category: ["logos", "photography"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "The Simplest",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "logos"],
    subtitle: "Website",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Johnny Walker",
    img: "https://via.placeholder.com/1000x815",
    category: ["graphics"],
    subtitle: "Identity",
    double_col: true,
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Zimmermann",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "graphics"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Gestalten",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics", "photography"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Berlining",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "graphics", "logos"],
    subtitle: "Web",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Abstract",
    img: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Mass Productions",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Pixflow Studio",
    img: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Crop Identity",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "logos"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Cortifiel Look",
    img: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-05",
  },
];

const portfolioBorderedData = [
  {
    title: "Motion Picture",
    img: "https://via.placeholder.com/800x650",
    category: [""],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Waterlly Lake",
    img: "https://via.placeholder.com/800x650",
    category: ["branding"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Hipster Land",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Stadt Gestalten",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "photography"],
    subtitle: "Identity",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Berlin Design",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "graphics"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Abstract Comics",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics"],
    subtitle: "Identity",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Teabag Collection",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics", "logos", "photography"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Pixflow Studio",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "logos"],
    subtitle: "Website",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Educamp School",
    img: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Thirdeye Glasses",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "graphics", "logos"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Love Fashion",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics", "logos", "photography"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Rots Schwarz",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "logos", "photography"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-02",
  },
];

const portfolioBorderedMasonryData = [
  {
    title: "Motion Picture",
    img: "https://via.placeholder.com/800x1200",
    category: [""],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Waterlly Lake",
    img: "https://via.placeholder.com/800x800",
    category: ["branding"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Hipster Land",
    img: "https://via.placeholder.com/800x1200",
    category: ["branding", "graphics"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Stadt Gestalten",
    img: "https://via.placeholder.com/800x800",
    category: ["graphics", "logos"],
    subtitle: "Identity",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Berlin Design",
    img: "https://via.placeholder.com/800x1200",
    category: ["logos", "photography"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Abstract Comics",
    img: "https://via.placeholder.com/800x1200",
    category: ["branding", "photography"],
    subtitle: "Identity",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Teabag Collection",
    img: "https://via.placeholder.com/800x800",
    category: ["branding", "graphics"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Pixflow Studio",
    img: "https://via.placeholder.com/800x800",
    category: ["graphics", "logos"],
    subtitle: "Website",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Educamp School",
    img: "https://via.placeholder.com/800x800",
    category: ["logos", "photography"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Thirdeye Glasses",
    img: "https://via.placeholder.com/800x800",
    category: ["photography"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Love Fashion",
    img: "https://via.placeholder.com/800x800",
    category: [],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Rots Schwarz",
    img: "https://via.placeholder.com/800x800",
    category: [],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-02",
  },
];

const portfolioBorderedMetroData = [
  {
    title: "Mongolish",
    img: "https://via.placeholder.com/1000x815",
    category: [""],
    subtitle: "Branding",
    double_col: true,
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Masscoating",
    img: "https://via.placeholder.com/800x650",
    category: ["photography"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Cortifiel",
    img: "https://via.placeholder.com/800x650",
    category: ["logos", "photography"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Everyday",
    img: "https://via.placeholder.com/1000x815",
    category: ["branding", "photography"],
    subtitle: "Brochure",
    double_col: true,
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Scandinavian",
    img: "https://via.placeholder.com/800x650",
    category: ["logos", "photography"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "The Simplest",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "logos"],
    subtitle: "Website",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Johnny Walker",
    img: "https://via.placeholder.com/1000x815",
    category: ["graphics"],
    subtitle: "Identity",
    double_col: true,
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Zimmermann",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "graphics"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Gestalten",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics", "photography"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Berlining",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "graphics", "logos"],
    subtitle: "Web",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Abstract",
    img: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Mass Productions",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Pixflow Studio",
    img: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Crop Identity",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "logos"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Cortifiel Look",
    img: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-05",
  },
];

const portfolioColorfulData = [
  {
    title: "Mongolish",
    img: "https://via.placeholder.com/800x1000",
    category: [""],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Masscoating",
    img: "https://via.placeholder.com/800x1000",
    category: ["graphics"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Cortifiel",
    img: "https://via.placeholder.com/800x1000",
    category: ["branding", "logos"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Everyday",
    img: "https://via.placeholder.com/800x1000",
    category: ["branding", "photography"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Scandinavian",
    img: "https://via.placeholder.com/800x1000",
    category: ["logos"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "The Simplest",
    img: "https://via.placeholder.com/800x1000",
    category: ["graphics"],
    subtitle: "Website",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Johnny Walker",
    img: "https://via.placeholder.com/800x1000",
    category: ["logos", "photography"],
    subtitle: "Identity",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Zimmermann",
    img: "https://via.placeholder.com/800x1000",
    category: ["branding"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Gestalten",
    img: "https://via.placeholder.com/800x1000",
    category: ["logos", "photography"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Berlining",
    img: "https://via.placeholder.com/800x1000",
    category: ["branding", "photography"],
    subtitle: "Web",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Abstract",
    img: "https://via.placeholder.com/800x1000",
    category: ["graphics", "photography"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Teabag",
    img: "https://via.placeholder.com/800x1000",
    category: ["graphics", "photography"],
    subtitle: "Web",
    link: "/portfolio/single-project-page-02",
  },
];

const portfolioColorfulMasonryData = [
  {
    title: "Mongolish",
    img: "https://via.placeholder.com/800x800",
    category: [""],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Masscoating",
    img: "https://via.placeholder.com/800x1200",
    category: ["graphics"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Cortifiel",
    img: "https://via.placeholder.com/800x800",
    category: ["branding"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Everyday",
    img: "https://via.placeholder.com/800x1200",
    category: ["branding", "logos"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Scandinavian",
    img: "https://via.placeholder.com/800x1200",
    category: ["logos"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "The Simplest",
    img: "https://via.placeholder.com/800x1200",
    category: ["graphics"],
    subtitle: "Website",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Johnny Walker",
    img: "https://via.placeholder.com/800x800",
    category: ["logos", "photography"],
    subtitle: "Identity",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Zimmermann",
    img: "https://via.placeholder.com/800x800",
    category: ["branding"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Gestalten",
    img: "https://via.placeholder.com/800x800",
    category: ["logos", "photography"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Berlining",
    img: "https://via.placeholder.com/800x800",
    category: ["branding", "photography"],
    subtitle: "Web",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Abstract",
    img: "https://via.placeholder.com/800x800",
    category: ["graphics", "photography"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Teabag",
    img: "https://via.placeholder.com/800x800",
    category: ["graphics"],
    subtitle: "Web",
    link: "/portfolio/single-project-page-02",
  },
];

const portfolioColorfulMetroData = [
  {
    title: "Mongolish",
    img: "https://via.placeholder.com/1000x815",
    category: [""],
    subtitle: "Branding",
    double_col: true,
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Masscoating",
    img: "https://via.placeholder.com/800x650",
    category: ["photography"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Cortifiel",
    img: "https://via.placeholder.com/800x650",
    category: ["logos", "photography"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Everyday",
    img: "https://via.placeholder.com/1000x815",
    category: ["branding", "logos", "photography"],
    subtitle: "Brochure",
    double_col: true,
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Scandinavian",
    img: "https://via.placeholder.com/800x650",
    category: ["logos", "photography"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "The Simplest",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "logos"],
    subtitle: "Website",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Johnny Walker",
    img: "https://via.placeholder.com/1000x815",
    category: ["graphics"],
    subtitle: "Identity",
    double_col: true,
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Zimmermann",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "graphics"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Gestalten",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics", "photography"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Berlining",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "graphics", "logos"],
    subtitle: "Web",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Abstract",
    img: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Mass Productions",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Pixflow Studio",
    img: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Crop Identity",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "logos"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Cortifiel Look",
    img: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-05",
  },
];

const PortfolioOverlayData = [
  {
    title: "Daimler Financial",
    img: "https://via.placeholder.com/800x650",
    category: [""],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Waterlly Lake",
    img: "https://via.placeholder.com/800x650",
    category: ["branding"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Bill Gardner",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "graphics"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Jeremy Dupont",
    img: "https://via.placeholder.com/800x650",
    category: ["logos", "photography"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Armchair Mojo",
    img: "https://via.placeholder.com/800x650",
    category: ["Photography", "branding"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "The Manchester",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics", "branding"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Everyday Needs",
    img: "https://via.placeholder.com/800x650",
    category: ["logos", "graphics"],
    subtitle: "Identity",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Mass Productions",
    img: "https://via.placeholder.com/800x650",
    category: ["logos", "photography"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Mass Productions",
    img: "https://via.placeholder.com/800x650",
    category: ["logos", "photography"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Truenorth Web",
    img: "https://via.placeholder.com/800x650",
    category: ["logos", "photography"],
    subtitle: "Identity",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "The Simplest",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics", "branding"],
    subtitle: "Identity",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Designblast Design",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-02",
  },
];

const portfolioOverlayMesonryData = [
  {
    title: "Daimler Financial",
    img: "https://via.placeholder.com/800x800",
    category: [""],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Waterlly Lake",
    img: "https://via.placeholder.com/800x1200",
    category: ["branding"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Jeremy Dupont",
    img: "https://via.placeholder.com/800x800",
    category: ["branding", "graphics"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Bill Gardner",
    img: "https://via.placeholder.com/800x1200",
    category: ["logos", "graphics"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "The Manchester",
    img: "https://via.placeholder.com/800x1200",
    category: ["Photography", "logos"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Armchair Mojo",
    img: "https://via.placeholder.com/800x1200",
    category: ["photography", "branding"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Truenorth Web",
    img: "https://via.placeholder.com/800x800",
    category: ["graphics", "branding"],
    subtitle: "Identity",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Mass Productions",
    img: "https://via.placeholder.com/800x800",
    category: ["logos", "graphics"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Cortifiel Look",
    img: "https://via.placeholder.com/800x800",
    category: ["logos", "photography"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Everyday Needs",
    img: "https://via.placeholder.com/800x800",
    category: ["photography", "branding"],
    subtitle: "Identity",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Designblast Design",
    img: "https://via.placeholder.com/800x800",
    category: ["graphics", "branding"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "The Simplest",
    img: "https://via.placeholder.com/800x800",
    category: ["graphics"],
    subtitle: "Identity",
    link: "/portfolio/single-project-page-02",
  },
];

const portfolioOverlayMetroData = [
  {
    title: "Mongolish",
    img: "https://via.placeholder.com/1000x815",
    category: [""],
    subtitle: "Branding",
    double_col: true,
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Masscoating",
    img: "https://via.placeholder.com/800x650",
    category: ["photography"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Cortifiel",
    img: "https://via.placeholder.com/800x650",
    category: ["logos", "photography"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Everyday",
    img: "https://via.placeholder.com/1000x815",
    category: ["branding", "photography"],
    subtitle: "Brochure",
    double_col: true,
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Scandinavian",
    img: "https://via.placeholder.com/800x650",
    category: ["logos", "photography"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "The Simplest",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "logos"],
    subtitle: "Website",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Johnny Walker",
    img: "https://via.placeholder.com/1000x815",
    category: ["graphics"],
    subtitle: "Identity",
    double_col: true,
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Zimmermann",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "graphics"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Gestalten",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics", "photography"],
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Berlining",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "graphics", "logos"],
    subtitle: "Web",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Abstract",
    img: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Mass Productions",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Pixflow Studio",
    img: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Crop Identity",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "logos"],
    subtitle: "Branding",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Cortifiel Look",
    img: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Photography",
    link: "/portfolio/single-project-page-05",
  },
];

const portfolioSwitchImgData = [
  {
    title: "Magazine Cover",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: [""],
    subtitle: "Design and create brand",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Biolator Series",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["branding"],
    subtitle: "Most wonderful place",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Potato Oslands",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["branding", "graphics"],
    subtitle: "You have a good family",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Daimler Financial",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["graphics", "logos"],
    subtitle: "These are good suggestions",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Skoda Corporate",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["logos", "photography"],
    subtitle: "Carmen is a good wife",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Jeremy Dupont",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["branding", "photography"],
    subtitle: "Everything was in good",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Bill Gardner",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["branding", "graphics"],
    subtitle: "Quit talking and begin doing",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "The Aparthotel",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["graphics", "logos"],
    subtitle: "Opportunity in every difficulty",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Armchair Mojo",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["logos", "photography"],
    subtitle: "You learn more from failure",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Truenorth Web",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["branding", "photography"],
    subtitle: "You are working on something",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Mass Productions",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["branding", "graphics"],
    subtitle: "Failure will never overtake",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Cortifiel Look",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["graphics"],
    subtitle: "Imagine your life",
    link: "/portfolio/single-project-page-02",
  },
];

const portfolioSwitchImgMesonryData = [
  {
    title: "Magazine Cover",
    frontimg: "https://via.placeholder.com/800x1200",
    backimg: "https://via.placeholder.com/800x1200",
    category: [""],
    subtitle: "Most wonderful place",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Biolator Series",
    frontimg: "https://via.placeholder.com/800x800",
    backimg: "https://via.placeholder.com/800x800",
    category: ["branding"],
    subtitle: "Design and create brand",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Daimler Financial",
    frontimg: "https://via.placeholder.com/800x1200",
    backimg: "https://via.placeholder.com/800x1200",
    category: ["branding", "graphics"],
    subtitle: "These are good suggestions",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Potato Oslands",
    frontimg: "https://via.placeholder.com/800x800",
    backimg: "https://via.placeholder.com/800x800",
    category: ["graphics", "logos"],
    subtitle: "You have a good family",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Skoda Corporate",
    frontimg: "https://via.placeholder.com/800x1200",
    backimg: "https://via.placeholder.com/800x1200",
    category: ["logos", "photography"],
    subtitle: "Carmen is a good wife",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Jeremy Dupont",
    frontimg: "https://via.placeholder.com/800x1200",
    backimg: "https://via.placeholder.com/800x1200",
    category: ["branding", "photography"],
    subtitle: "Everything was in good",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Bill Gardner",
    frontimg: "https://via.placeholder.com/800x800",
    backimg: "https://via.placeholder.com/800x800",
    category: ["branding", "graphics"],
    subtitle: "Quit talking and begin doing",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "The Aparthotel",
    frontimg: "https://via.placeholder.com/800x800",
    backimg: "https://via.placeholder.com/800x800",
    category: ["graphics", "logos"],
    subtitle: "Opportunity in every difficulty",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Truenorth Web",
    frontimg: "https://via.placeholder.com/800x800",
    backimg: "https://via.placeholder.com/800x800",
    category: ["logos", "photography"],
    subtitle: "You are working on something",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Armchair Mojo",
    frontimg: "https://via.placeholder.com/800x800",
    backimg: "https://via.placeholder.com/800x800",
    category: ["photography"],
    subtitle: "You learn more from failure",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Cortifiel Look",
    frontimg: "https://via.placeholder.com/800x800",
    backimg: "https://via.placeholder.com/800x800",
    category: [""],
    subtitle: "Imagine your life",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Mass Productions",
    frontimg: "https://via.placeholder.com/800x800",
    backimg: "https://via.placeholder.com/800x800",
    category: [""],
    subtitle: "Failure will never overtake",
    link: "/portfolio/single-project-page-02",
  },
];

const portfolioSwitchImgMetroData = [
  {
    title: "Magazine Cover",
    frontimg: "https://via.placeholder.com/1000x815",
    backimg: "https://via.placeholder.com/1000x815",
    category: [""],
    subtitle: "Design and create brand",
    double_col: true,
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Biolator Series",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["photography"],
    subtitle: "Most wonderful place",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Potato Oslands",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["logos", "photography"],
    subtitle: "You have a good family",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Daimler Financial",
    frontimg: "https://via.placeholder.com/1000x815",
    backimg: "https://via.placeholder.com/1000x815",
    category: ["branding", "photography"],
    subtitle: "These are good suggestions",
    double_col: true,
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Skoda Corporate",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["logos", "photography"],
    subtitle: "Carmen is a good wife",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Jeremy Dupont",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["branding", "logos"],
    subtitle: "Everything was in good",
    link: "/portfolio/single-project-page-01",
  },

  {
    title: "Bill Gardner",
    frontimg: "https://via.placeholder.com/1000x815",
    backimg: "https://via.placeholder.com/1000x815",
    category: ["graphics"],
    subtitle: "Quit talking and begin doing",
    double_col: true,
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "The Aparthotel",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["branding", "graphics"],
    subtitle: "Opportunity in every difficulty",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Armchair Mojo",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["graphics", "photography"],
    subtitle: "You learn more from failure",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Truenorth Web",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["branding", "graphics", "logos"],
    subtitle: "You are working on something",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Mass Productions",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Failure will never overtake",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Crop Identity",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["graphics"],
    subtitle: "Failure will never overtake",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Pixflow Studio",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Failure will never overtake",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Cortifiel Look",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["branding", "logos"],
    subtitle: "Failure will never overtake",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Educamp School",
    frontimg: "https://via.placeholder.com/800x650",
    backimg: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Failure will never overtake",
    link: "/portfolio/single-project-page-05",
  },
];

const portfolioSliderData = [
  {
    title: "Simply Apple",
    subtitle: "Create brand",
    img: "https://via.placeholder.com/800x1000",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "SBiolator Series",
    subtitle: "Wonderful place",
    img: "https://via.placeholder.com/800x1000",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Potato Oslands",
    subtitle: "Good family love",
    img: "https://via.placeholder.com/800x1000",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Daimler Financial",
    subtitle: "Good suggestions",
    img: "https://via.placeholder.com/800x1000",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Skoda Corporate",
    subtitle: "Carmen wife",
    img: "https://via.placeholder.com/800x1000",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Jeremy Dupont",
    subtitle: "Everything good",
    img: "https://via.placeholder.com/800x1000",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Bill Gardner",
    subtitle: "Quit talking",
    img: "https://via.placeholder.com/800x1000",
    link: "/portfolio/single-project-page-02",
  },
];

const portfolioScatteredData = [
  {
    title: "Magazine Cover",
    img: "https://via.placeholder.com/800x550",
    subtitle: "Photography",
    category: [""],
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Biolator Series",
    img: "https://via.placeholder.com/800x1070",
    subtitle: "Identity",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "Potato Oslands",
    img: "https://via.placeholder.com/800x550",
    subtitle: "Branding",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Daimler Financial",
    img: "https://via.placeholder.com/800x1070",
    subtitle: "Branding",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Skoda Corporate",
    img: "https://via.placeholder.com/800x1070",
    subtitle: "Photography",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Jeremy Dupont",
    img: "https://via.placeholder.com/800x550",
    subtitle: "Brochure",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Bill Gardner",
    img: "https://via.placeholder.com/800x1070",
    subtitle: "Branding",
    link: "/portfolio/single-project-page-02",
  },
  {
    title: "The Aparthotel",
    img: "https://via.placeholder.com/800x550",
    subtitle: "Development",
    link: "/portfolio/single-project-page-03",
  },
  {
    title: "Armchair Mojo",
    img: "https://via.placeholder.com/800x550",
    subtitle: "E-commerce",
    link: "/portfolio/single-project-page-04",
  },
  {
    title: "Truenorth Web",
    img: "https://via.placeholder.com/800x1070",
    subtitle: "Photography",
    link: "/portfolio/single-project-page-05",
  },
  {
    title: "Mass Productions",
    img: "https://via.placeholder.com/800x550",
    subtitle: "Branding",
    link: "/portfolio/single-project-page-01",
  },
  {
    title: "Cortifiel Look",
    img: "https://via.placeholder.com/800x1070",
    subtitle: "Photography",
    link: "/portfolio/single-project-page-02",
  },
];

const portfolioJustifiedGalleryData = [
  {
    title: "Branding",
    img: "https://via.placeholder.com/800x1000",
    category: [""],
    subtitle: "Armchair",
  },
  {
    title: "Photography",
    img: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Identity",
  },
  {
    title: "Branding",
    img: "https://via.placeholder.com/800x1200",
    category: ["branding"],
    subtitle: "Educamp",
  },
  {
    title: "Web",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "photography"],
    subtitle: "Cortifiel",
  },
  {
    title: "Branding",
    img: "https://via.placeholder.com/800x1000",
    category: ["photography"],
    subtitle: "Instacuppa",
  },
  {
    title: "Branding",
    img: "https://via.placeholder.com/800x1000",
    category: ["logos"],
    subtitle: "Pendant",
  },
  {
    title: "Branding",
    img: "https://via.placeholder.com/800x1200",
    category: ["graphics"],
    subtitle: "Everyday",
  },
  {
    title: "Branding",
    img: "https://via.placeholder.com/800x1000",
    category: ["logos"],
    subtitle: "Instacuppa",
  },
  {
    title: "Photography",
    img: "https://via.placeholder.com/800x650",
    category: ["logos"],
    subtitle: "Henson",
  },
  {
    title: "Branding",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "graphics", "photography"],
    subtitle: "Willmann",
  },
  {
    title: "Branding",
    img: "https://via.placeholder.com/800x650",
    category: ["branding", "graphics"],
    subtitle: "Willmann",
  },
  {
    title: "Branding",
    img: "https://via.placeholder.com/800x1200",
    category: ["graphics", "photography"],
    subtitle: "Nothing",
  },
  {
    title: "Branding",
    img: "https://via.placeholder.com/800x1000",
    category: ["graphics", "photography"],
    subtitle: "Pritchard",
  },
  {
    title: "Photography",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics", "photography"],
    subtitle: "Herbally",
  },
  {
    title: "Brochure",
    img: "https://via.placeholder.com/1000x815",
    category: ["graphics", "photography"],
    subtitle: "Adapter",
  },
  {
    title: "Branding",
    img: "https://via.placeholder.com/800x650",
    category: ["graphics", "photography"],
    subtitle: "Whistles",
  },
  {
    title: "Photography",
    img: "https://via.placeholder.com/800x1000",
    category: ["graphics", "photography"],
    subtitle: "Harddot",
  },
];

const FilterData = [
  {
    title: "All",
    key: "*",
  },
  {
    title: "BRANDING",
    key: "branding",
  },
  {
    title: "GRAPHICS",
    key: "graphics",
  },
  {
    title: "LOGOS",
    key: "logos",
  },
  {
    title: "PHOTOGRAPHY",
    key: "photography",
  },
];

const landingPageFilterData = [
  {
    title: "All",
    key: "*",
  },
  {
    title: "CORPORATE",
    key: "corporate",
  },
  {
    title: "SPECIALIZED",
    key: "specialized",
  },
  {
    title: "PORTFOLIO",
    key: "portfolio",
  },
  {
    title: "E-COMMERCE",
    key: "e-commerce",
  },
  {
    title: "BLOG",
    key: "blog",
  },
];

export {
  portfolioClassicData,
  portfolioClassicMetroData,
  portfolioClassicMasonryData,
  portfolioBoxedData,
  portfolioBoxedMasonryData,
  portfolioBoxedMetroData,
  portfolioBorderedData,
  portfolioBorderedMetroData,
  portfolioBorderedMasonryData,
  portfolioColorfulData,
  portfolioColorfulMasonryData,
  portfolioColorfulMetroData,
  PortfolioOverlayData,
  portfolioOverlayMesonryData,
  portfolioOverlayMetroData,
  portfolioSwitchImgData,
  portfolioSwitchImgMesonryData,
  portfolioSwitchImgMetroData,
  portfolioSliderData,
  portfolioScatteredData,
  portfolioJustifiedGalleryData,
  FilterData,
  landingPageFilterData,
};
