import React, { memo } from "react";
import NavigationRegister from "../NavigationRegister";

const HorizontalNavigation = (props) => {
  const { navigation, isMenuActive, handleMenuClick, language, selected } =
    props;

  return (
    <ul className="navbar-nav">
      {navigation.map((_item, _index) => (
        <NavigationRegister
          key={_item.menu_item_id}
          item={_item}
          i={_index}
          type={_item.type}
          handleMenuClick={handleMenuClick}
          isMenuActive={isMenuActive}
          language={language}
          selected={selected}
        />
      ))}
    </ul>
  );
};

export default memo(HorizontalNavigation);
