import React, { forwardRef, memo, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { m } from "framer-motion";
import Overlap from "../../overlap/Overlap";
import { fadeInLeft, fadeInDown } from "../../../../utils/GlobalAnimations";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import Buttons from "../../button/Buttons";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../../../store/slices/languageSlice";
import Utils from "../../../../utils/Utils";

const SlideCards = forwardRef((props, ref) => {
  const { items, parent } = props;
  const lang = useSelector(selectCurrentLanguage);
  const swiperRef = useRef(null);
  const cl = Utils.isValidJson(parent.className)
    ? JSON.parse(parent.className)
    : parent.className;

  return (
    <section
      ref={ref}
      className={`bg-[#f1edea] pb-[130px] lg:py-[90px] md:py-[75px] sm:py-[50px] overflow-hidden xs:pt-[9%] xs:px-[15px] `}
      style={{ ...cl }}
    >
      <Container className="pt-[8%] lg:pt-0">
        <Row className="justify-center md:justify-start text-center font-serif mb-28 lg:mb-16 md:mb-12 xs:mb-20">
          <Col lg={6} sm={8} className="md:text-left xs:px-0">
            <span className="text-basecolor font-medium mb-[20px] block uppercase">
              {parent?.title_lang && parent?.title_lang[lang]}
            </span>
            <h2 className="heading-5 font-serif font-bold whitespace-pre-line -tracking-[1px] uppercase text-darkgray block md:text-start mb-0">
              {parent?.subtitle_widget && parent?.subtitle_widget[lang]}
            </h2>
          </Col>
        </Row>

        <Row className="items-center">
          <m.div
            className="relative md:mb-[20px] col-xl-3 col-lg-4 col-md-6 pt-[65px] md:pt-0 xs:p-0"
            {...fadeInLeft}
          >
            <span className="font-serif mb-[20px] block uppercase font-medium">
              Expert services
            </span>
            <h2 className="heading-6 font-serif uppercase text-darkgray font-bold mb-[40px] md:mb-[20px]">
              We like to make you feel more on your home
            </h2>
            <div className="flex">
              <div
                onClick={() => swiperRef.current.swiper.slidePrev()}
                className="btn-slider-next text-[40px] text-[#828282] hover:text-black transition-default leading-[40px] w-auto h-[40px] mr-[25px]"
              >
                <button aria-label="swiper next" className="">
                  <i className="line-icon-Arrow-OutLeft"></i>
                </button>
              </div>
              <div
                onClick={() => swiperRef.current.swiper.slideNext()}
                className="btn-slider-prev text-[40px] text-[#828282] hover:text-black transition-default leading-[40px] w-auto h-[40px]"
              >
                <button aria-label="swiper prev" className="">
                  <i className="line-icon-Arrow-OutRight"></i>
                </button>
              </div>
            </div>
          </m.div>

          <m.div className="col-lg-8 offset-xl-1" {...fadeInLeft}>
            <Swiper
              className="interiordesign-icon-with-text black-move swiper-pagination-medium h-full min-w-[1170px] md:min-w-full"
              ref={swiperRef}
              spaceBetween={30}
              slidesPerView={1}
              observer={true}
              observeParents={true}
              loop={true}
              modules={[Autoplay]}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              keyboard={{
                enabled: true,
                onlyInViewport: true,
              }}
              breakpoints={{
                992: { slidesPerView: 3 },
                768: { slidesPerView: 2 },
              }}
            >
              <>
                <Row className={`${props.grid} md:justify-center`}>
                  {items.map((item, _item) => {
                    return (
                      <SwiperSlide key={item.post_content_id}>
                        <m.div
                          className="feature-box-bg-white-hover border-[1px] border-[#0000001a] overflow-hidden"
                          {...{
                            ...props.animation,
                            transition: { delay: 0.2 },
                          }}
                        >
                          <div className="feature-box-move-bottom-top py-12 px-16 md:py-8 md:px-10 sm:py-12 sm:px-[4.5rem] xs:pt-16">
                            <h2 className="heading-4 font-serif font-medium text-[#c3964e] mb-[20px] -tracking-[2px]">{`${String(
                              _item + 1
                            ).padStart(2, "0")}`}</h2>
                            <div className="feature-box-content">
                              {item?.title_lang && (
                                <span className="font-semibold text-darkgray title font-serif uppercase text-xmd mb-[10px] inline-block">
                                  {item.title_lang[lang]}
                                </span>
                              )}
                              {item?.content?.description_lang && (
                                <p>{item?.content?.description_lang[lang]}</p>
                              )}
                            </div>
                            <div className="move-bottom-top w-full flex justify-start mt-[15px] xs:mt-0">
                              {item?.content?.link && (
                                <Buttons
                                  ariaLabel="swiper btn"
                                  className="font-medium font-serif uppercase btn-link after:h-[2px] md:text-md md:mb-[15px] after:bg-basecolor hover:text-basecolor text-[#c3964e]"
                                  to={item.content.link[lang]}
                                  title={item.content.link[lang]}
                                />
                              )}
                            </div>
                          </div>
                        </m.div>
                      </SwiperSlide>
                    );
                  })}
                </Row>
              </>
            </Swiper>
          </m.div>
        </Row>
      </Container>
    </section>
  );
});

export default memo(SlideCards);
