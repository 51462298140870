import { Outlet, Route } from "react-router-dom";
import _ from "lodash";
import { lazy } from "react";
import CategoriesPage from "../app/pages/products/components/CategoriesPage";
import ProductPortifolio from "../app/pages/products/components/Product/ProductPortifolio";
import Downloads from "../app/pages/restricted/Downloads";
import SearchPage from "../app/pages/products/components/SearchPage";
import imgdf from "../assets/img/floricolor_default.jpeg";
import SubPageTemplate from "../app/pages/standard/SubPageTemplate";
import PageOutlet from "../app/pages/standard/PageOutlet";

const ProductsPage = lazy(() => import("../app/pages/products/ProductsPage"));
const PagesRegister = lazy(() => import("../app/pages/PagesRegister"));
const Products = lazy(() => import("../app/pages/products/Products"));

class Utils {
  static formatURL(complement) {
    const current =
      window.location.hostname === "localhost" ||
      ["192.168.102.79", "192.168.102.79:3000"].includes(
        window.location.hostname
      )
        ? `https://floricolor.com`
        : window.location.origin;

    const assets = `api/live/box`;

    const string = `${current}/${assets}/${complement}`;

    return string;
  }

  static generateRoutesFromConfigs(data, lang, arr = []) {
    for (let index = 0; index < data.length; index++) {
      const element = data[index];

      const {
        title_lang,
        url_lang,
        website_template_id,
        subitems,
        menu_item_id,
        path,
        url_tabs,
      } = element;

      if (url_tabs) continue;

      if (website_template_id === "layout_products") {
        arr.push({
          path,
          element: <ProductsPage item={element} />,
          children: [
            {
              path: "",
              element: <Products item={element} />,
            },
            {
              path: "c/:category_id",
              element: <CategoriesPage item={element} />,
            },
            {
              path: "p/:product_id",
              element: <ProductPortifolio item={element} />,
            },
            {
              path: "s/:search",
              element: <SearchPage item={element} />,
            },
          ],
        });

        continue;
      }

      if (website_template_id === "layout_downloads") {
        arr.push({
          path,
          element: <Downloads item={element} />,
        });

        continue;
      }

      const template = website_template_id || "layout_default_i";

      if (website_template_id === "layout_subpage") {
        arr.push({
          path,
          element: <PageOutlet type={"layout_default_i"} item={element} />,
          children: [
            {
              path: "",
              element: (
                <PagesRegister type={"layout_default_i"} item={element} />
              ),
            },
            {
              path: ":post",
              element: <SubPageTemplate item={element} />,
            },
          ],
        });

        continue;
      }

      if (path)
        arr.push({
          path,
          element: <PagesRegister type={template} item={element} />,
        });

      if (subitems && !_.isEmpty(subitems))
        arr.push(...this.generateRoutesFromConfigs(subitems, lang));
    }

    return arr;
  }

  static getFlatNavigation(navigationItems, flatNavigation = []) {
    for (let i = 0; i < navigationItems.length; i += 1) {
      const navItem = navigationItems[i];

      flatNavigation.push(navItem);

      if (navItem.subitems && !_.isEmpty(navItem.subitems))
        flatNavigation.push(...this.getFlatNavigation(navItem.subitems));
    }
    return flatNavigation;
  }

  static isValidJson(jsonString) {
    try {
      JSON.parse(jsonString);
      return true;
    } catch (e) {
      return false;
    }
  }

  static removeDiacritics(str) {
    if (!str) return str;

    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036F]/g, "")
      .replace(/[^\w]/g, "_")
      .toLowerCase();
  }

  static searchItemInArray(array, searchText) {
    for (const item of array) {
      const propArray = Object.keys(item);

      for (let i = 0; i < propArray.length; i += 1) {
        const key = propArray[i];
        const value = item[key];

        if (typeof value === "string") {
          if (this.searchInString(value, searchText)) return item;
        } else if (typeof value === "number") {
          if (this.searchInString(`${value}`, searchText)) return item;
        } else if (Array.isArray(value)) {
          const result = this.searchItemInArray(value, searchText);

          if (result) return result;
        }
      }
    }

    return null;
  }

  static searchInString(value, searchText) {
    return value.toLowerCase().includes(searchText);
  }

  static formatDate(dateString, hasTime) {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleString("en-GB", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });

    return formattedDate.replace(",", "");
  }

  static placeholderImage() {
    return imgdf;
  }

  static getYoutubeVideoId(url) {
    const urlParams = new URLSearchParams(new URL(url).search);
    return urlParams.get("v");
  }

  static async downloadFile(url) {
    try {
      const urlParams = new URL(url);
      const fileName = urlParams.searchParams.get("name") || "file"; // Extract 'name' param or default to 'file'

      const response = await fetch(url);
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  }
}

export default Utils;
