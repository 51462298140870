import React, { forwardRef, memo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../../../store/slices/languageSlice";
import Utils from "../../../../utils/Utils";
import { Parallax } from "react-scroll-parallax";

const TitleSimple = forwardRef((props, ref) => {
  const { items, parent } = props;
  const lang = useSelector(selectCurrentLanguage);
  const cl = Utils.isValidJson(parent.className)
    ? JSON.parse(parent.className)
    : parent.className;

  //console.log(parent, cl);

  return (
    <div>
      <Parallax
        className={
          "h-[300px] lg:h-[320px] md:h-[200px] sm:h-[200px] xs:h-[180px] flex items-center overflow-hidden relative  mt-[-101px] xs:mt-[0px] mb-[80px] md:mb-[60px] lg:mb-[60px] sm:mb-[60px]"
        }
        translateY={[-40, 40]}
        style={{
          backgroundImage:
            parent?.image_main &&
            `url(${Utils.formatURL(
              `posts/${parent?.post_content_id}/${parent?.image_main}`
            )}?s=md)`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          color: "#FFF",
          ...cl,
        }}
      >
        <div className="absolute h-full w-full opacity-50 top-0 left-0"></div>
        <Container>
          <Row className="items-center justify-center">
            <Col
              md={12}
              xl={12}
              lg={12}
              sm={12}
              className="relative text-center"
            >
              <h1 className="inline-block opacity-60 mb-[0px] text-xmd leading-[20px] -tracking-[.5px] font-serif  whitespace-pre-line mt-20">
                {parent?.subtitle_widget && parent?.subtitle_widget[lang]}
              </h1>
              <h2 className="font-serif -tracking-[1px] text-[3.9rem] font-medium mb-0 sm:-tracking-[1px]">
                {parent?.title_lang && parent?.title_lang[lang]}
              </h2>
            </Col>
          </Row>
        </Container>
      </Parallax>
    </div>
  );
});

export default memo(TitleSimple);
