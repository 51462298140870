import React, { forwardRef, memo } from "react";
import { m } from "framer-motion";
import { Container } from "react-bootstrap";
import Counter from "../../counter/Counter";
import { CounterData02 } from "../../counter/CounterData";
import { fadeIn } from "../../../../utils/GlobalAnimations";
import { selectCurrentLanguage } from "../../../store/slices/languageSlice";
import { useSelector } from "react-redux";
import Utils from "../../../../utils/Utils";

const CounterSimple = forwardRef((props, ref) => {
  const { items, parent } = props;
  const lang = useSelector(selectCurrentLanguage);
  const cl = Utils.isValidJson(parent.className)
    ? JSON.parse(parent.className)
    : parent.className;

  return (
    <m.section
      ref={ref}
      className={`pb-[130px] lg:pb-[90px] md:pb-[75px] sm:pb-[50px] `}
      style={{
        ...cl,
      }}
      {...fadeIn}
    >
      <Container>
        <Counter
          theme="counter-style-02"
          grid="row-cols-1 row-cols-md-4 row-cols-sm-2 gap-y-10 items-center justify-center text-center"
          className="text-black interiordesign-counter"
          data={items}
          duration={2}
        />
      </Container>
    </m.section>
  );
});

export default memo(CounterSimple);
