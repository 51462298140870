import React, { memo, useEffect, useRef, useState } from "react";
import { Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { m } from "framer-motion";
import PropTypes from "prop-types";
import Buttons from "../button/Buttons";
import { Link, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../../store/slices/languageSlice";
import Utils from "../../../utils/Utils";
import _ from "lodash";

const SliderHorizontal = (props) => {
  const swiperRef = useRef(null);
  const { pathname } = useLocation();
  const params = useParams();
  const lang = useSelector(selectCurrentLanguage);
  const [loading, setLoading] = useState(true);

  const path = pathname.replace(`/c/${params.category_id}`, "");

  useEffect(() => {
    let allImages = swiperRef.current.querySelectorAll("img");

    Promise.all(
      Array.prototype.slice
        .call(allImages)
        .filter((img) => !img.complete)
        .map(
          (img) =>
            new Promise((resolve) => {
              img.onload = img.onerror = resolve;
            })
        )
    ).then(() => {
      setLoading(false);
    });
  }, [props.data]);

  return (
    <Row
      className={`interactivebanners-style-02 sm:contents 
      ${loading ? " default_loading loading " : ""}
      `}
    >
      <Swiper
        className="white-move"
        modules={[Autoplay, Pagination, Navigation]}
        {...props.carousalOption}
        ref={swiperRef}
        observer
      >
        {props.data.map((item, i) => {
          return (
            <SwiperSlide key={i} className="my-swiper">
              <m.div
                className="interactivebanners-main !hover:pointer-events-none "
                {...{
                  ...props.animation,
                  transition: { delay: i * props.animationDelay },
                }}
              >
                <Link
                  aria-label="link"
                  target={props.target}
                  to={`${path}/${
                    item.name_lang
                      ? `c/${Utils.removeDiacritics(item.name_lang[lang])}-v${
                          item.id
                        }`
                      : ""
                  }`}
                  className="no-underline hover:no-underline "
                >
                  <div className="overflow-hidden">
                    <img
                      height="300"
                      width="200"
                      className="interactiveanners-img"
                      src={item.picture_src || Utils.placeholderImage()}
                      alt={item?.name_lang[lang] || item?.picture_src}
                    />
                  </div>
                  <div className="fancy-text-content !relative !transform-none pt-2 pb-3 hover:text-[#f9f9f9] ">
                    {item.name_lang && item.name_lang[lang] && (
                      <div className="interactivebanners-title text-black text-md font-serif ">
                        {item.name_lang[lang]}
                      </div>
                    )}
                  </div>
                </Link>
              </m.div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Row>
  );
};

SliderHorizontal.defaultProps = {
  data: [],
  animationDelay: 0.2,
  carousalOption: {
    slidesPerView: 2,
    spaceBetween: 5,
    loop: true,
    keyboard: { enabled: true, onlyInViewport: true },
    autoplay: { delay: 3000, disableOnInteraction: false },
    breakpoints: {
      1400: { slidesPerView: 8 },
      1200: { slidesPerView: 6 },
      800: { slidesPerView: 4 },
      400: { slidesPerView: 3 },
    },
  },
  grid: "opacity-100",
};

SliderHorizontal.propTypes = {
  className: PropTypes.string,
  animationDelay: PropTypes.number,
  animation: PropTypes.object,
};

export default SliderHorizontal;
