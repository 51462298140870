import React, { forwardRef, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import WidgetRegister from "../../components/widgets/WidgetsRegister";
import { selectFooterData } from "../../store/slices/pageSlice";

const FooterWidgets = forwardRef((props, ref) => {
  const data = useSelector(selectFooterData);

  return (
    <div>
      {data.map((dt, _index) => {
        const {
          content,
          widget_content,
          post_content_id,

          ...parent
        } = dt;
        const { type_widget } = content;

        return (
          <WidgetRegister
            key={post_content_id}
            type={type_widget}
            items={widget_content}
            parent={{ ...dt, ...parent, ...content }}
          />
        );
      })}
    </div>
  );
});

export default memo(FooterWidgets);
