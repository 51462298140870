import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import fetch from "../../../../context/AxiosContext";
import _ from "lodash";
import { SectionsModel, SubSectionsModel } from "../../../model/ContentModel";

export const getSubPost = createAsyncThunk(
  "subpage/getSubPost",
  async ({ post }, { dispatch, getState }) => {
    try {
      const id = post ? post.split("-v")[1] : post;

      const res = await fetch.get(`/site/public/content/post/${id}`);

      if (res.status === "error") return {};

      if (_.isEmpty(res.result)) return {};

      return SectionsModel(res.result);
    } catch (error) {
      return {};
    }
  }
);

export const getSpecificPost = createAsyncThunk(
  "subpage/getSpecificPost",
  async (id, { dispatch, getState }) => {
    try {
      const res = await fetch.get(`/site/public/content/${id}`);

      if (res.status === "error") return null;

      if (_.isEmpty(res.result)) return null;

      return SubSectionsModel(res.result);
    } catch (error) {
      return {};
    }
  }
);

const subSlice = createSlice({
  name: "subpage",
  initialState: {
    post: {},
    content: null,
    status: "pending",
  },
  reducers: {},
  extraReducers: (b) => {
    b.addCase(getSubPost.pending, (state) => {
      state.status = "pending";
      state.content = null;
    })
      .addCase(getSubPost.fulfilled, (state, action) => {
        state.post = action.payload;
        state.status = "fulfilled";
      })
      .addCase(getSpecificPost.fulfilled, (state, action) => {
        state.content = action.payload;
      });
  },
});

export const selectSub = ({ subpage }) => subpage;

export default subSlice.reducer;
