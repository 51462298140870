import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import fetch from "../../../context/AxiosContext";
import _ from "lodash";
import { getSessionConfig } from "./configSlice";

export const validateLogin = createAsyncThunk(
  "user/validateLogin",
  async ({ email, password, remember }, { dispatch, getState }) => {
    try {
      const res = await fetch.post("/site/public/session/signin", {
        email,
        password,
      });

      if (res.status === "error") return Promise.reject(res);

      if (_.isEmpty(res.result)) return Promise.reject(res);

      const { token } = res.result;

      localStorage.setItem("site-session-token", token);

      return token;
    } catch (error) {}
  }
);

export const signupForm = createAsyncThunk(
  "user/signupForm",
  async (data, { dispatch, getState }) => {
    try {
      const res = await fetch.post("/site/public/session/signup", { ...data });

      if (res.status === "error") return Promise.reject(res);

      return res;
    } catch (error) {
      console.error(error);
    }
  }
);

export const logoutUser = createAsyncThunk(
  "user/logoutUser",
  async (navigate, { dispatch, getState }) => {
    try {
      const { pathname } = window.location;

      if (pathname.includes("downloads")) navigate("/");

      localStorage.removeItem("site-session-token");

      return true;
    } catch (error) {
      console.error(error);
    }
  }
);

export const contactForm = createAsyncThunk(
  "user/contactForm",
  async (data, { dispatch, getState }) => {
    try {
      const res = await fetch.post("/site/public/contact_us", { ...data });

      if (res.status === "error") return Promise.reject(res);

      return true;
    } catch (error) {
      console.error(error);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    token: null,
    data: null,
  },
  reducers: {},
  extraReducers: (b) => {
    b.addCase(getSessionConfig.fulfilled, (state, action) => {
      const session_data = action.payload?.session_data || {};

      state.token = window.localStorage.getItem("site-session-token");

      state.data = {
        ...session_data,
      };
    })
      .addCase(validateLogin.fulfilled, (state, action) => {
        state.token = action.payload;
      })
      .addCase(logoutUser.fulfilled, (state, action) => {
        state.token = null;
        state.data = null;
      });
  },
});

export const selectUser = ({ user }) => user;

export default userSlice.reducer;
