import React, { memo } from "react";
import { Link } from "react-router-dom";
import { m } from "framer-motion";
import "../../../../assets/scss/components/_socialicons.scss";

const FooterIconItem = memo((props) => {
  const { item, language } = props;

  const { is_visible } = item;

  const path = item.url_tabs
    ? item.url_tabs
    : item?.url_lang
    ? item.url_lang[language]
      ? `/${language}/${item.url_lang[language]}`
      : ""
    : "";

  if (!Boolean(is_visible)) return null;

  return (
    <m.li
      key={item.menu_item_id}
      style={{ "--social-icon-color": item.color ? item.color : "#000" }}
      {...{
        ...props.animation,
        transition: { delay: 1 * 0.2 },
      }}
    >
      <a href={path} aria-label="social icon" target="_blank" rel="noreferrer">
        {item.socialback && (
          <div className="social-back">
            <span>{item.socialback}</span>
          </div>
        )}
        <div className={`social-front grid`}>
          {item.icon && <i className={item.icon}></i>}
          {/* {item?.title_lang && (
            <span>
              {item.title_lang[language] ? item.title_lang[language] : "icon"}
            </span>
          )} */}
        </div>
      </a>
    </m.li>
  );
});

export default FooterIconItem;
