import React, { memo, useState } from "react";
import "../../../../assets/scss/pages/_products.scss";
import FormFilter from "./FormFilter";
import { Drawer } from "rsuite";
import { useDispatch } from "react-redux";
import { t } from "../../../store/slices/languageSlice";
import Buttons from "../../../components/button/Buttons";

const Filters = (props) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
    // document.body.style.overflow = "hidden";
  };

  const handleCloseOutside = (e) => {
    if (
      isOpen &&
      e.target.className.includes("drawer-content") &&
      e.target.className.includes("show")
    ) {
      setIsOpen(false);
      document.body.style.overflow = "";
    }
  };

  return (
    <>
      <Buttons
        className={
          "btn-fill font-medium font-serif rounded-[50px] tracking-[1px] bg-[#fff] hover:text-white"
        }
        ariaLabel="filter"
        size={"md"}
        type="submit"
        themeColor="#000"
        color={"#000"}
        icon="feather-sliders"
        iconPosition="after"
        onClick={toggleDrawer}
        title={dispatch(t("WEBSITE.all_filters"))}
      />
      <Drawer
        size={"xs"}
        className="p-0 m-0"
        open={isOpen}
        onClose={toggleDrawer}
      >
        <Drawer.Header>
          <Drawer.Title>{dispatch(t("common.filters"))}</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body className="p-0 m-0">
          <FormFilter {...props} />
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default memo(Filters);
