import React, { forwardRef, lazy, memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  selectCurrentLanguage,
  setLanguage,
} from "../../store/slices/languageSlice";
import { motion } from "framer-motion";
import { motionContainer } from "../../../utils/GlobalAnimations";
import _ from "lodash";
import { Col, Container, Row } from "react-bootstrap";
import { setSelectedMenu } from "../../store/slices/navigationSlice";
import { getSpecificPost, getSubPost, selectSub } from "./store/subSlice";
import PageLoading from "../../components/loader/PageLoading";
import Utils from "../../../utils/Utils";
import Buttons from "../../components/button/Buttons";
import { getPageContent } from "../../store/slices/pageSlice";
import { Helmet } from "react-helmet";
import clsx from "clsx";
import ImageGallery from "../../components/gallery/ImageGallery";

const SubPageTemplate = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const params = useParams();
  const lang = useSelector(selectCurrentLanguage);
  const { status, post, content } = useSelector(selectSub);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (params.post)
      dispatch(getSubPost({ ...params })).then((r) => {
        if (r.payload) {
          const { content } = r.payload;
          const id = content?.widget_id || null;
          if (id) dispatch(getSpecificPost(id));
        }
      });

    dispatch(
      setSelectedMenu({
        website_menu_type_id: "reverse-scroll_light__",
      })
    );

    if (params.lang) {
      dispatch(setLanguage(params.lang));
    }
  }, [params, dispatch]);

  const handleBack = async () => {
    try {
      const { pathname } = location;

      const paths = pathname.split("/");
      paths.splice(3);
      const path = paths.join("/");

      navigate(path);
    } catch (error) {}
  };

  if (status === "pending")
    return (
      <motion.div
        initial="hidden"
        animate="visible"
        exit={{ opacity: 0, transition: { duration: 1 } }}
        variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
      >
        <PageLoading />
      </motion.div>
    );

  return (
    <motion.div
      className="interior-design"
      style={props.style}
      variants={motionContainer}
      initial="hidden"
      animate="show"
    >
      <Helmet>
        <meta
          name="description"
          content={
            (post?.content?.description_lang &&
              post?.content?.description_lang[lang]) ||
            ""
          }
        />
        <meta
          property="og:title"
          content={(post?.title_lang && post?.title_lang[lang]) || ""}
        />
        <meta
          property="og:description"
          content={
            (post?.content?.description_lang &&
              post?.content?.description_lang[lang]) ||
            ""
          }
        />
        <meta
          property="og:image"
          content={`${Utils.formatURL(
            `posts/${post?.post_content_id}/${post?.content?.image_main}`
          )}?s=lg`}
        />
        <meta
          property="og:image:alt"
          content={(post?.title_lang && post?.title_lang[lang]) || ""}
        />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <section className="mt-[120px]">
        <Container>
          <div
            className={
              "w-full flex !flex-row xs:!flex-col items-center justify-between xs:justify-center"
            }
          >
            <Buttons
              className={
                "btn-fill font-medium font-serif !px-[14px]  rounded-[50px] tracking-[1px] bg-[#fff] hover:text-white"
              }
              ariaLabel="filter"
              size={"sm"}
              type="submit"
              icon="feather-arrow-left !m-0"
              iconPosition="after"
              onClick={handleBack}
            />
          </div>
        </Container>
      </section>

      <section>
        <Container>
          <div className="justify-center">
            <div className="md:mb-[60px] sm:mb-[40px] xs:mb-0">
              <Row>
                <Col className="blog-details-text last:mb-0 mb-24">
                  <h5 className="font-serif font-medium text-darkgray mb-[4.5rem]">
                    {post?.title_lang && post?.title_lang[lang]}
                  </h5>
                  <img
                    src={`${Utils.formatURL(
                      `posts/${post?.post_content_id}/${post?.content?.image_main}`
                    )}?s=lg`}
                    height=""
                    width=""
                    alt=""
                    className="w-full rounded-[6px] mb-[4.5rem]"
                  />

                  {post?.content?.html_lang &&
                  post?.content?.html_lang[lang] ? (
                    <div
                      className="mb-[25px] whitespace-pre-line"
                      dangerouslySetInnerHTML={{
                        __html:
                          post?.content?.html_lang &&
                          post?.content?.html_lang[lang],
                      }}
                    />
                  ) : (
                    <p className="mb-[25px]">
                      {post?.content?.description_lang &&
                        post?.content?.description_lang[lang]}
                    </p>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </section>

      {content?.subitems && !_.isEmpty(content?.subitems) && (
        <section className=" border-bottom border-color-extra-light-gray py-[60px] md:py-[80px] sm:py-[80px] xs:py-[70px]">
          <Container>
            <Row>
              <ImageGallery data={content?.subitems} />
            </Row>
          </Container>
        </section>
      )}
    </motion.div>
  );
});

export default memo(SubPageTemplate);
