import React, { forwardRef, memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAll } from "../../store/slices/pageSlice";
import { useNavigate, useParams } from "react-router-dom";
import { setLanguage } from "../../store/slices/languageSlice";
import WidgetRegister from "../../components/widgets/WidgetsRegister";
import { Col, Container, Row } from "react-bootstrap";
import { getFiles, selectAll as selectAllFiles } from "./store/filesSlice";
import DownloadItem from "./components/DownloadItem";

const Downloads = forwardRef((props, ref) => {
  const { item } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector(selectAll);
  const params = useParams();
  const files = useSelector(selectAllFiles);

  useEffect(() => {
    if (params.lang) {
      dispatch(setLanguage(params.lang));
    }
  }, [params.lang]);

  useEffect(() => {
    dispatch(getFiles(navigate));
  }, [dispatch]);

  return (
    <div style={props.style}>
      {data.map((dt, _index) => {
        const {
          content,
          widget_content,
          post_content_id,

          ...parent
        } = dt;
        const { type_widget } = content;

        return (
          <WidgetRegister
            key={post_content_id}
            type={type_widget}
            items={widget_content}
            parent={{ ...dt, ...parent, ...content }}
          />
        );
      })}

      <section className="relative pt-0 py-[130px] px-[4%] lg:py-[90px] lg:px-[2%] md:py-[75px] sm:py-[50px] xs:px-0">
        <Container fluid>
          <Row>
            <Col xs={12} className="xs:px-0">
              <DownloadItem
                grid="grid grid-4col xl-grid-4col lg-grid-3col md-grid-2col sm-grid-2col xs-grid-1col gutter-extra-large text-center"
                className={"pt-0"}
                filterData={[]}
                items={files}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
});

export default memo(Downloads);
