import React, { forwardRef, memo, useState } from "react";
import { useSelector } from "react-redux";
import { motion, m } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { selectCurrentLanguage } from "../../store/slices/languageSlice";
import Utils from "../../../utils/Utils";

const ItemSimpleRounded = forwardRef((props, ref) => {
  const { item } = props;
  const lang = useSelector(selectCurrentLanguage);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  return (
    <div className="col-span-3 xl:col-span-3 lg:col-span-6 md:col-span-6 sm:col-span-12">
      <m.div
        className="col-span-3 xl:col-span-3 lg:col-span-6 md:col-span-6 sm:col-span-12 w-full flex flex-col justify-center items-center space-y-3"
        // style={style}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.5, ease: "easeOut" }}
      >
        <img
          className={`w-[200px] h-[200px] rounded-full ${
            item?.content?.link &&
            item?.content?.link[lang] &&
            `hover:cursor-pointer`
          } `}
          src={
            item?.content?.image_main && item?.content?.image_main !== "NULL"
              ? `${Utils.formatURL(
                  `posts/${item?.post_content_id}/${item?.content?.image_main}`
                )}?s=md`
              : Utils.placeholderImage()
          }
        />

        <div className="!w-full flex flex-col items-center">
          {item?.content?.link && item?.content?.link[lang] ? (
            <Link
              aria-label="link"
              to={item?.content?.link && item?.content?.link[lang]}
              className="text-center text-md uppercase font-medium !no-underline !text-inherit"
            >
              {item?.title_lang && item?.title_lang[lang]}
            </Link>
          ) : (
            <span className="blog-title">
              {item?.title_lang && item?.title_lang[lang]}
            </span>
          )}

          <p
            className="text-center leading-[20px] text-[14px]"
            style={{
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {item?.content?.description_lang &&
              item?.content?.description_lang[lang]}
          </p>
        </div>
      </m.div>
    </div>
  );
});

export default memo(ItemSimpleRounded);
