import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAll as selectPageData } from "../../../store/slices/pageSlice";
import {
  getCategories,
  selectAll,
  selectCategory,
  selectCategoryStatus,
} from "../store/categoriesSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Parallax } from "react-scroll-parallax";
import { Col, Container, Row } from "react-bootstrap";
import {
  selectCurrentLanguage,
  setLanguage,
  t,
} from "../../../store/slices/languageSlice";
import { m, motion } from "framer-motion";
import CategoryItem from "./CategoryItem";
import { fadeIn } from "../../../../utils/GlobalAnimations";
import SliderHorizontal from "../../../components/slider/SliderHorizontal";
import ProductItem from "./ProductItem";
import { selectProducts } from "../store/listSlice";
import WidgetRegister from "../../../components/widgets/WidgetsRegister";
import { setPageTitle } from "../../../store/slices/configSlice";
import Filters from "./Filters";
import { Typography } from "@mui/material";
import Buttons from "../../../components/button/Buttons";
import _ from "lodash";
import PageLoading from "../../../components/loader/PageLoading";
import { Helmet } from "react-helmet";
import Utils from "../../../../utils/Utils";

const motionContainer = {
  show: {
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const CategoriesPage = (props) => {
  const dispatch = useDispatch();
  const category = useSelector(selectCategory);
  const status = useSelector(selectCategoryStatus);
  const lang = useSelector(selectCurrentLanguage);
  const data = useSelector(selectPageData);
  const items = useSelector(selectAll);
  const list = useSelector(selectProducts);
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (params.category_id) {
      const id = params.category_id.split("-v")[1];
      dispatch(getCategories(id));
    }
  }, [dispatch, params.category_id]);

  useEffect(() => {
    const name =
      category?.name_lang && category?.name_lang[lang]
        ? category?.name_lang[lang]
        : "";

    dispatch(setPageTitle(name));
  }, [category, dispatch]);

  const handleBack = async () => {
    try {
      const { pathname } = location;

      // const paths = pathname.split("/");
      // paths.splice(3);
      // const path = paths.join("/");

      navigate(-1);
    } catch (error) {}
  };

  if (_.isEmpty(data) || status === "pending")
    return (
      <motion.div
        initial="hidden"
        animate="visible"
        exit={{ opacity: 0, transition: { duration: 1 } }}
        variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
      >
        <PageLoading />
      </motion.div>
    );

  return (
    <div style={props.style}>
      <Helmet>
        <meta
          name="description"
          content={
            (category?.description_lang && category?.description_lang[lang]) ||
            ""
          }
        />
        <meta
          property="og:title"
          content={(category?.name_lang && category?.name_lang[lang]) || ""}
        />
        <meta
          property="og:description"
          content={
            (category?.description_lang && category?.description_lang[lang]) ||
            ""
          }
        />
        <meta
          property="og:image"
          content={`${Utils.formatURL(
            `categories/${category?.category_id}/pics/${category?.picture}`
          )}?s=lg`}
        />
        <meta
          property="og:image:alt"
          content={(category?.name_lang && category?.name_lang[lang]) || ""}
        />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      {data.map((dt, _index) => {
        const {
          content,
          widget_content,
          post_content_id,

          ...parent
        } = dt;
        const { type_widget } = content;

        return (
          <WidgetRegister
            key={post_content_id}
            type={type_widget}
            items={widget_content}
            parent={{ ...dt, ...parent, ...content }}
            options={{
              ...category,
            }}
          />
        );
      })}

      <section className="px-[4%] lg:px-[2%] xs:px-0 overflow-hidden pb-[80px] lg:pb-[60px] md:pb-[40px] sm:py-[50px]">
        <Container fluid>
          <Row>
            <Col xs={12} className="xs:px-0">
              <m.div
                className="col mt-2 w-full home-startup-interactivebanner px-9 md:px-10 sm:px-5"
                {...fadeIn}
              >
                <SliderHorizontal data={items} />
              </m.div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="relative pt-0 px-[6%] lg:px-[2%] xs:px-5 pb-10 xs:-mt-10 -mt-20">
        <Container fluid>
          <div
            className={
              "w-full flex !flex-row xs:!flex-col items-center justify-between xs:justify-center"
            }
          >
            <div className="flex flex-row items-center space-x-2">
              <Buttons
                className={
                  "btn-fill font-medium font-serif !px-[14px]  rounded-[50px] tracking-[1px] bg-[#fff] hover:text-white"
                }
                ariaLabel="filter"
                size={"sm"}
                type="submit"
                icon="feather-arrow-left !m-0"
                iconPosition="after"
                onClick={handleBack}
              />

              <Filters category={true} id={params.category_id} />
            </div>

            <Typography color={"text.secondary"} className="xs:!mt-2 mt-0">
              {`${dispatch(
                t("WEBSITE.total_result", {
                  length: list.length,
                })
              )}`}
            </Typography>
          </div>
        </Container>
      </section>

      <section className="relative pt-0 py-[130px] px-[4%] lg:py-[90px] lg:px-[2%] md:py-[75px] sm:py-[50px] xs:px-0">
        <Container fluid>
          <Row>
            {/* <Col xs={12} className="xs:px-0 grid grid-rows-12"> */}
            <Col xs={12}>
              <motion.div
                className="grid grid-cols-12 grid-flow-row gap-4"
                variants={motionContainer}
                initial="hidden"
                animate="show"
              >
                {list.map((l, _index) => (
                  <ProductItem key={_index} item={l} />
                ))}
              </motion.div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default memo(CategoriesPage);
