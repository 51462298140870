import React, { useState, useEffect, useRef, memo } from "react";

// Libraries
import { Link } from "react-router-dom";
import { PropTypes } from "prop-types";
import { m } from "framer-motion";

// Components
import Pagination from "./helpers/Pagination";

// Data
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../../store/slices/languageSlice";
import Utils from "../../../utils/Utils";

const BlogSimple = (props) => {
  const { data } = props;
  const lang = useSelector(selectCurrentLanguage);

  const blogWrapper = useRef();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    import("../../../utils/Utilities").then((module) => {
      const grid = module.initializeIsotop(blogWrapper.current);
      grid.on("arrangeComplete", () => setLoading(false));
    });
  }, []);

  const style = {
    "--overlay-color":
      typeof props.overlay === "object"
        ? `linear-gradient(to right top, ${props.overlay.map(
            (item, i) => item
          )})`
        : props.overlay,
  };

  return (
    <div className="grid-wrapper">
      {/* Grid Start */}
      <ul
        ref={blogWrapper}
        className={`grid-container grid grid-3col xl-grid-2col lg-grid-2col md-grid-1col sm-grid-1col xs-grid-1col gutter-double-extra-large ${
          loading ? " loading" : ""
        } mt-28 md:mt-[4.5rem] sm:mt-8 `}
      >
        <li className="grid-sizer"></li>
        {data.map((item, i) => {
          return (
            <li key={i} className={`grid-item`}>
              <m.div
                className="blog-Simple xs:block flex flex-col !w-full"
                style={style}
                initial={{ opacity: 0 }}
                whileInView={!loading && { opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, ease: "easeOut" }}
              >
                <div
                  className="blog-post-image h-[250px] !w-full"
                  style={{
                    backgroundImage: `url(${Utils.formatURL(
                      `posts/${item?.post_content_id}/${item?.content?.image_main}`
                    )}?s=md)`,
                  }}
                >
                  <Link
                    aria-label="link"
                    to={item?.content?.link && item?.content?.link[lang]}
                  ></Link>
                </div>
                <div className="post-details !w-full">
                  <Link
                    aria-label="link"
                    to={item?.content?.link && item?.content?.link[lang]}
                    className="blog-title"
                  >
                    {item?.title_lang && item?.title_lang[lang]}
                  </Link>
                  <p className="mb-[25px] xl:mb-[25px] md:mb-[20px] xs:mb-[15px]">
                    {item?.content?.description_lang &&
                      item?.content?.description_lang[lang]}
                  </p>
                </div>
              </m.div>
            </li>
          );
        })}
      </ul>

      {props.pagination === true && (
        <div className="flex justify-center mt-[7.5rem] md:mt-20">
          <Pagination />
        </div>
      )}
    </div>
  );
};

BlogSimple.defaultProps = {
  filter: false,
  data: [],
  link: "",
};

BlogSimple.propTypes = {
  pagination: PropTypes.bool,
  title: PropTypes.string,
  grid: PropTypes.string,
  link: PropTypes.string,
};

export default memo(BlogSimple);
