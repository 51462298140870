import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import fetch from "../../../context/AxiosContext";
import _ from "lodash";
import { ContentModel, SectionsModel } from "../../model/ContentModel";

const pageAdapter = createEntityAdapter({
  selectId: (data) => data.post_content_id,
});

const initialState = pageAdapter.getInitialState({
  status: "pending",
  footerData: [],
});

export const getPageContent = createAsyncThunk(
  "page/getPageContent",
  async ({ item }, { dispatch, getState }) => {
    try {
      const { menu_item_tag, website_menu_page_id } = item;

      if (!website_menu_page_id) return [];

      const res = await fetch.get(
        `/site/public/content/${website_menu_page_id}`
      );

      if (_.isEmpty(res.result)) return Promise.reject(res);

      const page = ContentModel(res.result);

      if (!page.subitems || _.isEmpty(page.subitems)) return [];

      return page.subitems
        .map((i) => SectionsModel(i))
        .filter((f) => Boolean(f.is_active));
    } catch (error) {
      console.error(error);
    }
  }
);

export const getFooterData = createAsyncThunk(
  "page/getFooterData",
  async (__, { dispatch, getState }) => {
    try {
      const res = await fetch.get(`/site/public/content/footer`);

      if (_.isEmpty(res.result)) return Promise.reject(res);

      const page = ContentModel(res.result);

      if (!page.subitems || _.isEmpty(page.subitems)) return [];

      return page.subitems
        .map((i) => SectionsModel(i))
        .filter((f) => Boolean(f.is_active));
    } catch (error) {
      console.error(error);
      return [];
    }
  }
);

export const sendSuggestion = createAsyncThunk(
  "page/sendSuggestion",
  async (data, { dispatch, getState }) => {
    try {
      const res = await fetch.post("/site/public/suggestions", { ...data });

      if (res.status === "error") return Promise.reject(res);

      return res;
    } catch (error) {
      console.error(error);
    }
  }
);

export const { selectAll, selectById, selectEntities, selectIds, selectTotal } =
  pageAdapter.getSelectors((state) => state.page);

const pageSlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    resetState: (state) => initialState,
  },
  extraReducers: (b) => {
    b.addCase(getPageContent.pending, (state, action) => {
      state.status = "pending";
    })
      .addCase(getPageContent.fulfilled, (state, action) => {
        pageAdapter.setAll(state, action.payload);
        state.status = "fulfilled";
      })
      .addCase(getFooterData.fulfilled, (state, action) => {
        state.footerData = action.payload;
      });
  },
});

export const selectFooterData = ({ page }) => page.footerData;
export const selectPageStatus = ({ page }) => page.status;

export const { resetState } = pageSlice.actions;

export default pageSlice.reducer;
