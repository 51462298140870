import React, { forwardRef, memo, useState } from "react";
import { fadeIn } from "../../../../utils/GlobalAnimations";
import { m } from "framer-motion";
import { Col, Container, Row } from "react-bootstrap";
import Buttons from "../../button/Buttons";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentLanguage, t } from "../../../store/slices/languageSlice";
import Utils from "../../../../utils/Utils";
import { contactForm, validateLogin } from "../../../store/slices/userSlice";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";
import _ from "lodash";

const FormContact = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { items, parent } = props;
  const [loading, setLoading] = useState(false);
  const lang = useSelector(selectCurrentLanguage);
  const cl = Utils.isValidJson(parent.className)
    ? JSON.parse(parent.className)
    : parent.className;

  const schema = yup.object().shape({
    name: yup.string().required(dispatch(t("common.input_required"))),
    email: yup
      .string()
      .required(dispatch(t("common.input_required")))
      .email(dispatch(t("common.input_invalid_email"))),
    message: yup.string().required(dispatch(t("common.input_required"))),
    subject: yup.string().required(dispatch(t("common.input_required"))),
  });

  const defaultValues = {
    name: "",
    email: "",
    message: "",
    subject: "",
  };

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors, dirtyFields, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const form = watch();

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      await dispatch(contactForm({ ...data })).then((res) => {
        setLoading(false);
        if (res.payload) reset({ ...defaultValues });
      });
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <m.section
      className="py-[30px] lg:py-[20px] md:py-[20px] sm:py-[20px] mb-20"
      {...fadeIn}
      style={{
        ...cl,
      }}
    >
      <Container>
        <Row className="justify-center">
          <Col
            xl={10}
            md={10}
            className="lg:px-[30px] md:px-[15px] sm:mb-[40px]"
          >
            <div className="p-16 border border-mediumgray lg:mt-[35px] md:p-10 space-y-8">
              <Controller
                control={control}
                name={"name"}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={dispatch(t("common.name"))}
                    placeholder={dispatch(t("common.name"))}
                    fullWidth
                    helperText={errors?.name?.message}
                    error={!!errors?.name}
                    required
                  />
                )}
              />

              <Controller
                control={control}
                name={"email"}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={dispatch(t("common.email"))}
                    placeholder={dispatch(t("common.email"))}
                    fullWidth
                    helperText={errors?.email?.message}
                    error={!!errors?.email}
                    required
                  />
                )}
              />

              <Controller
                control={control}
                name={"subject"}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={dispatch(t("common.subject"))}
                    placeholder={dispatch(t("common.subject"))}
                    fullWidth
                    helperText={errors?.subject?.message}
                    error={!!errors?.subject}
                    required
                  />
                )}
              />

              <Controller
                control={control}
                name={"message"}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={dispatch(t("common.message"))}
                    placeholder={dispatch(t("common.message"))}
                    fullWidth
                    multiline
                    minRows={12}
                    maxRows={18}
                    helperText={errors?.message?.message}
                    error={!!errors?.message}
                    required
                  />
                )}
              />

              <Buttons
                ariaLabel="contact"
                type="submit"
                color={"#fff"}
                onClick={handleSubmit(onSubmit)}
                disabled={_.isEmpty(dirtyFields) || !isValid || loading}
                title={dispatch(t("common.send"))}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </m.section>
  );
});

export default memo(FormContact);
