import React, { memo } from "react";

// Libraries
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { PropTypes } from "prop-types";

// Data
import { TestimonialsCarouselData5 } from "./TestimonialsCarouselData";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../../store/slices/languageSlice";
import Utils from "../../../utils/Utils";

const TestimonialsCarousel = (props) => {
  const { data } = props;
  const lang = useSelector(selectCurrentLanguage);

  const swiperRef = React.useRef(null);
  return (
    <div className={`relative ${props.className}`}>
      <Swiper
        {...props.carouselOption}
        className="testimonial-carousel-style-05 black-move"
        ref={swiperRef}
        modules={[Navigation, Pagination, Autoplay]}
      >
        {data.map((item, i) => {
          return (
            <SwiperSlide key={i}>
              <div className="flex items-center">
                {item?.content?.image_main && (
                  <img
                    width=""
                    height=""
                    className="mr-[50px] h-[180px] w-[180px] sm:w-[150px] sm:h-[150px] xs:w-[80px] xs:h-[80px] xs:mr-[15px] rounded-full"
                    src={Utils.formatURL(
                      `posts/${item?.post_content_id}/${item?.content?.image_main}?s=md`
                    )}
                    alt={(item?.title_lang && item?.title_lang[lang]) || ""}
                  />
                )}
                <div>
                  {item?.content?.description_lang && (
                    <p className="w-[85%] inline-block mb-[25px] md:w-full">
                      {item?.content?.description_lang[lang]}
                    </p>
                  )}
                  {item?.title_lang && (
                    <div className="font-semibold uppercase leading-5 font-serif text-darkgray">
                      {item?.title_lang[lang]}
                    </div>
                  )}
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      {props.carouselOption.navigation && (
        <div className="flex">
          <div
            onClick={() => swiperRef.current.swiper.slidePrev()}
            className="btn-slider-next rounded-full flex justify-center items-center text-darkgray bg-white border-[1px] border-solid border-mediumgray h-[40px] w-[40px] cursor-pointer hover:shadow-xl transition-all duration-800 absolute top-[38%] left-[-90px] right-auto"
          >
            <i className="feather-arrow-left text-xmd"></i>
          </div>
          <div
            onClick={() => swiperRef.current.swiper.slideNext()}
            className="btn-slider-prev rounded-full border-[1px] border-solid border-mediumgray bg-white text-darkgray flex justify-center items-center h-[40px] w-[40px] ml-[10px] cursor-pointer hover:shadow-xl transition-all duration-800 absolute top-[38%] right-[-90px] left-auto"
          >
            <i className="feather-arrow-right text-xmd"></i>
          </div>
        </div>
      )}
    </div>
  );
};

TestimonialsCarousel.defaultProps = {
  data: [],
};

TestimonialsCarousel.propTypes = {
  grid: PropTypes.string,
  theme: PropTypes.string,
  // data: PropTypes.arrayOf(
  //   PropTypes.exact({
  //     img: PropTypes.string,
  //     designation: PropTypes.string,
  //     content: PropTypes.string,
  //     firstname: PropTypes.string,
  //     lastname: PropTypes.string,
  //     title: PropTypes.string,
  //     thumbimg: PropTypes.string,
  //   })
  // ),
};

export default memo(TestimonialsCarousel);
