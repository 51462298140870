import { combineReducers } from "@reduxjs/toolkit";

import message from "./slices/messageSlice";
import user from "./slices/userSlice";
import navigation from "./slices/navigationSlice";
import footer from "./slices/footerSlice";
import config from "./slices/configSlice";
import language from "./slices/languageSlice";
import page from "./slices/pageSlice";

import products from "../pages/products/store";
import downloads from "../pages/restricted/store";

import subpage from "../pages/standard/store/subSlice";

const createReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    message,

    user,
    navigation,
    footer,
    config,
    language,

    page,
    products,
    downloads,
    subpage,
    ...asyncReducers,
  });

  /*
	Reset the redux store when user logged out
	 */
  if (action.type === "user/userLoggedOut") {
    // state = undefined;
  }

  return combinedReducer(state, action);
};

export default createReducer;
