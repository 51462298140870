import React from "react";
import { motion } from "framer-motion";

const PageLoading = () => {
  const loadingContainerVariants = {
    start: {
      transition: {
        staggerChildren: 0.2,
      },
    },
    end: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const loadingCircleVariants = {
    start: {
      y: "0%",
    },
    end: {
      y: "100%",
    },
  };

  const loadingCircleTransition = {
    duration: 0.5,
    repeat: Infinity,
    repeatType: "reverse",
    ease: "easeInOut",
  };

  return (
    <div className="w-full h-[100vh] flex items-center justify-center">
      <motion.div
        className="w-1/2 h-full flex items-center justify-center space-x-3"
        variants={loadingContainerVariants}
        initial="start"
        animate="end"
      >
        <motion.span
          className="w-[20px] h-[20px] block bg-black rounded-full"
          variants={loadingCircleVariants}
          transition={loadingCircleTransition}
        />
        <motion.span
          className="w-[20px] h-[20px] block bg-black rounded-full"
          variants={loadingCircleVariants}
          transition={loadingCircleTransition}
        />
        <motion.span
          className="w-[20px] h-[20px] block bg-black rounded-full"
          variants={loadingCircleVariants}
          transition={loadingCircleTransition}
        />
      </motion.div>
    </div>
  );
};

export default PageLoading;
