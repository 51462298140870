import React, { forwardRef, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentLanguage, t } from "../../../store/slices/languageSlice";
import Utils from "../../../../utils/Utils";
import { m } from "framer-motion";
import { Col, Container, Row } from "react-bootstrap";
import Buttons from "../../button/Buttons";
import CustomModal from "../../modal/CustomModal";
import { fadeIn } from "../../../../utils/GlobalAnimations";

const ModelSimple = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { items, parent } = props;
  const lang = useSelector(selectCurrentLanguage);
  const cl = Utils.isValidJson(parent.className)
    ? JSON.parse(parent.className)
    : parent.className;

  const id = `model-site-${parent.post_content_id}`;

  const handleClose = () => {
    import("../../../../utils/Utilities").then((module) => {
      if (module.getCookie(id) !== "shown") {
        document.cookie = `${id}=shown`;
      }
    });
  };

  return (
    <CustomModal.Wrapper
      modelCookie={id}
      animation="fade-zoom"
      closeBtnOuter={false}
      defaultOpen
    >
      <div className="w-[500px] sm:w-full justify-center">
        <div className="p-[4.5rem] relative bg-white rounded sm:px-10">
          <div className="mx-auto text-center rounded-[6px] sm:px-[2.5rem] xs:px-0">
            <div onClick={handleClose} className="absolute top-0 right-0 z-10">
              <CustomModal.Close className="close-btn">
                <button
                  title="Close (Esc)"
                  type="button"
                  className="border-none text-[30px] w-[44px] h-[44px]"
                >
                  ×
                </button>
              </CustomModal.Close>
            </div>
            <span className="text-darkgray uppercase font-serif text-xlg font-semibold mb-[15px] block md:text-lg md:leading-[26px]">
              {parent.title_lang && parent.title_lang[lang]}
            </span>
            <p className="mb-[25px] text-[#828282] xs:px-[15px]">
              {parent.subtitle_widget && parent.subtitle_widget[lang]}
            </p>
            {/* <CustomModal.Close>
              <Buttons type="submit" title={dispatch(t("common.close"))} />
            </CustomModal.Close> */}
          </div>
        </div>
      </div>
    </CustomModal.Wrapper>
  );
});

export default memo(ModelSimple);
