import React, { forwardRef, memo, useState } from "react";
import { fadeIn } from "../../../../utils/GlobalAnimations";
import { m } from "framer-motion";
import { Col, Container, Row } from "react-bootstrap";
import Buttons from "../../button/Buttons";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentLanguage, t } from "../../../store/slices/languageSlice";
import Utils from "../../../../utils/Utils";
import { selectCountries } from "../../../store/slices/configSlice";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { sendSuggestion } from "../../../store/slices/pageSlice";
import FilePicker from "../../formFields/FilePicker";

const FormSuggestions = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { items, parent } = props;
  const lang = useSelector(selectCurrentLanguage);
  const countries = useSelector(selectCountries);
  const cl = Utils.isValidJson(parent.className)
    ? JSON.parse(parent.className)
    : parent.className;

  const subject_keys = [
    {
      value: "products",
      label: dispatch(t("common.products")),
    },
    {
      value: "software",
      label: dispatch(t("common.software")),
    },
    {
      value: "website",
      label: dispatch(t("common.website")),
    },
    {
      value: "services",
      label: dispatch(t("common.services")),
    },
    {
      value: "others",
      label: dispatch(t("common.others")),
    },
  ];

  const [loading, setIsLoading] = useState(false);

  const schema = yup.object().shape({
    name: yup.string().required(dispatch(t("common.input_required"))),
    email: yup
      .string()
      .required(dispatch(t("common.input_required")))
      .email(dispatch(t("common.input_invalid_email"))),
    country: yup.string().required(dispatch(t("common.input_required"))),
    subject_key: yup.string().required(dispatch(t("common.input_required"))),
    description: yup.string().required(dispatch(t("common.input_required"))),
    is_customer: yup.string().notRequired(),
    file: yup.mixed().notRequired().nullable(),
  });

  const defaultValues = {
    name: "",
    email: "",
    country: null,
    subject_key: null,
    description: "",
    is_customer: null,
    file: null,
  };

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    trigger,
    formState: { errors, dirtyFields, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const form = watch();

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);

      data.country = _.find(countries, {
        value: data.country,
      }).label;

      await dispatch(sendSuggestion(data)).then((r) => {
        setIsLoading(true);

        if (r.payload) reset({ ...defaultValues });
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <m.section
      className="py-[10px] overflow-hidden"
      {...fadeIn}
      style={{
        ...cl,
      }}
    >
      <Container>
        <Row className="justify-center">
         
          <Col xl={9} md={12} sm={12} className="px-[10px] sm:mb-[40px] w-full">
            <div className="p-16 border border-mediumgray lg:mt-[35px] md:p-10 space-y-8  w-full">
              <Controller
                control={control}
                name={"name"}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={dispatch(t("common.name"))}
                    placeholder={dispatch(t("common.name"))}
                    fullWidth
                    helperText={errors?.name?.message}
                    error={!!errors?.name}
                    required
                  />
                )}
              />

              <Controller
                control={control}
                name={"email"}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={dispatch(t("common.email"))}
                    placeholder={dispatch(t("common.email"))}
                    fullWidth
                    helperText={errors?.email?.message}
                    error={!!errors?.email}
                    required
                  />
                )}
              />

              <Controller
                control={control}
                name={"country"}
                render={({ field: { onChange, value, ...field } }) => (
                  <Autocomplete
                    {...field}
                    options={countries}
                    value={
                      value
                        ? _.find(countries, { value: value.value || value })
                        : null
                    }
                    onChange={(e, nv) => {
                      if (nv) {
                        onChange(nv.value);
                        return;
                      }

                      onChange(nv);
                      return;
                    }}
                    getOptionLabel={(opt) => opt.label}
                    isOptionEqualToValue={(opt, v) =>
                      v && opt.value == (v.value || v)
                    }
                    renderOption={(_props, option) => (
                      <li {..._props} key={option?.value}>
                        {option?.label}
                      </li>
                    )}
                    className="w-full"
                    renderInput={(_params) => (
                      <TextField
                        {..._params}
                        label={dispatch(t("common.country"))}
                        placeholder={dispatch(t("common.country"))}
                        className="w-full"
                        helperText={errors?.country?.message}
                        error={!!errors?.country}
                        required
                      />
                    )}
                  />
                )}
              />

              <div className="w-full flex flex-col space-y-2">
                <Typography color={"text.secondary"}>
                  {`${dispatch(t("common.is_customer"))} *`}
                </Typography>
                <Controller
                  control={control}
                  name={"is_customer"}
                  render={({ field: { onChange, value, ...field } }) => (
                    <RadioGroup
                      row
                      {...field}
                      value={`${value}`}
                      onChange={(e, v) => {
                        onChange(v);
                      }}
                      name={"is_customer"}
                    >
                      <FormControlLabel
                        value={"1"}
                        control={<Radio />}
                        label={dispatch(t("common.yes"))}
                      />
                      <FormControlLabel
                        value={"0"}
                        control={<Radio />}
                        label={dispatch(t("common.no"))}
                      />
                    </RadioGroup>
                  )}
                />
              </div>

              <Controller
                control={control}
                name={"subject_key"}
                render={({ field: { onChange, value, ...field } }) => (
                  <Autocomplete
                    {...field}
                    options={subject_keys}
                    value={
                      value
                        ? _.find(subject_keys, { value: value.value || value })
                        : null
                    }
                    onChange={(e, nv) => {
                      if (nv) {
                        onChange(nv.value);
                        return;
                      }

                      onChange(nv);
                      return;
                    }}
                    getOptionLabel={(opt) => opt.label}
                    isOptionEqualToValue={(opt, v) =>
                      v && opt.value == (v.value || v)
                    }
                    renderOption={(_props, option) => (
                      <li {..._props} key={option?.value}>
                        {option?.label}
                      </li>
                    )}
                    className="w-full"
                    renderInput={(_params) => (
                      <TextField
                        {..._params}
                        label={dispatch(t("common.subject_key"))}
                        placeholder={dispatch(t("common.subject_key"))}
                        className="w-full"
                        helperText={errors?.subject_key?.message}
                        error={!!errors?.subject_key}
                        required
                      />
                    )}
                  />
                )}
              />

              <Controller
                control={control}
                name={"description"}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={dispatch(t("common.description"))}
                    placeholder={dispatch(t("common.description"))}
                    fullWidth
                    multiline
                    minRows={10}
                    maxRows={15}
                    helperText={errors?.description?.message}
                    error={!!errors?.description}
                    required
                  />
                )}
              />

              <Controller
                control={control}
                name={"file"}
                render={({ field }) => (
                  <FilePicker
                    {...field}
                    errors={errors}
                    title={dispatch(t("common.file"))}
                  />
                )}
              />

              <Buttons
                type={"submit"}
                ariaLabel="register"
                onClick={handleSubmit(onSubmit)}
                title={dispatch(t("common.send"))}
                color={"#fff"}
                disabled={_.isEmpty(dirtyFields) || !isValid || loading}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </m.section>
  );
});

export default memo(FormSuggestions);
