import React, { memo } from "react";
import { Link } from "react-router-dom";

const FooterItem = memo((props) => {
  const { item, language } = props;

  const { is_visible } = item;

  const path = item.url_tabs
    ? item.url_tabs
    : item?.url_lang
    ? item.url_lang[language]
      ? `/${language}/${item.url_lang[language]}`
      : ""
    : "";

  if (!Boolean(is_visible)) return null;

  return (
    <li className="mb-[7px] last:mb-0" key={item.menu_item_id}>
      <Link
        aria-label="footer menu link"
        to={path}
        target={item.url_tabs && "_blank"}
        rel={item.url_tabs && "noopener noreferrer"}
      >
        {item?.title_lang && item.title_lang[language]}
      </Link>
    </li>
  );
});

export default FooterItem;
