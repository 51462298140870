import React, { memo } from "react";
import { Row } from "react-bootstrap";
import { m } from "framer-motion";
import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";
import "../../../assets/scss/components/_iconwithtext.scss";
import Buttons from "../button/Buttons";
import Utils from "../../../utils/Utils";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentLanguage } from "../../store/slices/languageSlice";
import _ from "lodash";

const IconText = (props) => {
  const dispatch = useDispatch();
  const lang = useSelector(selectCurrentLanguage);
  const { item } = props;
  const general_feature =
    !_.isEmpty(item?.general_feature) && item?.general_feature?.props_list
      ? item?.general_feature?.props_list
      : [];

  return (
    <Row className={`${props.grid} md:justify-center`}>
      {general_feature.map((item, i) => {
        const table = "product_general_feature";

        const {
          icon,
          picture,
          title_lang,
          description_lang,
          product_general_feature_id: id,
        } = item;

        return (
          <m.div
            key={i}
            className={`col${props.theme ? ` ${props.theme}` : ""}${
              props.className ? ` ${props.className}` : ""
            }`}
            {...{
              ...props.animation,
              transition: {
                delay: i * props.animationDelay,
                ease: props.animationTransition,
                duration: props.animationDuration,
              },
            }}
          >
            <div className="rounded-md w-full">
              {icon ? (
                <i className={icon}></i>
              ) : picture ? (
                <img
                  height={60}
                  width={69}
                  className="inline-block items-center justify-center mb-[20px]"
                  src={Utils.formatURL(
                    `data_table/${table}/picture/${id}/${picture}?s=xs`
                  )}
                  alt="featurebox"
                />
              ) : null}

              <div className="feature-box-content">
                {title_lang && title_lang[lang] && (
                  <span className="font-medium title font-serif">
                    {title_lang[lang]}
                  </span>
                )}
                {description_lang && description_lang[lang] && (
                  <p>{description_lang[lang]}</p>
                )}
              </div>
            </div>
          </m.div>
        );
      })}
    </Row>
  );
};

IconText.defaultProps = {
  data: [],
  animationDelay: 0.6,
  animationDuration: 0.8,
  animationTransition: "circOut",
  theme: "icon-with-text-01",
};

IconText.propTypes = {
  className: PropTypes.string,
  animation: PropTypes.object,
  animationDelay: PropTypes.number,
  animationTransition: PropTypes.string,
  theme: PropTypes.string,
  grid: PropTypes.string,
};

export default memo(IconText);
