import React, { memo } from "react";
import NavigationRegister from "./NavigationRegister";

const FooterNavigation = memo((props) => {
  const { navigation, language } = props;

  return navigation.map((_item, _index) => (
    <NavigationRegister
      {...props}
      key={_item.menu_item_id}
      item={_item}
      type={_item.type}
      language={language}
    />
  ));
});

export default FooterNavigation;
