import React, { forwardRef, memo } from "react";
import { m } from "framer-motion";
import { fadeIn } from "../../../../utils/GlobalAnimations";
import { Col, Container, Row } from "react-bootstrap";
import { Parallax } from "react-scroll-parallax";
import Buttons from "../../button/Buttons";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../../../store/slices/languageSlice";
import Utils from "../../../../utils/Utils";

const CardTwoImages = forwardRef((props, ref) => {
  const { items, parent } = props;
  const lang = useSelector(selectCurrentLanguage);
  const cl = Utils.isValidJson(parent.className)
    ? JSON.parse(parent.className)
    : parent.className;

  return (
    <m.section
      className={`cover-background py-[130px] lg:py-[90px] md:py-[75px] sm:py-[50px] sm:pt-[100px] `}
      style={{
        backgroundImage:
          "url(/assets/img/webp/home-interior-design-about-bg.webp)",
        ...cl,
      }}
      {...fadeIn}
    >
      <Container className="relative">
        {items.map((item, _item) => (
          <Row
            key={item.post_content_id}
            className="items-center justify-center"
          >
            <Col
              lg={6}
              md={10}
              className="relative lg:mt-[30px] md:mb-36 sm:mb-36 md:mt-0"
            >
              <Parallax
                className="lg-no-parallax relative w-1/2 md:w-[70%] z-[2] mt-[20%] lg:mt-0 lg:mr-auto"
                speed={0}
              >
                <m.img
                  width={278}
                  height={344}
                  src={Utils.formatURL(
                    `posts/${item?.post_content_id}/${item?.content?.image_secondary}?s=md`
                  )}
                  alt=""
                  {...fadeIn}
                />
              </Parallax>
              <Parallax
                className="lg-no-parallax flex justify-center items-center w-[65%] bg-no-repeat absolute bottom-[-100px] right-[15px] lg:!left-auto lg:!right-0"
                speed={5}
              >
                <m.img
                  width={380}
                  height={554}
                  src={Utils.formatURL(
                    `posts/${item?.post_content_id}/${item?.content?.image_main}?s=md`
                  )}
                  alt=""
                  {...fadeIn}
                />
              </Parallax>
            </Col>
            <m.div
              className="col-lg-5 offset-lg-1 col-md-10"
              {...{ ...fadeIn, transition: { delay: 0.4, duration: 1 } }}
            >
              <div className="font font-medium mb-[30px] flex">
                <span className="flex-shrink-0 w-[30px] h-[1px] bg-basecolor opacity-70 self-center mr-5"></span>
                <div className="flex-grow-1">
                  <span className="text-basecolor uppercase">
                    {parent?.title_lang && parent?.title_lang[lang]}
                  </span>
                </div>
              </div>
              <h2 className="heading-5 font-serif uppercase text-darkgray font-bold w-[85%] mb-[30px] xl:w-full">
                {item?.title_lang && item?.title_lang[lang]}
              </h2>
              <p className="w-[75%] xl:w-full mb-[25px] whitespace-pre-line">
                {item?.content?.description_lang &&
                  item?.content?.description_lang[lang]}
              </p>
              <Buttons
                ariaLabel={
                  item?.content?.link_title_lang &&
                  item?.content?.link_title_lang[lang]
                }
                href={item?.content?.link && item?.content?.link[lang]}
                title={
                  item?.content?.link_title_lang &&
                  item?.content?.link_title_lang[lang]
                }
              />
            </m.div>
          </Row>
        ))}
      </Container>
    </m.section>
  );
});

export default memo(CardTwoImages);
