import { combineReducers } from "@reduxjs/toolkit";
import categories from "./categoriesSlice";
import list from "./listSlice";

const reducer = combineReducers({
  categories,
  list,
});

export default reducer;
