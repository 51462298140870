import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import fetch from "../../../../context/AxiosContext";
import _, { uniqueId } from "lodash";
import { CategoryModel } from "../model/CategoryModel";
import { ProductModel } from "../model/ProductModel";
import Utils from "../../../../utils/Utils";
import imgdf from "../../../../assets/img/floricolor_default.jpeg";

const categoriesAdapter = createEntityAdapter({
  selectId: (data) => data.category_id,
});

const emptyState = categoriesAdapter.getInitialState({
  status: "pending",
  category: null,
});

// const initialState = categoriesAdapter.upsertMany(emptyState, [
//   ...Array.from({ length: 10 }, (v, k) => v).map((i) => ({
//     id: uniqueId("slider-horizontal"),
//     category_id: uniqueId("slider-horizontal"),
//     picture_src: imgdf,
//   })),
// ]);

const initialState = categoriesAdapter.getInitialState({
  status: "pending",
  category: null,
});

export const getCategories = createAsyncThunk(
  "products/categories/getCategories",
  async (id, { dispatch, getState }) => {
    try {
      const res = await fetch.post(`/site/public/categories/${id || ""}`);

      if (res.status === "error") return Promise.reject(res);

      if (_.isEmpty(res.result)) return Promise.reject(res);

      const { categories, products, category } = res.result;

      return {
        categories: categories.map((r) => CategoryModel(r)),
        products: products.map((r) => ProductModel(r)),
        category: CategoryModel({
          ...category,
          title_lang: category?.name_lang,
          subtitle_widget: category?.description_lang,
        }),
      };
    } catch (error) {
      console.error(error);
      return [];
    }
  }
);

export const { selectAll, selectById, selectEntities, selectIds, selectTotal } =
  categoriesAdapter.getSelectors((state) => state.products.categories);

const categoriesSlice = createSlice({
  name: "products/categories",
  initialState,
  reducers: {},
  extraReducers: (b) => {
    b.addCase(getCategories.pending, (state) => {
      state.status = "pending";
    })
      .addCase(getCategories.fulfilled, (state, action) => {
        const { categories, category } = action.payload;

        state.category = category;
        categoriesAdapter.setAll(state, categories);
        state.status = "fulfilled";
      })
      .addCase(getCategories.rejected, (state, action) => {
        state.status = "rejected";
      });
  },
});

export const selectCategoryStatus = ({ products }) =>
  products.categories.status;
export const selectCategory = ({ products }) => products.categories.category;

export default categoriesSlice.reducer;
